// react
import React,{useState} from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Menu18x14Svg, ArrowRoundedDown9x6Svg } from '../../svg';
// application
import PageHeader from '../shared/PageHeader';
import Solutions from './Solutions';
// const solucionesEstandarizadas =[ "Automatización y Control EPC", "Potencia y Energía EPC", "Medición y Herramientas EPC", "Salud y Seguridad EPC"]

function PageSolutionsInfo(props) {
    const { solutions, sidebar, match: { params: { title } } } = props;
    const url = props.match.url
    const PrincipalNameService = url.split('/')
    const serviceName = PrincipalNameService[2].replace(/_/g, ' ');
    const serviceNameInfo = PrincipalNameService[3].replace(/_/g, ' ');
    const solutionList = solutions.filter((e) => e.solution_name == serviceName);
    const solution = solutionList[0]?.solution_list.filter((e) => e.title == serviceNameInfo);
    const [activeNameService, setActiveNameService] = useState(false)
    // solucionesEstandarizadas.includes(serviceNameInfo)
    const handleListItems = (item) => {
        const service_name = item.name_service
        setActiveNameService(previus => !previus)
    }
    if (!solution) {
        return (
            <div className="container">
                <br />
                <br />
                <br />
                <div className="div-loading" />
                <br />
                <br />
                <br />
            </div>
        );
    }
    const slug = serviceName.replace(/ /gi, '_');
    const content = (
        <div className='container_about'>
        <div className="row justify-content-center solutionsPage">
                {solution?.map(solution => (
                    <div>
                        <div>
                            <div className="post-card__image">
                                <Link to={`/soluciones/${slug}/${solution.title.replace(/ /gi, '_')}`}>
                                    <img src={`${process.env.REACT_APP_API_URL}assets/${solution.solution_list_image}`} alt="" />
                                </Link>
                            </div>
                            <div className="post-card__info">
                                <div className="solutionsPage">
                                    <div
                                        className="about-us__text typography"
                                        dangerouslySetInnerHTML={{ __html: solution.body }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                                    </div>
                <div className="about-us__body">
                    <div className='container_second_page'>
                        <div className="container_services_list second">
                            <h3 className='services_ref'>Nuestros Servicios</h3>
                            {solutions.map(solution => (
                                <div key={solution.solution_name}>
                                    {solution.solution_name === 'Soluciones Estandarizadas' ?
                                        <button type="button" className="departments__buttonS" onClick={() => handleListItems(solution)} >
                                            {solution.solution_name}
                                            <ArrowRoundedDown9x6Svg className="departments__button-arrowS" />
                                        </button> :
                                        <p className={`${solution.solution_name === serviceName ? 'service_name service_name_active' : 'service_name'}`}>
                                            <Link to={`/soluciones/${solution.solution_name.replace(/ /gi, '_')}`}>{solution.solution_name}</Link>
                                        </p>
                                    }
                                    {solution.solution_name === 'Soluciones Estandarizadas' && activeNameService && solution.solution_list.map(item => (
                                        <div className='departmentsList'>
                                            <Link to={`/soluciones/Soluciones_Estandarizadas/${item.title.replace(/ /gi, '_')}`}>{item.title}</Link>
                                        </div>

                                    ))}
                                </div>
                            ))}

                        </div>
                    </div>
                    <div
                        className="about-us__text typography"
                        dangerouslySetInnerHTML={{ __html: sidebar.side_bar }}
                    />
                </div>
            </div>
    );

    const breadcrumbs = [
        { title: 'Soluciones', url: '/Soluciones' },
        { title: serviceName, url: `/Soluciones/${serviceName}` },
        { title: serviceNameInfo.replace(/_/g, ' '), title2: serviceNameInfo.replace(/_/g, ' '), url: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{serviceNameInfo.replace(/_/g, ' ')}</title>

            </Helmet>

            <PageHeader breadcrumb={breadcrumbs} />

            <div className="container">{content}</div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    solutions: state.pagesContent.solutions,
});

const mapDispatchToProps = {
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PageSolutionsInfo);

// react
import React, { useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Menu18x14Svg, ArrowRoundedDown9x6Svg } from '../../svg';

// application
import PageHeader from '../shared/PageHeader';
function acortarParrafo(parrafo, longitudMaxima) {
    if (parrafo.length > longitudMaxima) {
        return parrafo.substring(0, longitudMaxima) + "...";
    } else {
        return parrafo;
    }
}
function PageSolutions(props) {
    const { solutions, sidebar, match: { params: { title } } } = props;
    const [nameService, setNameService] = useState('')
    const [activeNameService, setActiveNameService] = useState(false)

    const serviceName = title.replace(/_/g, ' ');
    const solutionFilter = solutions.filter((e) => e.solution_name == serviceName);

    const handleListItems = (item) => {
        const service_name = item.name_service
        setNameService(service_name)
        setActiveNameService(previus => !previus)
    }

    if (!solutionFilter) {
        return (
            <div className="container">
                <br />
                <br />
                <br />
                <div className="div-loading" />
                <br />
                <br />
                <br />
            </div>
        );
    }
    const slug = serviceName.replace(/ /gi, '_');
    console.log(serviceName)
    const content = (
        <div className='container_about'>
            {slug === 'Soluciones_en_Desarrollo_de_Software' ?
                <div className="row justify-content-center solutionsPage">
                    <div className="post-card__image">
                        <img src={`${process.env.REACT_APP_API_URL}assets/${solutionFilter[0].sub_image}`} alt="" />
                    </div>
                    <div className="post-card__content">
                        <h3>{solutionFilter[0].sub_title}</h3>
                        <p align="justify">
                            {solutionFilter[0].sub_body}
                        </p>
                    </div>
                    {solutionFilter[0]?.solution_list?.map(solution => (
                        <div className='solutions_container'>
                            <div className="post-card__image">
                                <img src={`${process.env.REACT_APP_API_URL}assets/${solution.solution_list_image}`} alt="" />
                            </div>
                            <div className="post-card__info">
                                <div className="post-card__name">
                                    <h4>{solution.title}</h4>
                                </div>
                                <div className="post-card__content">
                                    <p align="justify">
                                        {solution.solution_list_text}
                                    </p>
                                </div>

                            </div>
                        </div>


                    ))}
                </div>
                : (
                    slug === 'Gestión_de_Proyectos' ? (
                        <div className='subSolutionsContainerRow'>
                            {solutionFilter[0]?.solution_list?.map(solution => (
                                <div className="post-card__imageProyect">
                                    <div className="post-card__image post-card__imageProyect">
                                        <Link to={`/soluciones/${slug}/${solution.title.replace(/ /gi, '_')}`}>
                                            <img src={`${process.env.REACT_APP_API_URL}assets/${solution.solution_list_image}`} alt="" />
                                        </Link>
                                    </div>
                                    <div className="post-card__info">
                                        <div className="post-card__name">
                                            <Link to={`/soluciones/${slug}/${solution.title.replace(/ /gi, '_')}`}>{solution.title}</Link>
                                        </div>
                                        <div className="post-card__content">
                                            <p align="justify">
                                                {acortarParrafo(solution.solution_list_text, 100)}
                                            </p>
                                        </div>
                                        <div className="button_read_mor_container">
                                            <Link style={{ zIndex: 999 }} to={`/soluciones/${slug}/${solution.title.replace(/ /gi, '_')}`} className="button_read_more">Leer más...</Link>
                                        </div>
                                    </div>
                                </div>


                            ))}
                        </div>) : (
                        <div className="row justify-content-center solutionsPage">
                            {solutionFilter[0]?.solution_list?.map(solution => (
                                <div className='subSolutionsContainer'>
                                    <div className="post-card__image">
                                        <Link to={`/soluciones/${slug}/${solution.title.replace(/ /gi, '_')}`}>
                                            <img src={`${process.env.REACT_APP_API_URL}assets/${solution.solution_list_image}`} alt="" />
                                        </Link>
                                    </div>
                                    <div className="post-card__info">
                                        <div className="post-card__name">
                                            <Link to={`/soluciones/${slug}/${solution.title.replace(/ /gi, '_')}`}>{solution.title}</Link>
                                        </div>
                                        <div className="post-card__content">
                                            <p align="justify">
                                                {solution.solution_list_text}
                                            </p>
                                        </div>
                                        <div className="button_read_mor_container">
                                            <Link style={{ zIndex: 999 }} to={`/soluciones/${slug}/${solution.title.replace(/ /gi, '_')}`} className="button_read_more">Leer más...</Link>
                                        </div>
                                    </div>
                                </div>


                            ))}
                        </div>
                    ))
            }
            <div className="about-us__body">
                <div className='container_second_page'>
                    <div className="container_services_list second">
                        <h3 className='services_ref'>Nuestros Servicios</h3>
                        {solutions.map(solution => (
                            <div key={solution.solution_name}>
                                {solution.solution_name === 'Soluciones Estandarizadas' ?
                                    <button type="button" className="departments__buttonS" onClick={() => handleListItems(solution)} >
                                        {solution.solution_name}
                                        <ArrowRoundedDown9x6Svg className="departments__button-arrowS" />
                                    </button> :
                                    <p className={`${solution.solution_name === serviceName ? 'service_name service_name_active' : 'service_name'}`}>
                                        <Link  to={`/soluciones/${solution.solution_name.replace(/ /gi, '_')}`}>{solution.solution_name}</Link>
                                    </p>
                                }
                                {solution.solution_name === 'Soluciones Estandarizadas' && activeNameService && solution.solution_list.map(item => (
                                    <div className='departmentsList'>
                                        <Link to={`/soluciones/Soluciones_Estandarizadas/${item.title.replace(/ /gi, '_')}`}>{item.title}</Link>
                                    </div>

                                ))}
                            </div>
                        ))}

                    </div>
                </div>
                <div
                    className="about-us__text typography"
                    dangerouslySetInnerHTML={{ __html: sidebar.side_bar }}
                />
            </div>
        </div>
    );

    const breadcrumbs = [
        { title: 'Soluciones', url: '/Soluciones' },
        { title: title.replace(/_/g, ' '), url: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{serviceName}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumbs} />

            <div className="container">{content}</div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    solutions: state.pagesContent.solutions,
});

const mapDispatchToProps = {
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PageSolutions);

// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import AccountFormAddress from './AccountFormAddress';

function AccountEditAddress(props) {
    console.log(props)
    const { match: { params: { addressId } }, currentUser } = props;
    const addr = currentUser.addresses.find(({id}) => id === parseInt(addressId));
    const address = { ...addr };
    address.countryid = address.countryid ? address.countryid.id : '';
    address.deliverycityid = address.deliverycityid ? address.deliverycityid.id : '';
    address.owner = address.owner? address.owner : '';
    console.log(address)
    return (
        <div>
            <Helmet>
                <title>Editar Dirección - ICAutomatizados S.A</title>
            </Helmet>

            <AccountFormAddress type="Editar" address={address} />
        </div>
    );
}

export default AccountEditAddress;
// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// application
import NavPanel from './NavPanel';
import Search from './Search';
import Topbar from './Topbar';
import Dropdown from './Dropdown';


function Header(props) {
    const { layout, pagesContent, pagesContent: { company }, currentUser, history } = props;
    let bannerSection;
    const accountLinks = [
        { title: 'Mi Perfil', url: '/account/dashboard' },
        { title: 'Mis Cotizaciones', url: '/account/cotizaciones' },
        { title: 'Mis Órdenes', url: '/account/orders' },
        { title: 'Mis Direcciones', url: '/account/addresses' },
        { title: 'Contraseña', url: '/account/password' },
        { title: 'Salir', url: '#' },
    ];
    if (layout === 'default') {
        bannerSection = (
            <div className="site-header__middle container">
                <div className="site-header__logo">
                    <Link to="/"><img src={`${process.env.REACT_APP_API_URL}assets/${company[0].logo}`} alt="ICAutomatizados" height="80px" /></Link>
                </div>
                <div className="site-header__search">
                    <Search context="header" />
                </div>
                <div className="site-header__phone">
                {!currentUser ? (
                            <div className="topbar__item topbar__item--link">
                                <Link className="topbar-link" to="/account/login"><b>Ingresar ó Registrarse</b></Link>
                            </div>
                        ) : (
                            <Dropdown
                                title={`${currentUser.first_name} ${currentUser.last_name}`}
                                items={accountLinks}
                                history={history}
                            />
                        )}
                    {/* <div className="site-header__phone-title">
                        <FormattedMessage id="header.phoneLabel" defaultMessage="Customer Service" />
                    </div> */}
                    <div className="site-header__phone-number">
                    <a href={`https://wa.me/6299-6744`} target="_blank" rel="noopener noreferrer">
                        <FormattedMessage id="header.phone" defaultMessage={` ${company && company[0].customer_service}`} />
                        </a>

                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="site-header">
            {/* <Topbar currentUser={currentUser} history={history} /> */}
            {bannerSection}
            <div className="site-header__nav-panel">
                <NavPanel layout={layout} history={history} pagesContent={pagesContent} />
            </div>
        </div>
    );
}

Header.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(['default', 'compact']),
};

Header.defaultProps = {
    layout: 'default',
};

export default Header;

import * as React from 'react';
import { useClearCacheCtx } from 'react-clear-cache';
import Root from './Root';


const App = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();
  console.log(isLatestVersion);
  if (!isLatestVersion) {
    emptyCacheStorage();
    return window.location.reload()
  }

  return <Root />
};

export default App;
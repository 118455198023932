// react
import React, { useState, useEffect } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es-us';
import Currency from '../shared/Currency';
import { getOrder } from '../../store/directusSdk';

function AccountPageOrderDetails(props) {
    const { company, match: {params: {orderId}} } = props;
    const [order, setOrder] = useState(null);

    useEffect(() => {
        getOrder(orderId).then((res) => {
            if (res) {
                setOrder(res);
            }
        });
    }, [orderId]);

    if (!order) {
        return (
            <div className="container">
                <br />
                <br />
                <br />
                <div className="div-loading" />
                <br />
                <br />
                <br />
            </div>
        );
    }
    console.log(order)
    const date = moment(order.created_on, 'YYYY/MM/DD HH:mm:ss', 'es-us');
    const dateFormated = date.add(date.utcOffset(), 'minutes').format('LLL');
    const shippingaddress = order.shippingaddress.split('-');
    const billingaddress = order.billingaddress.split('-');

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Detalle de Orden — ${company[0].name}`}</title>
            </Helmet>

            <div className="card">
                <div className="order-header">
                    <div className="order-header__actions">
                        <Link to="/account/orders" className="btn btn-xs btn-secondary">Volver a Listado</Link>
                    </div>
                    <h5 className="order-header__title">{`Orden #${order.id}`}</h5>
                    <div className="order-header__subtitle">
                        Creada el
                        {' '}
                        <mark className="order-header__date">{dateFormated}</mark>
                        .
                        {' '}
                        <div className={`badge badge-${order.status === 'pendingforpayment' ? 'info' : 'success'}`}>{order.status === 'pendingforpayment' ? 'Pendiente' : 'Procesada'}</div>
                    </div>
                </div>
                <div className="card-divider" />
                <div className="card-table">
                    <div className="table-responsive-sm">
                        <table>
                            <thead>
                                <tr>
                                    <th>Producto</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody className="card-table__body card-table__body--merge-rows">
                                {order?.orderlines.map((product) => (
                                    <tr key={product.id}>
                                        <td>{`${product.productname} x ${product.quantity}`}</td>
                                        <td><Currency value={product.subtotal} /></td>
                                    </tr>
                                ))}
                            </tbody>
                            <tbody className="card-table__body card-table__body--merge-rows">
                                <tr>
                                    <th>Subtotal</th>
                                    <td><Currency value={order.subtotal} /></td>
                                </tr>
                                <tr>
                                    <th>ITBMS</th>
                                    <td><Currency value={order.itbms} /></td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Total</th>
                                    <td><Currency value={order.total} /></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>

            <div className="row mt-3 no-gutters mx-n2">
                <div className="col-sm-6 col-12 px-2">
                    <div className="card address-card address-card--featured">
                        <div className="address-card__body">
                            <div className="address-card__badge address-card__badge--muted">Dirección Envio</div>
                            <div className="address-card__name">{shippingaddress[0]}</div>
                            {shippingaddress.length > 1 && (
                                <React.Fragment>
                                    <div className="address-card__row">
                                        {shippingaddress[2]}
                                    </div>
                                    <div className="address-card__row">
                                        <div className="address-card__row-title">Telefono</div>
                                        <div className="address-card__row-content">{shippingaddress[1]}</div>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-12 px-2 mt-sm-0 mt-3">
                    <div className="card address-card address-card--featured">
                        <div className="address-card__body">
                            <div className="address-card__badge address-card__badge--muted">Dirección Facturación</div>
                            <div className="address-card__name">{billingaddress[0]}</div>
                            <div className="address-card__row">
                                {billingaddress[2]}

                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Telefono</div>
                                <div className="address-card__row-content">{billingaddress[1]}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    company: state.pagesContent.company,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageOrderDetails);
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable linebreak-style */
/* eslint-disable quotes */
// react
import React from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// application
import AsyncAction from "./AsyncAction";
import Currency from "./Currency";
import { cartAddItem } from "../../store/cart";
import { Compare16Svg, Quickview16Svg, Wishlist16Svg } from "../../svg";
import { compareAddItem } from "../../store/compare";
import { quickviewOpen } from "../../store/quickview";
import { wishlistAddItem } from "../../store/wishlist";
import ProductTabSpecification from "../shop/ProductTabSpecification";
function ProductCardSubcategory(props) {
    const { product, layout, quickviewOpen, cartAddItem, wishlistAddItem, compareAddItem, brands } = props;
    const containerClasses = classNames("product-card", {
        "product-card--layout--grid product-card--size--sm": layout === "grid-sm",
        "product-card--layout--grid product-card--size--nl": layout === "grid-nl",
        "product-card--layout--grid product-card--size--lg": layout === "grid-lg",
        "product-card--layout--list": layout === "list",
        "product-card--layout--horizontal": layout === "horizontal",
    });
    const [showSpecifications, setShowSpefifications] = React.useState(false)
    let image;
    let price;
    let features;
    if (product?.images && product?.images.length > 0) {
        image = (
            <div className="product-card__image" style={{ padding: "0px 14px 6px" }}>
                {product.discount && <span className="title-card">En Oferta</span>}
                <Link to={`/shop/product/${product.code.replace(/\//g, "-")}/${product?.id}`}>
                    <img src={`/images/products/${product?.images}`} alt="" />
                </Link>
            </div>
        );
    } else {
        image = (
            <div className="product-card__image">
                <Link to={`/shop/product/${product.code.replace(/\//g, "-")}/${product.id}`}>
                    <img src={`${process.env.REACT_APP_API_URL}assets/2750d158-ce6b-4c78-9c90-3e5dd0cf9549`} alt={product.name} />
                </Link>
            </div>
        );
    }

    if (!product.discount) {
        price = (
            <div className="product-card__prices">
                <Currency value={Number(product?.price)} />
                <span className="product-card__old-price">
                    <span className="percentage_price">{product.discount && `-${product.discount} `}</span>
                </span>
            </div>
        );
    } else {
        price = (
            <div className="product-card__prices" style={{ paddingTop: "20px" }}>
                <Currency value={parseFloat(product?.price)} />
                <del className="price_without_sale_price">${parseFloat(product?.sale_price)}</del>
            </div>
        );
    }

    if (product.features && product.features.length) {

        // eslint-disable-next-line react/jsx-wrap-multilines
        features = (<div>
            {brands.map(brand => {
                if (brand.name.toLowerCase() === product?.brand.toLowerCase()) {
                    return (<img src={`${process.env.REACT_APP_API_URL}assets/${brand.logo}`} alt="" height="50px" />)
                }
            })
            }

            <ul className="product-card__features-list">
                {product.features.slice(0, 3).map((feature, index) => (
                    <li key={index}>
                        {`${feature.name === "Número del cátalogo del fabricante" ? "ACE:" : feature.name}: `}
                        {feature.name === "Código del Producto" ? <b>{feature.value}</b> : feature.value}
                    </li>
                ))}
                {/* {product.features.map((feature, index) => (
                    <li key={index}>
                        {`${feature.name}: `} {feature.name === "Código del Producto" ? <b>{feature.value}</b> : feature.value}
                    </li>
                ))} */}
            </ul>
        </div>
        );
    }
    function getFirstWord(paragraph) {
        const words = paragraph.split(' ');
        if (words.length > 0) {
            return words[0];
        } else {
            return ''; // Devuelve una cadena vacía si el párrafo está vacío
        }
    }
    const handleShowSpecifications = () => {
        setShowSpefifications(prev => !prev)
    }
    return (
        <div className={containerClasses} style={{ display: "flex" }} >
            <div style={{ display: "flex", width: "400px" }} >
                <div style={{ width: "140px" }}  >

                    <AsyncAction
                        action={() => quickviewOpen(product.id)}
                        render={({ run, loading }) => (
                            <>
                                <button
                                    type="button"
                                    onClick={run}
                                    className={classNames("product-card__quickview", {
                                        "product-card__quickview--preload": loading,
                                    })}
                                >
                                    <Quickview16Svg />
                                </button>
                            </>
                        )
                        }
                    />
                    <AsyncAction
                        action={() => wishlistAddItem(product)}
                        render={({ run, loading }) => (
                            <button
                                type="button"
                                onClick={run}
                                className={classNames("btn btn-light btn-svg-icon btn-svg-icon--fake-svgcard__wishlist", {
                                    "btn-loading": loading,
                                })}
                            >
                                {/* <i class="fa fa-heart" aria-hidden="true"></i> */}
                                <Wishlist16Svg />
                            </button>
                        )}
                    />
                    {image}

                </div>

                <div>

                    <div >

                        <div className="product-card__name" style={{ fontSize: "16px", display: "flex" }}>
                            <Link to={`/shop/product/${product.code.replace(/\//g, "-")}/${product?.id}`}>
                                <div className="product-info-sub">
                                    <span className="code_name_brand"> <b>{getFirstWord(product?.name)} </b> </span>
                                    <span className="code_name_brand"> <b>{product?.brand} </b> </span>

                                    {/* <span className="code_product">{product.code}</span> */}
                                </div></Link>
                        </div>
                    </div>

                    {features}
                    <div className="product-card__actions">
                        {product?.available > 0 &&
                            <div className="product-card__availability">
                                <i className="fa fa-check-circle" aria-hidden="true"></i>
                                <span className="text-stock">EN STOCK</span>
                            </div>}
                        <div className="product-card__availability">
                            Disponible: <span className="text-success">{product?.available > 0 ? product?.available : "0"}</span>
                        </div>
                        <div className="product-card__availability">
                            En Transito: <span className="text-info">{product?.OnOrder > 0 ? product?.OnOrder : "0"}</span>
                        </div>{" "}
                        <div className="product-card__availability">
                            Bodega externa: <span className="text-info">{product?.externalQty}</span>
                        </div>
                        {price}
                        {/* <Currency value={parseFloat(product?.price)} /> */}
                        <div className="product-card__buttons">
                            <AsyncAction
                                action={() => cartAddItem(product)}
                                render={({ run, loading }) => (
                                    <React.Fragment>
                                        <button
                                            type="button"
                                            onClick={run}
                                            className={classNames("btn btn-primary product-card__addtocart", {
                                                "btn-loading": loading,
                                            })}
                                        >

                                            <svg width="20" height="20"><circle cx="7" cy="17" r="2"></circle><circle cx="15" cy="17" r="2"></circle><path d="M20,4.4V5l-1.8,6.3c-0.1,0.4-0.5,0.7-1,0.7H6.7c-0.4,0-0.8-0.3-1-0.7L3.3,3.9C3.1,3.3,2.6,3,2.1,3H0.4C0.2,3,0,2.8,0,2.6 V1.4C0,1.2,0.2,1,0.4,1h2.5c1,0,1.8,0.6,2.1,1.6L5.1,3l2.3,6.8c0,0.1,0.2,0.2,0.3,0.2h8.6c0.1,0,0.3-0.1,0.3-0.2l1.3-4.4 C17.9,5.2,17.7,5,17.5,5H9.4C9.2,5,9,4.8,9,4.6V3.4C9,3.2,9.2,3,9.4,3h9.2C19.4,3,20,3.6,20,4.4z"></path></svg>
                                        </button>
                                        <button
                                            type="button"
                                            onClick={run}
                                            className={classNames("btn btn-secondary product-card__addtocart product-card__addtocart--list", {
                                                "btn-loading": loading,
                                            })}
                                        >
                                            Enviar
                                        </button>
                                    </React.Fragment>
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div></div>

    );
}

ProductCardSubcategory.propTypes = {
    /**
     * product object
     */
    product: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(["grid-sm", "grid-nl", "grid-lg", "list", "horizontal"]),
};

const mapStateToProps = (state) => ({
    brands: state.pagesContent.brands,

});

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
    quickviewOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardSubcategory);

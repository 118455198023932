// react
import React, { Component } from 'react';

// third-party
import { Redirect } from 'react-router-dom';
import { updateTransactionLog, updateOrder } from '../../store/directusSdk';
import PageHeader from '../shared/PageHeader';

class ShopPagePaymentConfirm extends Component {
    constructor(props) {
        super(props);
        localStorage.setItem('try', JSON.stringify(parseInt(localStorage.getItem('try')) + 1));
        this.state = {
            order: JSON.parse(localStorage.getItem('ord')),
            transaction: JSON.parse(localStorage.getItem('trans')),
            result: null,
        };
    }

    componentDidMount() {
        const { transaction } = this.state;

        const response = updateTransactionLog(transaction.id, transaction.formid);
        response.then((res) => {
            if (res.data) {
                const result = res.data;
                this.setState(() => ({ result }));
            }
        });
    }

    Retry = () => {
        const { history } = this.props;
        localStorage.removeItem('trans');
        history.replace('/shop/checkout/payment');
    };

    render() {
        const { order, result } = this.state;
        const { history, currentUser } = this.props;

        if (!currentUser) {
            return <Redirect to="/" />;
        }

        if (result === null) {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-xl-12">
                            <div className="card">
                                <br />
                                <br />
                                <br />
                                <div className="div-loading" />
                                <br />
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        const res = result.resultcode.split('.');
        const checked = order;
        if (res[0] === '000' && res[1] === process.env.REACT_APP_CODE_TC) {
            updateOrder(order.numberOrder, { status: 'paid' }).then(() => {
                checked.pending = false;
                history.replace('/shop/checkout/success', checked);
            });
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-xl-12">
                            <div className="card">
                                <br />
                                <br />
                                <br />
                                <div className="div-loading" />
                                <br />
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        const breadcrumb = [
            { title: 'Mis Cuenta', url: '/account/dashboard' },
            { title: 'Mis Ordenes', url: '/account/orders' },
            { title: 'Trarjeta de Credito', url: '' },
        ];

        return (
            <React.Fragment>
                <PageHeader breadcrumb={breadcrumb} />
                <div className="checkout block">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-12 col-xl-12">
                                <div className="card" id="cr">
                                    <div className="card-body">
                                        <div className="card-divider" />
                                        <div align="center">
                                            <img src="images/error_TC.png" alt="Error" />
                                            <br />
                                            <br />
                                            <h3 className="document__title">¡Error!</h3>
                                            <span>Ha ocurrido un error al procesar su pago, si desea puede volver a intentarlo... </span>
                                            <br />
                                            <br />
                                            <button type="button" className="btn btn-secondary" onClick={this.Retry}>Volver a Intentar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };
}
export default ShopPagePaymentConfirm;
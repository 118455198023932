// react
import React from 'react';
import { connect } from 'react-redux';


// application
import Indicator from './Indicator';
import { Person20Svg } from '../../svg';
import AccountLogin from '../account/AccountLogin';
import Account from '../account/Account';


function IndicatorAccount(props) {
    const { currentUser, history } = props;

    const dropdown = (
        <div className="account-menu">
            {!currentUser ? <AccountLogin /> : <Account history={history}/>}
        </div>
    );

    return (
        <Indicator url="/account" dropdown={dropdown} icon={<Person20Svg />} />
    );
}
const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorAccount);

import React, {useState} from 'react'

import CookiesModal from './CookiesModal';
import Modal from "react-modal";
import Cookies from "js-cookie";
Modal.setAppElement("#root");


export default function Cookie() {
  
    
  const [showFirstModal, setShowFirstModal] = useState(true);

  const [showSecondModal, setShowSecondModal] = useState(false);

  const handleFirstModalAcceptAll = () => {
    Cookies.set("necessary_cookies", true);
    Cookies.set("personalization_cookies", true);
    Cookies.set("statistics_cookies", true);
    setShowFirstModal(false);
  };

  const handleFirstModalRejectAll = () => {
    Cookies.set("necessary_cookies", true);
    Cookies.set("personalization_cookies", false);
    Cookies.set("statistics_cookies", false);
    setShowFirstModal(false);
  };

  return (
    <>

{showFirstModal && (
        <CookiesModal
          title="Cookies y privacidad"
          subtitle="Nuestro sitio web utiliza cookies enviadas tanto por nosotros como por terceros. Algunas de estas cookies son necesarias para el funcionamiento del sitio web, mientras que otras las puedes ajustar tú mismo en cualquier momento. En particular, las que nos permiten comprender el rendimiento de nuestro sitio web, te proporcionan funciones de redes sociales y te ofrecen una mejor experiencia al presentarte contenidos y publicidad más ajustados a tus gustos."
          buttonText="Cambiar tu configuración de cookies"
          acceptAllText="Aceptar todo"
          rejectAllText="Rechazar todo"
          onAcceptAll={handleFirstModalAcceptAll}
          onRejectAll={handleFirstModalRejectAll}
          onButtonClick={() => setShowSecondModal(true)}
        />
      )}
</>
  )
}

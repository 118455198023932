// react
import React from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import { Cart16Svg } from '../../svg';
import { cartAddItem } from '../../store/cart';


function Suggestions(props) {
    const {
        total,
        query,
        context,
        className,
        products,
        cartAddItem,
    } = props;
    const rootClasses = classNames(`suggestions suggestions--location--${context}`, className);

    const list = (products && products.map((product) => (
        <li key={product.id} className="suggestions__item">
            {product.images && product.images.length > 0 && (
                <div className="suggestions__item-image">
                    <img src={product.images[0]} alt="" />
                </div>
            )}
            <div className="suggestions__item-info">
                <Link
                    className="suggestions__item-name"
                    to={`/shop/product/${product.code.replace(/\//g, '-')}/${product.id}`}
                >
                    {product.name}
                </Link>
                <div className="suggestions__item-meta">{`Código: ${product.code}`}</div>
                <div className="suggestions__item-meta">{`Código de Manofactura: ${product.code_manufacture}`}</div>
            </div>
            <div className="suggestions__item-price">
                <Currency value={product.price} />
            </div>
            {context === 'header' && (
                <div className="suggestions__item-actions">
                    <AsyncAction
                        action={() => cartAddItem(product)}
                        render={({ run, loading }) => (
                            <button
                                type="button"
                                onClick={run}
                                title="Add to cart"
                                className={classNames('btn btn-primary btn-sm btn-svg-icon', {
                                    'btn-loading': loading,
                                })}
                            >
                                <Cart16Svg />
                            </button>
                        )}
                    />
                </div>
            )}
        </li>

    )));

    return (
        <div className={rootClasses}>
            <ul className="suggestions__list">
                {list}
                {total > 5 ? (
                    <li className="suggestions__item">
                        <div className="suggestions__item-info" style={{textAlign: 'center'}}>
                            <Link className="suggestions__item-name" to={`/shop/search/${query}`}>
                                Ver todos los resultados...
                            </Link>
                            <div className="suggestions__item-meta">{`Resultados: ${total}`}</div>
                        </div>
                    </li>
                ) : null}
            </ul>
        </div>
    );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItem,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Suggestions);

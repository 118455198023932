// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';

// application
import PageHeader from '../shared/PageHeader';
import Project from './Project';


function PageProject(props) {
    const { projects, match: { params: { title, id }} } = props;
    const project = projects.find((e) => e.id == id);
    if (!project) {
        return (
            <div className="container">
                <br />
                <br />
                <br />
                <div className="div-loading" />
                <br />
                <br />
                <br />
            </div>
        );
    }
    const content = (
        <div className="row justify-content-center">
            <div className="col-md-12 col-lg-9 col-xl-8">
                <Project project={project} />
            </div>
        </div>
    );

    const breadcrumbs = [
        { title: 'Proyectos', url: '/proyectos' },
        { title: title.replace(/_/g, ' '), url: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{title.replace(/_/g, ' ')}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumbs} />

            <div className="container">{content}</div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    projects: state.pagesContent.projects,
});

const mapDispatchToProps = {
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PageProject);

// react
import React, { Component } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// application
import AsyncAction from "./AsyncAction";
import Currency from "./Currency";
import InputNumber from "./InputNumber";
import ProductGallery from "./ProductGallery";
import { cartAddItem } from "../../store/cart";
import { compareAddItem } from "../../store/compare";
import { Wishlist16Svg, Compare16Svg } from "../../svg";
import { wishlistAddItem } from "../../store/wishlist";
import { red } from "@material-ui/core/colors";

class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            quantity: 1,
        };
    }
    handleChangeQuantity = (quantity) => {
        this.setState({ quantity });
    };
    
    render() {
        const discountStyle ={
            fontSize:"16px"
        }
        const { product, layout, wishlistAddItem, compareAddItem, cartAddItem } = this.props;
        const { quantity } = this.state;
        let prices;

        if (product.discount) {
            prices = (
                <React.Fragment>
                    <span>
                    <Currency value={Number(product.price)} />

                    {/* <Currency value={product.price} /> */}
                                        </span>{" "}
                    <span className="product__old-price">
                        {/* ${product.price_without_sale_price} */}
                        <Currency value={Number(product.sale_price)} />

                    </span>
                </React.Fragment>
            );
        } else {
            prices = <Currency value={product.price} />;

        }
        return (
            <div className={`product product--layout--${layout}`}>
                <div className="product__content">
                    <ProductGallery layout={layout} images={product.images} />

                    <div className="product__info">
                        <div className="product__wishlist-compare">
                            <AsyncAction
                                action={() => wishlistAddItem(product)}
                                render={({ run, loading }) => (
                                    <button
                                        type="button"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Wishlist"
                                        onClick={run}
                                        className={classNames("btn btn-sm btn-light btn-svg-icon", {
                                            "btn-loading": loading,
                                        })}
                                    >
                                        <Wishlist16Svg />
                                    </button>
                                )}
                            />
                            <AsyncAction
                                action={() => compareAddItem(product)}
                                render={({ run, loading }) => (
                                    <button
                                        type="button"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Compare"
                                        onClick={run}
                                        className={classNames("btn btn-sm btn-light btn-svg-icon", {
                                            "btn-loading": loading,
                                        })}
                                    >
                                        <Compare16Svg />
                                    </button>
                                )}
                            />
                        </div>
                        <h3 className="product__name">{product.name}</h3>
                        <div className="product__description">{product.description}</div>
                        <ul className="product__features"></ul>
                        <ul className="product__meta">
                            <li>{`Código: ${product.code}`}</li>
                            <li>{`Código de Fabricante: ${product.code_manufacture}`}</li>
                        </ul>
                        <ul className="product__meta">
                            <li className="product__meta-availability">
                                Disponible:
                                {"  "}
                                <span className="text-success">{product.available > 0 ? product.available : "0"}</span>
                            </li>
                            <li className="product__meta-availability">
                                En Tránsito:
                                {"  "}
                                <span className="text-success">{product.OnOrder > 0 ? product.OnOrder : "0"}</span>
                            </li>
                            <div className="product-card__availability">
                                Bodega externa: <span className="text-info">{product.externalQty}</span>
                            </div>
                        </ul>
                        {product.bod01 < 1 && product.bod05 < 1 ? (
                            <ul className="product__meta">
                                <li className="product__meta-availability" style={{ width: "100%" }}>
                                    <span className="text-success">{`Para entrega de ${product.days} a ${product.days + 5} días hábiles.`}</span>
                                </li>
                                {product.existence &&
                                    product.existence.length > 0 &&
                                    product.existence.map((ele) => (
                                        <li className="product__meta-availability" style={{ width: "100%" }}>
                                            {ele.inv.toUpperCase()}
                                            <span className="text-success">{` : disponible en ${ele.time} Días, ${ele.qty} un.`}</span>
                                        </li>
                                    ))}
                            </ul>
                        ) : (
                            <ul className="product__meta">
                                {product.bod01 > 0 && (
                                    <li className="product__meta-availability">
                                        Almacén 01:{" "}
                                        <span className="text-success">{`Disponibilidad Inmediata: ${
                                            product.bod01 > 0 ? product.bod01 : "0"
                                        } un.`}</span>
                                    </li>
                                )}
                                {product.bod05 > 0 && (
                                    <li className="product__meta-availability">
                                        Almacén 05:
                                        {"  "}
                                        <span className="text-success">{`Disponible en 5 días hábiles: ${
                                            product.bod05 > 0 ? product.bod05 : "0"
                                        } un.`}</span>
                                    </li>
                                )}
                                {product.sale_price &&<h3 className="title-card-details">Oferta</h3>}

                                {product.existence &&
                                    product.existence.length > 0 &&
                                    product.existence.map((ele) => (
                                        <li className="product__meta-availability" style={{ width: "100%" }}>
                                            {ele.inv.toUpperCase()}
                                            <span className="text-success">{` : disponible en ${ele.time} Días, ${ele.qty} un.`}</span>
                                        </li>
                                    ))}
                            </ul>
                        )}
                    </div>

                    <div className="product__sidebar">
                        <div className="product__availability">
                            Disponible:
                            {"  "}
                            <span className="text-success">{product.available > 1 ? product.available : "0"}</span>
                        </div>
                        {product.discount?
                        <div className="product__prices"> <span style={{color:"#b30000", fontWeight:"100"}}> -{product.discount}%</span> {prices} <span style={discountStyle}></span> </div>
                        :
                        <div className="product__prices">{prices}</div>
                    }

                        <form className="product__options">
                            <div className="form-group product__option">
                                <label htmlFor="product-quantity" className="product__option-label">
                                    Cantidad
                                </label>
                                <div className="product__actions">
                                    <div className="product__actions-item">
                                        <InputNumber
                                            id="product-quantity"
                                            aria-label="Quantity"
                                            className="product__quantity"
                                            size="lg"
                                            min={1}
                                            value={quantity}
                                            onChange={this.handleChangeQuantity}
                                        />
                                    </div>
                                    <div className="product__actions-item product__actions-item--addtocart">
                                        <AsyncAction
                                            action={() => cartAddItem(product, [], quantity)}
                                            render={({ run, loading }) => (
                                                <button
                                                    type="button"
                                                    onClick={run}
                                                    className={classNames("btn btn-primary btn-lg", {
                                                        "btn-loading": loading,
                                                    })}
                                                >
                                                    Enviar al carrito
                                                </button>
                                            )}
                                        />
                                    </div>
                                    {/* <div className="product__actions-item product__actions-item--wishlist">
                                        <AsyncAction
                                            action={() => wishlistAddItem(product)}
                                            render={({ run, loading }) => (
                                                <button
                                                    type="button"
                                                    data-toggle="tooltip"
                                                    title="Wishlist"
                                                    onClick={run}
                                                    className={classNames("btn btn-secondary btn-svg-icon btn-lg", {
                                                        "btn-loading": loading,
                                                    })}
                                                >
                                                    <Wishlist16Svg />
                                                </button>
                                            )}
                                        />
                                    </div>
                                    <div className="product__actions-item product__actions-item--compare">
                                        <AsyncAction
                                            action={() => compareAddItem(product)}
                                            render={({ run, loading }) => (
                                                <button
                                                    type="button"
                                                    data-toggle="tooltip"
                                                    title="Compare"
                                                    onClick={run}
                                                    className={classNames("btn btn-secondary btn-svg-icon btn-lg", {
                                                        "btn-loading": loading,
                                                    })}
                                                >
                                                    <Compare16Svg />
                                                </button>
                                            )}
                                        />
                                    </div> */}
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="product__footer">
                        <div className="product__tags tags">
                            <div className="tags__list">
                                <Link to="/">Mounts</Link>
                                <Link to="/">Electrodes</Link>
                                <Link to="/">Chainsaws</Link>
                            </div>
                        </div>

                        <div className="product__share-links share-links">
                            <ul className="share-links__list">
                                <li className="share-links__item share-links__item--type--like">
                                    <Link to="/">Like</Link>
                                </li>
                                <li className="share-links__item share-links__item--type--tweet">
                                    <Link to="/">Tweet</Link>
                                </li>
                                <li className="share-links__item share-links__item--type--pin">
                                    <Link to="/">Pin It</Link>
                                </li>
                                <li className="share-links__item share-links__item--type--counter">
                                    <Link to="/">4K</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Product.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
};

Product.defaultProps = {
    layout: "standard",
};

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
};

export default connect(() => ({}), mapDispatchToProps)(Product);

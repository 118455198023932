// react
import React, { Component } from 'react';

// application
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Collapse from '../shared/Collapse';
import AccountFormAddress from '../account/AccountFormAddress';

class SelectAddressShipping extends Component {
    constructor(props) {
        super(props);
        const { currentUser, shippingAddress } = props;
        this.state = {
            address: currentUser ? currentUser.addresses : [],
            defaultaddressShipping: shippingAddress && currentUser ? shippingAddress.id : null,
            open: false,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.currentUser && nextProps.currentUser.addresses.length !== prevState.address.length) {
            return {
                address: nextProps.currentUser.addresses,
                open: false,
            };
        }
        return null;
    }

    handleAddressShippingChange = (event) => {
        const { handleAddressChange } = this.props;
        if (event.target.checked) {
            const address = JSON.parse(event.target.value);
            this.setState({ defaultaddressShipping: address.id });
            handleAddressChange(address);
        }
    };


    renderAddressShippingList() {
        const { address, defaultaddressShipping } = this.state;
        const { sending } = this.props;

        const addresses = address.map((address) => {
            const renderAddressShipping = ({ setItemRef, setContentRef }) => (
                <li className="payment-methods__item" ref={setItemRef}>
                    <label className="payment-methods__item-header">
                        <span className="payment-methods__item-radio input-radio">
                            <span className="input-radio__body">
                                <input
                                    type="radio"
                                    className="input-radio__input"
                                    name="shipping_address"
                                    value={JSON.stringify(address)}
                                    checked={defaultaddressShipping == address.id}
                                    onChange={this.handleAddressShippingChange}
                                    disabled={sending}
                                />
                                <span className="input-radio__circle" />
                            </span>
                        </span>
                        <span className="payment-methods__item-title">{address.responsable}</span>
                    </label>
                    <div className="payment-methods__item-container" ref={setContentRef}>
                        <div className="payment-methods__item-description text-muted">
                            <div>{`${address.address}.${address.countryid.countryname === 'Panamá' && address.deliverycityid ? ` ${address.deliverycityid.cityname}.` : ''} ${address.countryid.countryname}`}</div>
                            <br />
                            <div className="address-card__row">
                                <div className="address-card__row-title">Telefono Principal</div>
                                <div className="address-card__row-content">{address.phone1}</div>
                            </div>
                        </div>
                    </div>
                </li>
            );
            return (
                <Collapse
                    key={address.id}
                    open={defaultaddressShipping == address.id}
                    toggleClass="payment-methods__item--active"
                    render={renderAddressShipping}
                />
            );
        });

        return (
            <div className="payment-methods">
                <ul className="payment-methods__list">
                    {addresses}
                </ul>
            </div>
        );
    }

    render() {
        const { open } = this.state;
        return (
            <React.Fragment>
                <div className="card mb-lg-0">
                    <div className="card-body">
                        <h3 className="card-title">Dirección de Envío</h3>
                        {this.renderAddressShippingList()}
                        <button type="button" className="btn btn-success" onClick={() => this.setState({ open: true })}>Nueva Dirección</button>
                    </div>
                </div>
                <Modal isOpen={open} toggle={() => this.setState({ open: false })} centered size="lg">
                    <ModalHeader toggle={() => this.setState({ open: false })} />
                    <ModalBody>
                        <AccountFormAddress type="Agregar" cart />
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export default SelectAddressShipping;

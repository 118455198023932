import { CURRENT_USER, ADDRESSES_USER } from './currentUserActionTypes';

const initialState = null;

export default function currentUserReducer(state = initialState, action) {
    if (action.type === CURRENT_USER) {
        return JSON.parse(JSON.stringify(action.currentUser));
    }
    if (action.type === ADDRESSES_USER) {
        return {...state, addresses: action.addresses};
    }
    return state;
}

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { filtersChange, dropFilters } from '../../store/pagesContent';
import { event } from 'jquery';

function FilterSelect(props) {
    const {
        filter,
        filtersChange,
        dropFilters,
        filterOn,
    } = props;

    // Initialize state with the value from filterOn or an empty array if not present
    const[showFilter, setShowFilter]= useState(false)
    const [state, setState] = useState(() => {
        const selectedFilter = filterOn.find((e) => e.name === filter.name);
        return selectedFilter ? selectedFilter.value : [];
    });

    useEffect(() => {
        // Update state when filterOn changes
        const selectedFilter = filterOn.find((e) => e.name === filter.name);
        setState(selectedFilter ? selectedFilter.value : []);
    }, [filterOn, filter.name]);

    const handleChange = (value) => {
        const isSelected = state.includes(value);
        const updatedValues = isSelected
            ? state.filter((val) => val !== value)
            : [...state, value];

        const filtro = {
            name: filter.name,
            value: updatedValues,
        };

        // Dispatch actions to update the filter state
        if (filtro.value.length > 0) {
            filtersChange(filtro);
        } else {
            dropFilters(filtro);
        }

        // Update local state
        setState(updatedValues);
    };
    return (
        <div className="form-group" >
            <div>
                <div className="form-group-filter" onClick={()=>setShowFilter(prev=> !prev)}>
                    {filter.name.replace(/^\w/, (c) => c.toUpperCase())}
                    {showFilter ?
                    <i class="fa fa-arrow-circle-up" aria-hidden="true"></i> :<i class="fa fa-arrow-circle-down" aria-hidden="true"></i>
                }
                                    </div>
                {showFilter && filter.options.sort((a, b) => a - b).map((e) => (
                    <div className='filter-Check' key={e}>
                        <input
                            type="checkbox"
                            value={e}
                            checked={state.includes(e)}
                            onChange={() => handleChange(e)}
                        />
                        <label>{e}</label>
                    </div>
                ))}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    filterOn: state.pagesContent.filters,
});

const mapDispatchToProps = {
    filtersChange,
    dropFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterSelect);

// react
import React, { useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { ChangedPassword } from '../../store/directusSdk';

function AccountPagePassword(props) {
    const { currentUser } = props;
    let [pwd, pwd2] = useState(null);

    function handleSubmit() {
        if (pwd === pwd2 && pwd !== '') {
            const result = ChangedPassword(currentUser.id, pwd);
            result.then(res => {
                props.history.push('/account/dashboard');

            });
        }
    }
    function handlepwd(e){
        pwd = e.target.value;
    }
    function handlepwd2(e){
        pwd2 = e.target.value; 
    }
    
    return (
        <div className="card">
            <Helmet>
                <title>Cambiar Contraseña — ICautomatizados </title>
            </Helmet>
            <div className="card-header">
                <h5>Cambiar Contraseña</h5>
            </div>
            <div className="card-divider" />
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-7 col-xl-6">
                        <div className="form-group">
                            <label htmlFor="password-new">Nueva Contraseña</label>
                            <input
                                type="password"
                                className="form-control"
                                id="password-new"
                                placeholder="Nueva Contraseña"
                                onChange={handlepwd}
                                required
                                minLength="6"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password-confirm">Repita Contraseña</label>
                            <input
                                type="password"
                                className="form-control"
                                id="password-confirm"
                                placeholder="Repita Contraña"
                                onChange={handlepwd2}
                                required
                                minLength="6"
                            />
                        </div>

                        <div className="form-group mt-5 mb-0">
                            <button type="button" className="btn btn-primary" onClick={handleSubmit} >Cambiar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPagePassword);

import React, {useState} from "react";
import Switch from "react-switch";

export default function CookiePreference(props) {
    const [showMore, setShowMore] = useState(false)
    const handleShowMore = () => {
        setShowMore(prev => !prev)
      }
      return (
      <>
    <div className="cookie-modal-item">
                  <div className="cookie-modal-head">
                    <span className="modalName"> <span  className="showMore" onClick={handleShowMore}>+</span>{props.name}</span>
                    <Switch
                      checked={props.title}
                      onChange={props.action}
                      disabled={props.disable}
                      onColor="#006CBE"
                      />
                  </div>
                  {showMore &&<p className='cookieText' >{props.text}</p>}
                </div>
                      </>
      )
    }

// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// application
import AppLink from '../shared/AppLink';
import languages from '../../i18n';
import Megamenu from './Megamenu';
import Menu from './Menu';
import { ArrowRoundedDown9x6Svg } from '../../svg';
import { useLocation } from 'react-router-dom';
function NavLinks(props) {
    const { MenuWeb } = props;
    const location = useLocation();
    const handleMouseEnter = (event) => {
        const { locale } = props;
        const { direction } = languages[locale];

        const item = event.currentTarget;
        const megamenu = item.querySelector('.nav-links__megamenu');

        if (megamenu) {
            const container = megamenu.offsetParent;
            const containerWidth = container.getBoundingClientRect().width;
            const megamenuWidth = megamenu.getBoundingClientRect().width;
            const itemOffsetLeft = item.offsetLeft;

            if (direction === 'rtl') {
                const itemPosition = containerWidth - (
                    itemOffsetLeft + item.getBoundingClientRect().width
                );

                const megamenuPosition = Math.round(
                    Math.min(itemPosition, containerWidth - megamenuWidth),
                );

                megamenu.style.left = '';
                megamenu.style.right = `${megamenuPosition}px`;
            } else {
                const megamenuPosition = Math.round(
                    Math.min(itemOffsetLeft, containerWidth - megamenuWidth),
                );

                megamenu.style.right = '';
                megamenu.style.left = `${megamenuPosition}px`;
            }
        }
    };
    const ActiveLinkStyle = {
        borderBottom: '4px solid #fff',
        transition: 'border-bottom 0.3s ease'
      };
      
      const linksList = MenuWeb.map((item, index) => {
        let arrow;
        let submenu;
    
        if (item.submenu) {
          arrow = <ArrowRoundedDown9x6Svg className="nav-links__arrow" />;
        }
    
        if (item.submenu && item.submenu.type === 'menu') {
          submenu = (
            <div className="nav-links__menu">
              <Menu items={item.submenu.menu} />
            </div>
          );
        }
    
        if (item.submenu && item.submenu.type === 'megamenu') {
          submenu = (
            <div className={`nav-links__megamenu nav-links__megamenu--size--${item.submenu.menu.size}`}>
              <Megamenu menu={item.submenu.menu} />
            </div>
          );
        }
    
        const classes = classNames('nav-links__item', {
          'nav-links__item--with-submenu': item.submenu,
          'nav-links__item--active': location.pathname === item.url, // Comparar con la URL actual
        });
    
        return (
          <li key={index} className={classes} onMouseEnter={handleMouseEnter} style={location.pathname === item.url ? ActiveLinkStyle : null}>
            <AppLink to={item.url} {...item.props}>
              <span>
                {item.title}
                {arrow}
              </span>
            </AppLink>
            {submenu}
          </li>
        );
      });

    return (
        <ul className="nav-links__list">
            {linksList} 
        </ul>
    );
}

NavLinks.propTypes = {
    /** current locale */
    locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
    MenuWeb: state.pagesContent.menuweb,
});

export default connect(mapStateToProps)(NavLinks);

// application
import enMessages from './messages/en.json';


export default {
    en: {
        messages: enMessages,
        direction: 'ltr',
    },
};

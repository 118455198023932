import {
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_UPDATE_QUANTITIES,
    CART_REMOVE_ALL_ITEMS,
    CART_UPDATE_PRICE,
    CART_LOAD_ALL_ITEMS,
    RECALCULATE_SHIPPING,
    CHANGE_QTY
} from './cartActionTypes';

function isClientInternational() {
    const state = JSON.parse(localStorage.getItem('state'));
    const { currentUser } = state;
    if (currentUser && currentUser.countryid && currentUser.countryid.countrycode !== 'PA') {
        return true;
    }
    return false;
}
function findItemIndex(items, product, options) {
    return items.findIndex((item) => {
        if (item.product.id !== product.id || item.options.length !== options.length) {
            return false;
        }

        for (let i = 0; i < options.length; i += 1) {
            const option = options[i];
            const itemOption = item.options.find((itemOption) => (
                itemOption.optionId === option.optionId && itemOption.valueId === option.valueId
            ));

            if (!itemOption) {
                return false;
            }
        }
        return true;
    });
}

export function calcSubtotal(items) {
    const subtotal = parseFloat(items.reduce((subtotal, item) => subtotal + item.total, 0).toFixed(2));
    return subtotal;
}
function calcQuantity(items) {
    return items.reduce((quantity, item) => quantity + item.quantity, 0);
}
function calcShipping(subtotal, deliveryPrices, shippingAddress) {
    if (deliveryPrices) {
        const i = deliveryPrices.findIndex((e) => subtotal >= e.topprice);
        const { delivery, freedelivery, price } = deliveryPrices[(i !== -1 ? i : deliveryPrices.length - 1)];
        const shipping = freedelivery ? 0 : price;
        const msgEnvio = freedelivery ? '¡Envio Gratis!' : (delivery ? null : 'Envios para esta ciudad a partir de 500$.');
        return { delivery, freedelivery, shipping, msgEnvio };
    }
    if (shippingAddress && shippingAddress.countryid.countryname !== 'Panamá') {
        return { delivery: false, msgEnvio: 'Sera contactado para cotizar.', freedelivery: false, shipping: 0 };
    }
    return { delivery: false, freedelivery: false, shipping: 0, msgEnvio: null };
}
export function calcITBMS(subtotal, shipping) {
    if (isClientInternational()) {
        return 0;
    }
    const itbms = parseFloat((subtotal * 0.07).toFixed(2)) + parseFloat((shipping * 0.07).toFixed(2));
    return itbms;
}
export function calcTotal(subtotal, shipping) {
    const total = subtotal + shipping + calcITBMS(subtotal, shipping);
    return total;
}

function addItem(state, product, options, quantity) {
    const itemIndex = findItemIndex(state.items, product, options);

    let newItems;
    let { lastItemId } = state;

    if (itemIndex === -1) {
        lastItemId += 1;
        newItems = [...state.items, {
            id: lastItemId,
            product: JSON.parse(JSON.stringify(product)),
            options: JSON.parse(JSON.stringify(options)),
            price: product.price,
            total: product.price * quantity,
            quantity,
        }];
    } else {
        const item = state.items[itemIndex];

        newItems = [
            ...state.items.slice(0, itemIndex),
            {
                ...item,
                quantity: item.quantity + quantity,
                total: (item.quantity + quantity) * item.price,
            },
            ...state.items.slice(itemIndex + 1),
        ];
    }

    const subtotal = calcSubtotal(newItems);
    const envio = calcShipping(subtotal, state.deliveriesPrices, state.shippingAddress);
    const itbms = calcITBMS(subtotal, envio.shipping);
    const total = calcTotal(subtotal, envio.shipping);

    return {
        ...state,
        lastItemId,
        subtotal,
        itbms,
        total,
        items: newItems,
        quantity: calcQuantity(newItems),
        delivery: envio.delivery,
        msgEnvio: envio.msgEnvio,
        freedelivery: envio.freedelivery,
        shipping: envio.shipping,
    };
}

function removeItem(state, itemId) {
    const { items } = state;
    const newItems = items.filter((item) => item.id !== itemId);

    const subtotal = calcSubtotal(newItems);
    const envio = calcShipping(subtotal, state.deliveriesPrices, state.shippingAddress);
    const itbms = calcITBMS(subtotal, envio.shipping);
    const total = calcTotal(subtotal, envio.shipping);

    return {
        ...state,
        items: newItems,
        quantity: calcQuantity(newItems),
        delivery: envio.delivery,
        msgEnvio: envio.msgEnvio,
        freedelivery: envio.freedelivery,
        shipping: envio.shipping,
        subtotal,
        itbms,
        total,
    };
}

function updateQuantities(state, quantities) {
    let needUpdate = false;

    const newItems = state.items.map((item) => {
        const quantity = quantities.find((x) => x.itemId === item.id && x.value !== item.quantity);

        if (!quantity) {
            return item;
        }

        needUpdate = true;

        return {
            ...item,
            quantity: quantity.value,
            total: quantity.value * item.price,
        };
    });

    if (needUpdate) {
        const subtotal = calcSubtotal(newItems);
        const envio = calcShipping(subtotal, state.deliveriesPrices, state.shippingAddress);
        const itbms = calcITBMS(subtotal, envio.shipping);
        const total = calcTotal(subtotal, envio.shipping);

        return {
            ...state,
            items: newItems,
            quantity: calcQuantity(newItems),
            delivery: envio.delivery,
            msgEnvio: envio.msgEnvio,
            freedelivery: envio.freedelivery,
            shipping: envio.shipping,
            subtotal,
            itbms,
            total,
        };
    }

    return state;
}

function updatePrices(state, products, action) {
    let needUpdate = false;

    const newItems = state.items.map((item) => {
        const product = products.find((x) => x.id === item.product.id && x.price !== item.price);

        if (!product) {
            return item;
        }
        needUpdate = true;

        return {
            ...item,
            price: product.price,
            total: parseFloat((item.quantity * product.price).toFixed(2)),
        };
    });

    if (needUpdate) {
        const shippingAddress = action === 'logout' ? null : state.shippingAddress;
        const deliveriesPrices = action === 'logout' ? null : state.deliveriesPrices;
        const incEnvio = action === 'logout' ? false : state.incEnvio;
        const subtotal = calcSubtotal(newItems);
        const envio = calcShipping(subtotal, deliveriesPrices, shippingAddress);
        const itbms = calcITBMS(subtotal, envio.shipping);
        const total = calcTotal(subtotal, envio.shipping);
        return {
            ...state,
            items: newItems,
            shippingAddress,
            incEnvio,
            deliveriesPrices,
            delivery: envio.delivery,
            msgEnvio: envio.msgEnvio,
            freedelivery: envio.freedelivery,
            shipping: envio.shipping,
            subtotal,
            itbms,
            total,
        };
    }
    return state;
}

function RecalculateShipping(state, shippingAddress, incEnvio) {
    if (!incEnvio) {
        const itbms = calcITBMS(state.subtotal, 0);
        const total = calcTotal(state.subtotal, 0);
        console.log('No incluye Envio!');
        return {
            ...state,
            shippingAddress: null,
            incEnvio: false,
            msgEnvio: null,
            deliveriesPrices: null,
            delivery: false,
            freedelivery: false,
            shipping: 0,
            itbms,
            total,
        };
    }
    if (incEnvio && shippingAddress && shippingAddress.countryid.countryname === 'Panamá') {
        console.log('Envio Nacional!');
        const deliveriesPrices = shippingAddress.deliverycityid.deliveryprices.sort((a, b) => b.topprice - a.topprice);
        const envio = calcShipping(state.subtotal, deliveriesPrices, shippingAddress);
        const itbms = calcITBMS(state.subtotal, envio.shipping);
        const total = calcTotal(state.subtotal, envio.shipping);

        return {
            ...state,
            deliveriesPrices,
            incEnvio: true,
            shippingAddress,
            delivery: envio.delivery,
            msgEnvio: envio.msgEnvio,
            freedelivery: envio.freedelivery,
            shipping: envio.shipping,
            itbms,
            total,
        };
    }
    if (incEnvio && shippingAddress && shippingAddress.countryid.countryname !== 'Panamá') {
        const itbms = calcITBMS(state.subtotal, 0);
        const total = calcTotal(state.subtotal, 0);
        console.log('Envio Internacional!');

        return {
            ...state,
            deliveriesPrices: null,
            incEnvio: true,
            shippingAddress,
            delivery: false,
            msgEnvio: 'Sera contactado para cotizar.',
            freedelivery: false,
            shipping: 0,
            itbms,
            total,
        };
    }
    const itbms = calcITBMS(state.subtotal, 0);
    const total = calcTotal(state.subtotal, 0);
    console.log('Return only');
    return {
        ...state,
        incEnvio: false,
        msgEnvio: null,
        shippingAddress: null,
        deliveriesPrices: null,
        delivery: false,
        freedelivery: false,
        shipping: 0,
        itbms,
        total,
    };
}

function editQty(state, index, qty) {
    if (index !== -1) {
        const { items } = state;
        items[index].quantity = qty;
        items[index].total = qty * items[index].price;

        const subtotal = calcSubtotal(items);
        const itbms = calcITBMS(subtotal, state.shipping);
        const total = calcTotal(subtotal, state.shipping);

        return {
            ...state,
            items,
            subtotal,
            itbms,
            total
        };
    }
    return state;
}

const initialState = {
    lastItemId: 0,
    deliveriesPrices: null,
    delivery: true,
    freedelivery: false,
    incEnvio: false,
    msgEnvio: null,
    shippingAddress: null,
    quantity: 0,
    items: [],
    subtotal: 0,
    shipping: 0,
    itbms: 0,
    total: 0,
};

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
        case CART_ADD_ITEM:
            return addItem(state, action.product, action.options, action.quantity);

        case CART_REMOVE_ITEM:
            return removeItem(state, action.itemId);

        case CART_UPDATE_QUANTITIES:
            return updateQuantities(state, action.quantities);

        case CART_UPDATE_PRICE:
            return updatePrices(state, action.items, action.action);

        case RECALCULATE_SHIPPING:
            return RecalculateShipping(state, action.shippingAddress, action.incEnvio);

        case CART_REMOVE_ALL_ITEMS:
            return initialState;

        case CART_LOAD_ALL_ITEMS:
            return action.cart;

        case CHANGE_QTY:
            return editQty(state, action.index, action.qty);

        default:
            return state;
    }
}

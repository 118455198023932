// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';

function SitePageAboutUs(props) {
    const { company, aboutUs } = props;
    return (
        <div className="block-s about-us">
            <Helmet>
                <title>{`Nosotros — ${company[0].name}`}</title>
            </Helmet>

            <div className="about-us__image" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/${aboutUs.banner})` }} />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="container_about">
                        <div className="about-us__body">
                            <div
                                className="about-us__text typography"
                                dangerouslySetInnerHTML={{ __html: aboutUs.body }}
                            />
                        </div>
                        <div className="about-us__body">
                            <div
                                className="about-us__text typography"
                                dangerouslySetInnerHTML={{ __html: aboutUs.side_bar }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SitePageAboutUs;

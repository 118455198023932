// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


function ProjectCard(props) {
    const { project, layout } = props;
    const cardClasses = classNames(
        'post-card',
        {
            'post-card--layout--grid': ['grid-nl', 'grid-lg'].includes(layout),
            'post-card--layout--list': ['list-nl', 'list-sm'].includes(layout),
            'post-card--size--nl': ['grid-nl', 'list-nl'].includes(layout),
            'post-card--size--lg': layout === 'grid-lg',
            'post-card--size--sm': layout === 'list-sm',
        },
    );
    const slug = project.title.replace(/ /gi,'_');

    return (
        <>
        <div className={cardClasses}>
            <div className="post-card__image">
                <Link to={`/proyectos/${slug}/${project.id}`}>
                    <img src={`${process.env.REACT_APP_API_URL}assets/${project.projectimage}`} alt="" />
                </Link>
            </div>
            <div className="post-card__info">
                <div className="post-card__name">
                    <Link to={`/proyectos/${slug}/${project.id}`}>{project.title}</Link>
                </div>
                <div className="post-card__content">
                    <p align="justify">
                    {project.description}
                    </p>
                </div>

</div>               
        </div>
                    
                  <div className="button_read_mor_container">
                    <Link style={{zIndex:999}} to={`/proyectos/${slug}/${project.id}`} className="button_read_more">Leer más...</Link>
                </div>
        </>
);
}

ProjectCard.propTypes = {
    /**
     * post data object
     */
    project: PropTypes.object,
    /**
     * post card layout
     * one of ['grid-nl', 'grid-lg', 'list-nl', 'list-sm']
     */
    layout: PropTypes.oneOf(['grid-nl', 'grid-lg', 'list-nl', 'list-sm']),
};

export default ProjectCard;

// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import AccountFormAddress from './AccountFormAddress';

function AccountAddAddress() {
    return (
        <div>
            <Helmet>
                <title>Nueva Dirección - ICAutomatizados S.A</title>
            </Helmet>

            <AccountFormAddress type="Agregar" />
        </div>
    );
}

export default AccountAddAddress;
// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
// application
import moment from 'moment';
import 'moment/locale/es-us';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getAvailable, getAvailablePrices } from '../../store/directusSdk';
import { cartLoadItemsSuccess } from '../../store/cart';
import Currency from '../shared/Currency';
import Pagination from '../shared/Pagination';
import { Cross20Svg } from '../../svg';
import { getQuotationById } from '../../store/directusSdk';

class AccountPageQuotationList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            quotations: [],
            page: 1,
            pages: 1,
            start: 0,
            end: 9,
            sinQuotations: false,
            open: false,
            open2: false,
            quote: null,
            loading: false,
            loading2: true,
        };
    }

    componentDidMount() {
        const { currentUser, getQuotations } = this.props;
        const { page } = this.state;
        const allOrders = getQuotations(currentUser.id);
        allOrders.then((res) => {
            if (res.data.length > 0) {
                const quotations = res.data;
                const pages = Math.ceil(quotations.length / 10);
                const end = page * 10;
                const start = end - 10;
                this.setState(() => ({ quotations, pages, end, start, loading2: false }));
            } else {
                this.setState(() => ({ sinQuotations: true, loading2: false }));

            }
        });
    }

    handlePageChange = (page) => {
        const end = page * 10;
        const start = end - 10;
        this.setState(() => ({ page, end, start }));
    };

    ViewQuotation = (quote) => {
        this.setState({ open: true, quote: quote.items });
    };

     GeneratePDF(e) {
        // this.setState({ loading: true });
        // sessionStorage.setItem('strCart', e.cart);
        // // window.open(`/cotizacion?id=${e.id}`, '_blank');
        // window.open(`${process.env.REACT_APP_API_URL}assets/${e.pdf}.pdf`, '_blank')
        // this.setState({ loading: false });
        this.setState({ loading: true });
        const data = getQuotationById(e.id).then( data => 
            data.data[0].pdf
            )
        data.then(res =>  window.open(`${process.env.REACT_APP_API_URL}assets/${res}.pdf`, '_blank'))
        this.setState({ loading: false });
    }

    LoadToCart(quote) {
        const { history } = this.props;
        this.setState({ loading: true });
        const { cartLoadItemsSuccess } = this.props;
        if (quote.isValidity) {
            getAvailable(JSON.parse(quote.cart)).then((cart) => {
                cartLoadItemsSuccess(cart);
                this.setState({ loading: false, quote: null });
                history.push('/shop/cart');
            });
        } else {
            getAvailablePrices(JSON.parse(quote.cart)).then((cart) => {
                cartLoadItemsSuccess(cart);
                this.setState({ loading: false, quote: null });
                history.push('/shop/cart');
            });
        }
    }
    

    render() {
        const { company } = this.props;
        const { page, quotations, pages, end, start, sinQuotations, open, open2, quote, loading, loading2 } = this.state;


        const quotationsList = quotations.map((quotation) => {
            const date = moment(quotation.created_on, 'YYYY/MM/DD HH:mm:ss', 'es-us');
            const dateFormated = date.add(date.utcOffset(), 'minutes').format('DD/MM/YYYY');
            quotation.isValidity = moment().isSameOrBefore(quotation.validity);
            return (
                <tr key={quotation.id}>
                    <td>{`# ${quotation.id}`}</td>
                    <td>{dateFormated}</td>
                    <td>{moment(quotation.validity).format('DD/MM/YYYY')}</td>
                    <td>
                        <div className={`badge badge-${quotation.isValidity ? 'success' : 'danger'}`}>{quotation.isValidity ? 'Vigente' : 'Vencida'}</div>
                    </td>
                    <td><Currency value={quotation.total} /></td>
                    <td>
                        <Button color="info" outline onClick={() => this.ViewQuotation(quotation)} title="Ver Productos"><i className="fa fa-search fa-lg" /></Button>
                        <Button disabled={loading} color="success ml-3" outline onClick={() => this.GeneratePDF(quotation)} title="Descargar PDF"><i className="fa fa-download fa-lg" /></Button>
                        <Button disabled={loading} color="primary ml-3" outline onClick={() => this.setState({ open2: true, quote: quotation })} title="Cargar al Carrito"><i className="fa fa-cart-arrow-down fa-lg" /></Button>
                    </td>
                </tr>
            );
        });

        return (
            <div className="card">
                <Helmet>
                    <title>{`Historial de Ordenes — ${company[0].name}`}</title>
                </Helmet>

                <div className="card-header">
                    <h5>Hitorial de Cotizaciones</h5>
                </div>
                <div className="card-divider" />
                <div className="card-table">
                    <div className="table-responsive-sm">
                        <table>
                            <thead>
                                <tr>
                                    <th>Cotización</th>
                                    <th>Fecha</th>
                                    <th>Validez</th>
                                    <th>Estado</th>
                                    <th>Total</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading2 ? (
                                    <tr><td colSpan="5" ><div className="div-loading" style={{ height: 300 }} /></td></tr>
                                ) : quotationsList.slice(start, end)}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-divider" />
                <div className="card-footer">
                    {sinQuotations && <strong> Actualmente no tiene Cotizaciones... </strong>}
                    {pages > 1 && <Pagination current={page} total={pages} onPageChange={this.handlePageChange} siblings={2} />}
                </div>
                <Modal isOpen={open} toggle={() => this.setState({ open: false })} centered size="xl">
                    <div className="quickview">
                        <button className="quickview__close" type="button" onClick={() => this.setState({ open: false })}>
                            <Cross20Svg />
                        </button>
                        <div
                            className="document__content typography"
                            dangerouslySetInnerHTML={{ __html: quote }}
                        />
                    </div>
                </Modal>
                <Modal isOpen={open2} toggle={() => this.setState({ open2: false })} centered>
                    <ModalHeader toggle={() => this.setState({ open2: false })}>Cargar Productos al Carrito</ModalHeader>
                    <ModalBody>
                        Estimado cliente nuestra web es 100% en línea;  La disponibilidad de sus productos puede haber cambiado, le recomendamos revisar su compra.
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" className={`${loading && 'btn-loading'}`} disabled={loading} onClick={() => this.LoadToCart(quote)}>Continuar</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

const mapDispatchToProps = {
    cartLoadItemsSuccess,
};

export default connect(
    () => ({}),
    mapDispatchToProps,
)(AccountPageQuotationList);
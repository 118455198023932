
// react
import React, { Component } from 'react';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import PageHeader from '../shared/PageHeader';
import Pagination from '../shared/Pagination';
import SolutionsCard from '../shared/SolutionsCard';


export default class PageSolutionstList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            records: 6,
            pages: 1,
            start: null,
            end: null,
            solutionsList: [],
        };
    }

    componentDidMount() {
        const { solutions,content, layout } = this.props;
        const { records, page } = this.state;
        const solutionsList = solutions.map((solutions) => {
            const postLayout = {
                classic: 'grid-lg',
                grid: 'grid-nl',
                list: 'list-nl',
            }[layout];

            return (
                <div key={solutions.id} className="posts-list__item">
                    <SolutionsCard solutions={solutions} layout={postLayout} />
                </div>
            );
        });
        const pages = Math.ceil(solutions.length / records);
        const end = page * records;
        const start = end - records;

        this.setState(() => ({ pages, end, start, solutionsList }));
    }

    handlePageChange = (page) => {
        const { records } = this.state;
        const end = page * records;
        const start = end - records;

        this.setState(() => ({ end, start, page }));
    };

    render() {
        const { layout, content, company } = this.props;
        const { page, solutionsList, start, end, pages } = this.state;
        const breadcrumb = [
            { title: 'Home', url: '/' },
            { title: 'Soluciones', url: '' },
        ];
        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Soluciones — ${company && company[0].name}`}</title>
                </Helmet>

                <PageHeader header="Soluciones" breadcrumb={breadcrumb} />

                <div className="container">
                    <div className="row">
                            <div className="block">
                                <div className="posts-view">
                                    <div className={`posts-view__list posts-list posts-list--layout--${layout}`}>
                                        <div className="posts-list__body_services">
                                            {solutionsList.slice(start, end)}
                                        </div>
                                        <div className="about-us__body">
                                            <div
                                                className="about-us__text typography"
                                                dangerouslySetInnerHTML={{ __html: content.side_bar }}
                                            />
                                        </div>
                                    </div>
                                    <div className="posts-view__pagination">
                                        {pages > 1 && (
                                            <Pagination
                                                current={page}
                                                siblings={2}
                                                total={pages}
                                                onPageChange={this.handlePageChange}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

PageSolutionstList.propTypes = {
    /**
     * blog layout
     * one of ['classic', 'grid', 'list'] (default: 'classic')
     */
    layout: PropTypes.oneOf(['classic', 'grid', 'list']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
};

PageSolutionstList.defaultProps = {
    layout: 'grid',
};

/* eslint-disable  */
import { getProduct } from '../directusSdk';
import { QUICKVIEW_CLOSE, QUICKVIEW_OPEN } from './quickviewActionTypes';


export function quickviewOpenSuccess(product) {
    return {
        type: QUICKVIEW_OPEN,
        product,
    };
}

export function quickviewClose() {
    return {
        type: QUICKVIEW_CLOSE,
    };
}

export function quickviewOpen(productId) {
    return (dispatch) => (
        new Promise((resolve) => {
            const consult = getProduct(productId);
            consult.then((res) => {
                if (res) {
                    const product = Object.assign(res);
                    const bodegas = product.OnHandByWhs.split(',');
                    const bod01 = bodegas[0].split(':');
                    const bod05 = bodegas[1].split(':');

                    const images = [];
                    if (product.images !== '') {
                        const imag = product.images.split(',');
                        imag.forEach((img) => {
                            if (img !== '') {
                                images.push(`images/products/${img}`);
                            }
                        });
                    } else {
                        images.push('images/not_found.png');
                    }

                    delete product.subcategoryid;
                    delete product.OnHandByWhs;
                    product.days = product.days ? parseInt(product.days) : 30;
                    product.bod01 = parseInt(bod01[1]);
                    product.bod05 = parseInt(bod05[1]);
                    product.compareAtPrice = null;
                    product.images = images;

                    dispatch(quickviewOpenSuccess(product));
                }
                resolve();
            });
        })
    );
}

/* eslint-disable */
import React, { useEffect, useState } from 'react';

// third-party
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';

// application
import CategorySidebar from './CategorySidebar';
import PageHeader from '../shared/PageHeader';
import ProductsView from './ProductsView';
import { sidebarClose } from '../../store/sidebar';
// data stubs
import { getProductsBySubCategory } from '../../store/directusSdk';
import { resetFilters } from '../../store/pagesContent';
import { data } from 'jquery';
import Slider from 'react-slick';
import BlockProducts from '../blocks/BlockProducts';
import { getSaleProducts } from '../../store/directusSdk';

const settings = {
    dots: false,
    infinite: true,
    speed: 1300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    pauseOnHover: true,
};

function ShopPageCategory(props) {
    const {
        columns,
        viewMode,
        sidebarPosition,
        match: { params: category },
        resetFilters,
        numFilterOn,
    } = props;
    const [state, setState] = useState({ filters: [], products: [], loading: true });
    const [featuredProducts, setFeaturedProducts] = useState([]);
    const keyWords = 'SALE';

    useEffect(() => {
        
        if (numFilterOn.length > 0) {
            resetFilters();
            
        }
        setState({filters: [], products: [], loading: true});
        const filtros= Object({
            brand: [],
            model: [],
            amp: [],
            hp: [],
            kw: [],
            voltage: [],
            rpm: [],
            frame: [],
            coil: [],
            polos: [],
            fp: [],
            efficiency: [],
            fs: [],
            type: [],
            connection: [],
            phases: [],
            temperature: [],
            output: [],
            input: [],
            pressure: [],
            flow: [],
            color: [],
            seal: [],
            diameter: [],
            thread: [],
            application: [],
            protection: [],
            mounting: [],
            communication: [],
            pulses: [],
            curve: [],
            capacity: [],
            unit: [],
            rank: [],
            long: [],
            size: [],
        });

        const productos =  getProductsBySubCategory(category.subcat_id)
        productos.then((res) => {
            if (res.data.length > 0) {
                const products = res.data.map((e) => {
                    let bod01 = 0;
                    let bod05 = 0;
                    if (e.OnHandByWhs) {
                        const bodegas = e.OnHandByWhs.split(',');
                        bod01 = bodegas[0].split(':');
                        bod05 = bodegas[1].split(':');
                    }
                    const features = [];
                    if (e.especificaciones) {
                        const specifications = e.especificaciones.split('<br>').splice(0,5);
                        specifications.forEach((e) => {
                            if (e !== '') {
                                const specif = e.split(':');
                                features.push({ name: specif[0], value: specif[1] });
                            }
                        });
                    }
                    const images = [];
                    if (e.images !== '') {
                        const imag = e.images.split(',');
                        imag.forEach((img) => {
                            if (img !== '') {
                                images.push(`images/products/${img}`);
                            }
                        });
                    } else {
                        images.push('images/not_found.png');
                    }

                    if (!filtros.brand.includes(e.brand) && e.brand) {
                        filtros.brand.push(e.brand);
                    }
                    if (!filtros.model.includes(e.model) && e.model) {
                        filtros.model.push(e.model);
                    }
                    if (!filtros.amp.includes(e.amp) && e.amp) {
                        filtros.amp.push(e.amp);
                    }
                    if (!filtros.hp.includes(e.hp) && e.hp) {
                        filtros.hp.push(e.hp);
                    }
                    if (!filtros.kw.includes(e.kw) && e.kw) {
                        filtros.kw.push(e.kw);
                    }
                    if (!filtros.voltage.includes(e.voltage) && e.voltage) {
                        filtros.voltage.push(e.voltage);
                    }
                    if (!filtros.rpm.includes(e.rpm) && e.rpm) {
                        filtros.rpm.push(e.rpm);
                    }
                    if (!filtros.frame.includes(e.frame) && e.frame) {
                        filtros.frame.push(e.frame);
                    }
                    if (!filtros.polos.includes(e.polos) && e.polos) {
                        filtros.polos.push(e.polos);
                    }
                    if (!filtros.coil.includes(e.coil) && e.coil) {
                        filtros.coil.push(e.coil);
                    }
                    if (!filtros.fp.includes(e.fp) && e.fp) {
                        filtros.fp.push(e.fp);
                    }
                    if (!filtros.efficiency.includes(e.efficiency) && e.efficiency) {
                        filtros.efficiency.push(e.efficiency);
                    }
                    if (!filtros.fs.includes(e.fs) && e.fs) {
                        filtros.fs.push(e.fs);
                    }
                    if (!filtros.type.includes(e.type) && e.type) {
                        filtros.type.push(e.type);
                    }
                    if (!filtros.connection.includes(e.connection) && e.connection) {
                        filtros.connection.push(e.connection);
                    }
                    if (!filtros.phases.includes(e.phases) && e.phases) {
                        filtros.phases.push(e.phases);
                    }
                    if (!filtros.temperature.includes(e.temperature) && e.temperature) {
                        filtros.temperature.push(e.temperature);
                    }
                    if (!filtros.output.includes(e.output) && e.output) {
                        filtros.output.push(e.output);
                    }
                    if (!filtros.input.includes(e.input) && e.input) {
                        filtros.input.push(e.input);
                    }
                    if (!filtros.pressure.includes(e.pressure) && e.pressure) {
                        filtros.pressure.push(e.pressure);
                    }
                    if (!filtros.flow.includes(e.flow) && e.flow) {
                        filtros.flow.push(e.flow);
                    }
                    if (!filtros.color.includes(e.color) && e.color) {
                        filtros.color.push(e.color);
                    }
                    if (!filtros.seal.includes(e.seal) && e.seal) {
                        filtros.seal.push(e.seal);
                    }
                    if (!filtros.diameter.includes(e.diameter) && e.diameter) {
                        filtros.diameter.push(e.diameter);
                    }
                    if (!filtros.thread.includes(e.thread) && e.thread) {
                        filtros.thread.push(e.thread);
                    }
                    if (!filtros.application.includes(e.application) && e.application) {
                        filtros.application.push(e.application);
                    }
                    if (!filtros.protection.includes(e.protection) && e.protection) {
                        filtros.protection.push(e.protection);
                    }
                    if (!filtros.mounting.includes(e.mounting) && e.mounting) {
                        filtros.mounting.push(e.mounting);
                    }
                    if (!filtros.pulses.includes(e.pulses) && e.pulses) {
                        filtros.pulses.push(e.pulses);
                    }
                    if (!filtros.communication.includes(e.communication) && e.communication) {
                        filtros.communication.push(e.communication);
                    }
                    if (!filtros.pulses.includes(e.pulses) && e.pulses) {
                        filtros.pulses.push(e.pulses);
                    }
                    if (!filtros.curve.includes(e.curve) && e.curve) {
                        filtros.curve.push(e.curve);
                    }
                    if (!filtros.capacity.includes(e.capacity) && e.capacity) {
                        filtros.capacity.push(e.capacity);
                    }
                    if (!filtros.unit.includes(e.unit) && e.unit) {
                        filtros.unit.push(e.unit);
                    }
                    if (!filtros.rank.includes(e.rank) && e.rank) {
                        filtros.rank.push(e.rank);
                    }
                    if (!filtros.long.includes(e.long) && e.long) {
                        filtros.long.push(e.long);
                    }
                    if (!filtros.size.includes(e.size) && e.size) {
                        filtros.size.push(e.size);
                    }
                    const product = Object.assign(e)
                    product.days = parseInt(e.days);
                    product.bod01 = parseInt(bod01[1]);
                    product.bod05 = parseInt(bod05[1]);
                    product.compareAtPrice = null;
                    product.images = images;
                    product.features = features;
                    return product;
                });
                const filters = [];
                Object.entries(filtros).forEach((e,i) => {
                    if (e[1].length > 0) {
                        filters.push({
                            id: i,
                            name: e[0],
                            type: 'select',
                            options: e[1],
                        });
                    }
                });
                setState({ filters, products, loading: false });
            } else {
                setState({ filters: [], products: [], loading: false });
            }
        });
        const abortController = new AbortController();
        const fetchData = async () => {
            try {
                const res = await getSaleProducts(keyWords, { signal: abortController.signal });
                setFeaturedProducts(res.data);
            } catch (error) {
                console.error('Error fetching carousel data:', error);
            }
        };

        fetchData();
        return () => {
            abortController.abort();
        };
    }, [category.subcat_id]);
    const breadcrumb = [
        { title: category.category.replace(/_/g, ' '), url: `/shop/categoria/${category.category}/${category.cat_id}` },
        { title: category.subcategory.replace(/_/g, ' '), url: `/shop/categoria/${category.category}/subcategoria/${category.subcategory}/${category.cat_id}/${category.subcat_id}` },
    ];
    let content;

    const offcanvas = columns === 3 ? 'mobile' : 'always';
    const chunkedProducts = [];
    for (let i = 0; i < featuredProducts.length; i += 9) {
        chunkedProducts.push(featuredProducts.slice(i, i + 4));
    }

    
    if (columns > 3) {
        content = (
            <div className="container">
                <div className="block">
            <CategorySidebar filters={state.filters} offcanvas={offcanvas} />
                    <ProductsView
                        products={state.products}
                        layout={viewMode}
                        grid={`grid-${columns}-full`}
                        limit={15}
                        offcanvas={offcanvas}
                        filters={state.filters}
                    />
                </div>
            </div>
        );
    } else {
        const sidebar = (
            <div className="shop-layout__sidebar"><CategorySidebar offcanvas={offcanvas} filters={state.filters} /></div>
        );

        content = (
            <div className="container">
                <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                    {sidebarPosition === 'start' && sidebar}
                    <div className="shop-layout__content">
                        <div className="block">
                            {sidebarPosition === 'end' && sidebar}
                            <ProductsView
                                products={state.products}
                                layout={viewMode}
                                grid="grid-3-sidebar"
                                limit={15}
                                offcanvas={offcanvas}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    const contentido = state.products.length > 0 ? content : <h4 align="center">Actualmente no hay productos disponibles en esta Subcategoria.</h4>;
     
    return (
        <React.Fragment>
            <Helmet>
                <title>Productos - ICAutomatizados</title>
            </Helmet>

            <PageHeader header={category.subcategory.replace(/_/g, ' ')} breadcrumb={breadcrumb} />
            <Slider {...settings}>
                {chunkedProducts.map((productsChunk, index) => (
                    <div key={index}>
                        <BlockProducts
                            layout="large-first"
                            products={productsChunk.slice(0, 4)}
                            subcategory={true}
                        />
                    </div>
                ))}
            </Slider>
            {state.loading ? (
                <div className="container">
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className="div-loading" />
                    <br />
                    <br />
                    <br />
                    <br />

                </div>
            ) : contentido }

        </React.Fragment>
    );
}

ShopPageCategory.propTypes = {
    columns: PropTypes.number,
    viewMode: PropTypes.oneOf(['grid', 'grid-with-features', 'list']),
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

ShopPageCategory.defaultProps = {
    columns: 3,
    viewMode: 'list',
    sidebarPosition: 'start',
};

const mapStateToProps = (state) => {
    return{
    sidebarState: state.sidebar,
    numFilterOn: state.pagesContent.filters,}
};

const mapDispatchToProps = {
    sidebarClose,
    resetFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCategory);
// react
import React from 'react';

// third-party
import { Link } from 'react-router-dom';

export default function BlockCategories(props) {
    const { layout, subcategories } = props;
    const subcategoriesList = subcategories.map((subcategory, index) => {
        const classes = `block-categories__item category-card category-card--layout--${layout}`;
        return (
            <div key={index} className={classes}>
                <div className=" category-card__body">
                    <div className=" category-card__image">
                        <Link to={subcategory.url}>
                            <img src={subcategory ? `${process.env.REACT_APP_API_URL}assets/${subcategory.subcategoryimage}`: 'images/not_found.png'} alt="" />
                        </Link>
                    </div>
                    <div className="">
                        <div className="">
                            <Link to={subcategory.url}>{subcategory.descripcion}</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <div className={`block block--highlighted block-categories block-categories--layout--${layout}`}>
            <div className="container">
                <div className="block-categories__list">
                    
                    {subcategoriesList}
                </div>
            </div>
        </div>
    );
}

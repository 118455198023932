// react
import React, { useEffect } from 'react';

// third-party
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// application
import FilterSelect from '../filters/FilterSelect';
import { resetFilters, dropFilters, dropMultipleFilter, filtersChange } from '../../store/pagesContent';

function WidgetFilters(props) {
    const { title, filters, offcanvas, resetFilters, filterOn, numFilterOn, dropFilters, dropMultipleFilter } = props;
    const filtersList = filters.map((filter) => {
        let filterView;

        if (filter.type === 'select') {
            filterView = <FilterSelect filter={filter} />;
        }

        return (
            <div key={filter.id} className="">
                {filterView}
            </div>
        );
    });

    const classes = classNames('widget-filters widget', {
        'widget-filters--offcanvas--always': offcanvas === 'always',
        'widget-filters--offcanvas--mobile': offcanvas === 'mobile',
    });

    const handleDeleteFilter = (filterName, filterValueToDelete) => {
        // Obtén el estado actual de los filtros
        const currentFilter = filterOn.find((filter) => filter.name === filterName);
        if (currentFilter) {
            if (Array.isArray(currentFilter.value) && currentFilter.value.length > 1) {
                const updatedValues = currentFilter.value.filter((value) => value !== filterValueToDelete);
                const updatedFilter = { ...currentFilter, value: updatedValues };
                // Llama a la acción para actualizar el estado

                dropMultipleFilter(updatedFilter, filterValueToDelete);
            } else {
                // Si el valor no es un array o solo hay un valor, elimina el filtro completo
                dropFilters({ name: filterName, value: '' });
            }
        }
    };

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return (
        <div className={classes}>
            <h4 className="widget-filters__title widget__title">{title}</h4>
            <div className="widget-filters__actions d-flex" align="center">
                <h5>Filtros Seleccionados</h5>
                {filterOn.length >= 1 &&
                    filterOn.map((filter) => (
                        <div key={filter.name} className="filter-item">
                            {Array.isArray(filter.value) ? (
                                // Si el valor del filtro es un array, muestra cada valor por separado
                                filter.value.map((value) => (
                                    <span key={value}>
                                        <span onClick={() => handleDeleteFilter(filter.name, value)}>
                                            <i className="fa fa-times-circle" aria-hidden="true"></i>
                                        </span>
                                        <span><b>{capitalizeFirstLetter(filter.name)}:</b> {value}</span>
                                    </span>
                                ))
                            ) : (
                                // Si el valor del filtro no es un array, muestra el valor directamente
                                <React.Fragment>
                                    <span onClick={() => handleDeleteFilter(filter.name, filter.value)}>
                                        <i className="fa fa-times-circle" aria-hidden="true"></i>
                                    </span>
                                    <span><b>{filter.name}:</b> {filter.value}</span>
                                </React.Fragment>
                            )}
                        </div>
                    ))}


                <button type="button" className="btn btn-secondary btn-sm ml-2" onClick={() => resetFilters()} disabled={numFilterOn.length < 1}>
                    Limpiar Filtros
                </button>
            </div>
            <div className="widget-filters__list">
                {filtersList}
            </div>
        </div>
    );
}

WidgetFilters.propTypes = {
    title: PropTypes.node,
    filters: PropTypes.array,
    offcanvas: PropTypes.oneOf(['always', 'mobile']),
    numFilterOn: PropTypes.array,
    filterOn: PropTypes.array,
    resetFilters: PropTypes.func,
    dropFilters: PropTypes.func,
    dropMultipleFilter: PropTypes.func,
    dropFilters: PropTypes.func,


};

const mapStateToProps = (state) => ({
    numFilterOn: state.pagesContent.filters,
    filterOn: state.pagesContent.filters,
});

const mapDispatchToProps = {
    resetFilters,
    dropFilters,
    dropMultipleFilter
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetFilters);
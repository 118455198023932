// react
import React from 'react';

// third-party
import { connect } from 'react-redux';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addCustomerAddress, editCustomerAddress, getCustomerAddress } from '../../store/directusSdk';
import { AddressesChange } from '../../store/currentUser';


function AccountFormAddress(props) {
    const { type, address, currentUser, countries, cart, checkout, deliverycities, AddressesChange } = props;
    const history = useHistory();
    const valIniciales = type === 'Editar' ? address : { responsable: '', countryid: '', deliverycityid: '', address: '', phone1: '', phone2: '', email: '', defaultaddress: false, customer: currentUser.id };

    return (
        <div className="card">
            <div className="card-header">
                <h5>{`${type} Dirección`}</h5>
            </div>
            <div className="card-divider" />
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-12 col-xl-12">
                        <Formik
                            initialValues={valIniciales}
                            validationSchema={Yup.object({
                                responsable: Yup.string()
                                    .required('Campo requerido.')
                                    .min(3, 'minimo 3 caracteres.'),
                                countryid: Yup.string()
                                    .required('Campo Requerido.'),
                                address: Yup.string()
                                    .required('Campo Requerido.')
                                    .min(10, 'minimo 10 caracteres.'),
                                phone1: Yup.string()
                                    .required('Campo Requerido.'),
                                email: Yup.string()
                                    .email('Email invalido.'),
                            })}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                if (values.countryid != 162) {
                                    values.deliverycityid = '';
                                }
                                if (type === 'Editar') {
                                    let addresses;
                                    if (values.defaultaddress) {
                                        addresses = currentUser.addresses.map((e) => {
                                            if (e.id === values.id) {
                                                return values;
                                            }
                                            return {id: e.id, defaultaddress: false}
                                        });
                                    } else {
                                        addresses = [values];
                                    }
                                    const editAddress = editCustomerAddress(addresses);
                                    editAddress.then(() => {
                                        toast.success('Se ha actualizado su dirección de envio.');
                                        getCustomerAddress(currentUser.id).then((res) => {
                                            AddressesChange(res.data);
                                        });
                                        history.push('/account/addresses');
                                    }).catch((error) => {
                                        console.log(error);
                                        setSubmitting(false);
                                    });
                                } else {
                                    const addAddress = addCustomerAddress(values, currentUser.id);
                                    addAddress.then(() => {
                                        toast.success('Se ha agregado una dirección de envio.');
                                        getCustomerAddress(currentUser.id).then((res) => {
                                            AddressesChange(res.data);
                                        });

                                        if (cart || checkout) {
                                            resetForm({});
                                        } else {
                                            history.push('/account/addresses');
                                        }
                                        setSubmitting(false);
                                    }).catch((error) => {
                                        console.log(error);
                                        setSubmitting(false);
                                    });
                                }
                            }}
                        >
                            {({ errors, touched, isSubmitting, values }) => (
                                <Form>
                                    <div className="form-row">
                                        <div className="form-group-direction col-md-12">
                                            <label htmlFor="responsable">Responsable</label>
                                            <Field
                                                name="responsable"
                                                type="text"
                                                id="responsable"
                                                className={`form-control ${errors.responsable && touched.responsable && 'is-invalid'}`}
                                                placeholder="Responsable de recepción..."
                                            />
                                            <ErrorMessage name="responsable" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group-direction col-md-6">
                                            <label htmlFor="countryid">Pais</label>
                                            <Field
                                                as="select"
                                                name="countryid"
                                                id="countryid"
                                                className={`form-control form-control-select2 ${errors.countryid && touched.countryid && 'is-invalid'}`}
                                            >
                                                <option value="">Seleccione...</option>
                                                {countries.map((e) => <option key={e.id} value={e.id}>{e.countryname}</option>)}
                                            </Field>
                                            <ErrorMessage name="countryid" component="div" className="invalid-feedback" />
                                        </div>
                                        <div className="form-group-direction col-md-6">
                                        {values && values.countryid == 162 && (
                                                <React.Fragment>
                                                    <label htmlFor="deliverycityid">Ciudad</label>
                                                    <Field
                                                        as="select"
                                                        name="deliverycityid"
                                                        id="deliverycityid"
                                                        className={`form-control form-control-select2 ${errors.deliverycityid && touched.deliverycityid && 'is-invalid'}`}
                                                        validate={(value) => {
                                                            let error;
                                                            if (value === '') {
                                                                error = 'Campo requerido.';
                                                            }
                                                            return error;
                                                        }}
                                                    >
                                                        <option value="">Seleccione...</option>
                                                        {deliverycities.map((e) => <option key={e.value} value={e.id}>{e.cityname}</option>)}
                                                    </Field>
                                                    <ErrorMessage name="deliverycityid" component="div" className="invalid-feedback" />
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group-direction">
                                        <label htmlFor="address">Dirección Completa</label>
                                        <Field
                                            as="textarea"
                                            className={`form-control ${errors.address && touched.address && 'is-invalid'}`}
                                            name="address"
                                            id="address"
                                            placeholder="Dirección completa..."
                                        />
                                        <ErrorMessage name="address" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="row">
                                        <div className="form-group-direction col-md-6">
                                            <label htmlFor="phone1">Telefono Principal</label>
                                            <Field
                                                type="text"
                                                name="phone1"
                                                className={`form-control ${errors.phone1 && touched.phone1 && 'is-invalid'}`}
                                                id="phone1"
                                                placeholder="Telefono principal..."
                                            />
                                            <ErrorMessage name="phone1" component="div" className="invalid-feedback" />
                                        </div>
                                        <div className="form-group-direction col-md-6">
                                            <label htmlFor="phone2">Telefono Opcional</label>
                                            <Field
                                                type="text"
                                                name="phone2"
                                                className={`form-control ${errors.phone2 && touched.phone2 && 'is-invalid'}`}
                                                id="phone2"
                                                placeholder="Telefono opcional..."
                                            />
                                            <ErrorMessage name="phone2" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group-direction col-md-12">
                                            <label htmlFor="email">Dirección de Correo</label>
                                            <Field
                                                type="text"
                                                name="email"
                                                className={`form-control ${errors.email && touched.email && 'is-invalid'}`}
                                                id="email"
                                                placeholder="Dirección de Correo..."
                                            />
                                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group-direction-direction col-md-12">
                                            <label htmlFor="defaultaddress" >Predeterminado</label>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Field
                                                type="checkbox"
                                                name="defaultaddress"
                                                id="defaultaddress"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group-direction mt-3 mb-0">
                                        <button className="btn btn-primary" type="submit" disabled={isSubmitting}>{isSubmitting ? 'Guardando...' : 'Guardar'}</button>
                                        {!cart && <button className="btn btn-secondary ml-3" type="button" onClick={() => history.push('/account/addresses')}>Cancelar</button>}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => ({
    countries: state.pagesContent.countries,
    deliverycities: state.pagesContent.deliverycities,
    currentUser: state.currentUser,
});

const mapDispatchToProps = {
    AddressesChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountFormAddress);
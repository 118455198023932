// react
import React from 'react';

// third-party
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


function BlockFeatures(props) {
    const { layout, miniBanners } = props;
    const listMiniBanners = miniBanners.map((miniBanner,i) => (
        <React.Fragment key={i}>
            <div className="block-features__item">
                <img
                    src={miniBanner.bannerimage}
                    alt=""
                />
            </div>
            <div className="block-features__divider" />
        </React.Fragment>
    ));

    return (
        <div className={`block block-features block-features--layout--${layout}`}>
            <div className="container">
                <div className="block-features__list">
                    {listMiniBanners}
                </div>
            </div>
        </div>
    );
}

BlockFeatures.propTypes = {
    layout: PropTypes.oneOf(['classic', 'boxed']),
};

BlockFeatures.defaultProps = {
    layout: 'classic',
};
const mapStateToProps = (state) => ({
    miniBanners: state.pagesContent.miniBanners,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BlockFeatures);
// react
import React, { Component } from 'react';
import classNames from 'classnames';
import Register from '../account/Register';
import Login from '../account/Login';


class LoginRegister extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTab: 'login',
        };
    }

    setTab = (newTab) => {
        this.setState(() => ({ currentTab: newTab }));
    };

    render() {
        const { currentTab } = this.state;

        const tabs = [
            { key: 'register', title: 'Crear Cuenta', content: <Register /> },
            { key: 'login', title: 'Login', content: <Login /> },
        ];

        const tabsButtons = tabs.map((tab) => {
            const classes = classNames('product-tabs__item', {
                'product-tabs__item--active': currentTab === tab.key,
            });

            return <button key={tab.key} type="button" onClick={() => this.setTab(tab.key)} className={classes}>{tab.title}</button>;
        });

        const tabsContent = tabs.map((tab) => {
            const classes = classNames('product-tabs__pane', {
                'product-tabs__pane--active': currentTab === tab.key,
            });

            return <div key={tab.key} className={classes}>{tab.content}</div>;
        });

        return (
            <React.Fragment>
                <div className="product-tabs__list">
                    {tabsButtons}
                </div>
                {tabsContent}
            </React.Fragment >
        );
    }
}

export default LoginRegister;

// react
import React from 'react';

function ProductTabVideo(props) {
    const { video } = props;
    return (
        <p align="center">
            <iframe
                title="youtube"
                width="600" 
                height="400" 
                src={video} 
                frameBorder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen 
            />
        </p>
    );
}

export default ProductTabVideo;

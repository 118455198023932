import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Switch from "react-switch";
import Cookies from "js-cookie";
// import "./Modal.scss";
import Logo from "../../svg/LOGO COMPLETO COLOR.png"
import CookiePreference from "./cookiePreference";
const CookiesModal = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [isCustomizing, setIsCustomizing] = useState(false);
  const [necessaryCookies, setNecessaryCookies] = useState(true);
  const [personalizationCookies, setPersonalizationCookies] = useState(false);
  const [statisticsCookies, setStatisticsCookies] = useState(false);
  const [showMore, setShowMore] = useState(false)
  //hacer un  objeto de show more o hacer uno componente independiente
  useEffect(() => {
    const hasCookies =
      Cookies.get("necessary_cookies") ||
      Cookies.get("personalization_cookies") ||
      Cookies.get("statistics_cookies");
    setIsOpen(!hasCookies);
  }, []);

  const handleAcceptAll = () => {
    Cookies.set("necessary_cookies", true);
    Cookies.set("personalization_cookies", true);
    Cookies.set("statistics_cookies", true);
    setIsOpen(false);
  };

  const handleRejectAll = () => {
    Cookies.set("necessary_cookies", true);
    Cookies.set("personalization_cookies", false);
    Cookies.set("statistics_cookies", false);
    setIsOpen(false);
  };

  const handleOpenCustomize = () => {
    setIsCustomizing(true);
  };

  const handleCloseCustomize = () => {
    setIsCustomizing(false);
  };

  const handleConfirmPreferences = () => {
    Cookies.set("necessary_cookies", true);
    Cookies.set("personalization_cookies", personalizationCookies);
    Cookies.set("statistics_cookies", statisticsCookies);
    setIsOpen(false);
    setIsCustomizing(false);
  };

  const handleRejectCustomize = () => {
    Cookies.set("necessary_cookies", true);
    Cookies.set("personalization_cookies", false);
    Cookies.set("statistics_cookies", false);
    setIsOpen(false);
    setIsCustomizing(false);
  };

  const handleShowMore = () => {
    setShowMore(prev => !prev)
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        className="cookie-modal"
        overlayClassName="cookie-modal-overlay"
        ariaHideApp={false}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={false}
        shouldReturnFocusAfterClose={false}

      >
        <div className="cookie-modal-content">
          <h4>Cookies y privacidad</h4>
          <p>Nuestro sitio web utiliza cookies enviadas tanto por nosotros como por terceros. Algunas de estas cookies son necesarias para el funcionamiento del sitio web, mientras que otras las puedes ajustar tú mismo en cualquier momento. En particular, las que nos permiten comprender el rendimiento de nuestro sitio web, te proporcionan funciones de redes sociales y te ofrecen una mejor experiencia al presentarte contenidos y publicidad más ajustados a tus gustos.</p>
          <div className="cookie-modal-buttons">
            <div className="buttonSelfPlaced">
              <button onClick={handleOpenCustomize}> Cambiar tu configuración de cookies</button>
            </div>
            <button onClick={handleAcceptAll}>Aceptar todo</button>
            <button onClick={handleRejectAll}>Rechazar todo</button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isCustomizing}
        onRequestClose={handleCloseCustomize}
        className="cookie-modal"
        overlayClassName="cookie-modal-overlay"
        ariaHideApp={false}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={false}
        shouldReturnFocusAfterClose={false}
      >
        <div style={{margin:"0 auto"}}className="cookie-modal-content">
          <div className="cookie-modal-close-container">
            <img className="logo_modal_ica" src={Logo} alt="ica_logo" srcSet="" />
            <button className="cookie-modal-close" onClick={handleCloseCustomize}>x</button>
          </div>
          <div className="cookie-modal-settings">
            <h4 className='cookieTitle'>Centro de preferencia de la privacidad</h4>
            <p className='cookieText'>Cuando se visita un sitio web, es común que se utilicen cookies para obtener o almacenar información en el navegador. Estas cookies pueden contener datos sobre usted, sus preferencias o su dispositivo, y se utilizan principalmente para que el sitio funcione correctamente. Por lo general, esta información no lo identifica directamente, pero ayuda a brindarle una experiencia web más personalizada. Respetamos su derecho a la privacidad, por lo que puede optar por no permitir ciertas cookies. Puede hacer clic en los encabezados de cada categoría para obtener más información y cambiar nuestras configuraciones predeterminadas. Sin embargo, tenga en cuenta que bloquear algunos tipos de cookies puede afectar su experiencia en el sitio y los servicios que podemos ofrecer.</p>
            <button onClick={handleAcceptAll} className='cookieButton'>Permitir todas</button>
            <h4 className='cookieTitle'>Gestionar las preferencias de consentimiento</h4>
            <CookiePreference name="Siempre activas" title={necessaryCookies} action={setNecessaryCookies} disable={true} text="Este tipo de cookies son un requisito para el funcionamiento correcto de nuestro sitio web y no se pueden desactivar. Se envían a su equipo o dispositivo cuando solicita una acción o un servicio en específico, por ejemplo, cuando inicia la sesión, llena un formulario o establece las preferencias de cookies. Si configura su navegador para bloquear o notificar acerca de estas cookies, algunas partes de nuestro sitio no funcionarán." />
            <CookiePreference name="Cookies de personalización" title={personalizationCookies} action={setPersonalizationCookies} disable={false} text="Las cookies de personalización le permiten configurar nuestra página web de acuerdo con sus preferencias (por ejemplo, seleccionar el idioma o la región) y habilitar funciones mejoradas. Son proporcionadas por nosotros o por otras compañías. Si no las acepta, no podremos guardar sus preferencias. También es posible que algunos servicios de nuestro sitio web no estén disponibles." />
            <CookiePreference name="Cookies de Estadística" title={statisticsCookies} action={setStatisticsCookies} disable={false} text="Este sitio web utiliza cookies de estadística para recopilar información sobre cómo los usuarios interactúan con el sitio. Esta información se utiliza para mejorar la experiencia del usuario y optimizar el sitio web. Al continuar utilizando este sitio web, usted acepta el uso de estas cookies. Si desea desactivar las cookies de estadística, puede hacerlo a través de la configuración de su navegador web o mediante las opciones de privacidad del sitio web." />
          </div>
          <div className="cookie-modal-buttons">
            <button onClick={handleConfirmPreferences}>
              Confirmar mis preferencias
            </button>
            <button onClick={handleRejectCustomize}>Rechazar todas</button>
          </div>
        </div>
      </Modal>

    </>
  );
};

export default CookiesModal;

// react
import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

// third-party
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';

// application
import { Cross20Svg, Search20Svg } from '../../svg';
import Suggestions from './Suggestions';

// data stubs
import { getSuggestedProducts } from '../../store/directusSdk';

function Search(props) {
    const {
        context,
        className,
        inputRef,
        onClose,
        location,
    } = props;
    const [suggestionsOpen, setSuggestionsOpen] = useState(false);
    const [hasSuggestions, setHasSuggestions] = useState(false);
    const [suggestedProducts, setSuggestedProducts] = useState([]);
    const [total, setTotal] = useState(0);
    const [query, setQuery] = useState('');
    const wrapper = useRef(null);
    const close = useCallback(() => {
        if (onClose) {
            onClose();
        }
        setSuggestionsOpen(false);
    }, [onClose]);

    useEffect(() => close(), [close, location]);

    useEffect(() => {
        const onGlobalClick = (event) => {
            if (wrapper.current && !wrapper.current.contains(event.target)) {
                close();
            }
        };

        document.addEventListener('mousedown', onGlobalClick);

        return () => document.removeEventListener('mousedown', onGlobalClick);
    }, [close]);

    useEffect(() => {
        if (query === '' || query.length < 3) {
            setHasSuggestions(false);

            return undefined;
        }
        const productos = getSuggestedProducts(query);
        productos.then((res) => {
              setTotal(res.data.length);
            if (res.data.length < 1) {
                setSuggestedProducts([]);
                setHasSuggestions(false);
                setSuggestionsOpen(false);
            } else {
                const firstsProducts = res.data.splice(0,5);
                const products = firstsProducts.map((e, i) => {   
                    const features = [];
                    if (e.especificaciones) {
                        const specifications = e.especificaciones.split('<br>').splice(0,5);
                        specifications.forEach((e) => {
                            if (e !== '') {
                                const specif = e.split(':');
                                features.push({ name: specif[0], value: specif[1] });
                            }
                        });
                    }
                    const images = [];
                    if (e.images !== '') {
                        const imag = e.images.split(',');
                        imag.forEach((img) => {
                            if (img) {
                                images.push(`images/products/${img}`);
                            }
                        });
                    } else {
                        images.push('images/not_found.png');
                    }

                    const pdfs = [];
                    if (e.pdfs !== '') {
                        const pdff = e.pdfs.split(',');
                        pdff.forEach((pdf) => {
                            if (pdf !== '') {
                                pdfs.push(`images/pdfs/${pdf}`);
                            }
                        });
                    }
                    const product = Object.assign({}, e)
                    product.days = parseInt(e.days);
                    product.bod01 = parseInt(e.OnHandByWhs && e.OnHandByWhs.Bodega01);
                    product.bod05 = parseInt(e.OnHandByWhs && e.OnHandByWhs.Bodega05);
                    product.compareAtPrice = null;
                    product.images = images;
                    product.pdfs = pdfs;
                    product.features = features;
                    if (i === 0) {
                        const breadcrumb = [
                            { title: e.subcategoryid.categoriaid.descripcion.replace(/' '/g, '_'), url: `/shop/categoria/${e.subcategoryid.categoriaid.descripcion}/${e.subcategoryid.categoriaid.id}` },
                            { title: e.subcategoryid.descripcion.replace(/' '/g, '_'), url: `/shop/categoria/${e.subcategoryid.categoriaid.descripcion}/subcategoria/${e.subcategoryid.descripcion}/${e.subcategoryid.categoriaid.id}/${e.subcategoryid.id}` },
                        ];
                        product.breadcrumb = breadcrumb;
                        return product;
                    }
                    return product;
                });

                setSuggestedProducts(products);
                setHasSuggestions(true);
                setSuggestionsOpen(true);
            }
        });
    }, [query]);

    const handleFocus = () => {
        setSuggestionsOpen(true);
    };

    const handleChange = (event) => {
        setQuery(event.target.value);
    };

    const handleBlur = () => {
        setTimeout(() => {
            if (!document.activeElement || document.activeElement === document.body) {
                return;
            }

            // Close suggestions if the focus received an external element.
            if (wrapper.current && !wrapper.current.contains(document.activeElement)) {
                close();
            }
        }, 10);
    };

    const handleKeyDown = (e) => {
        // Escape.
        if (e.which === 27) {
            close();
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (query.length >= 3) {
            props.history.push(`/shop/search/${query}`);
        }
    };


    const rootClasses = classNames(`search search--location--${context}`, className, {
        'search--suggestions-open': suggestionsOpen,
        'search--has-suggestions': hasSuggestions,
    });

    const closeButton = context !== 'mobile-header' ? '' : (
        <button className="search__button search__button--type--close" type="button" onClick={close}>
            <Cross20Svg />
        </button>
    );

    return (
        <div className={rootClasses} ref={wrapper} onBlur={handleBlur}>
            <div className="search__body">
                <form className="search__form" onSubmit={handleSubmit}>
                    <input
                        ref={inputRef}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onKeyDown={handleKeyDown}
                        value={query}
                        className="search__input"
                        name="search"
                        placeholder="Buscar más de 5.400 productos"
                        aria-label="Site search"
                        type="text"
                        autoComplete="off"
                    />
                    <button className="search__button search__button--type--submit" type="submit">
                        <Search20Svg />
                    </button>
                    {closeButton}
                    <div className="search__border" />
                </form>

                <Suggestions className="search__suggestions" context={context} products={suggestedProducts} total={total} query={query}/>
            </div>
        </div>
    );
}

export default withRouter(Search);

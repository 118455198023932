// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { useState, useEffect } from 'react';

function SitePageServices({ services, company, items }) {
    const [listPage, setListPage] = useState(true);
    const [nameService, setNameService] = useState('')
    const [change, setChange] = useState()
    const [filteredItems, setFilteredItems] = useState([])

    const handleList = (item) => {
        const service_name = item.name_service
        setListPage(false)
        setNameService(service_name)
    }


    const handleListItems = (item) => {
        setChange(true)
        const service_name = item.name_service
        setNameService(service_name)
    }

    useEffect(() => {
        let filteredService = items?.filter(item => {
            return item.name_service === nameService;
        });
        setFilteredItems(filteredService)
        console.log('se cambio')
        setChange(false)
    }, [listPage, change])

    console.log(filteredItems)
    return (
        <div className="block-s about-us">
            <Helmet>
                <title>{`Nuestros Servicios — ${company[0]?.name}`}</title>
            </Helmet>

            {/* <div className="about-us__image" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/${services?.banner})` }} /> */}
            <div
                className="about-us__image"
                style={{
                    backgroundImage: `${filteredItems.length >= 1
                        ? `url(${process.env.REACT_APP_API_URL}assets/${filteredItems[0].image_service})`
                        : `url(${process.env.REACT_APP_API_URL}assets/${services?.banner})`
                        }`
                }}
            />            <div className="container">
                                {listPage ?
                                <>
                                <div
                                className="servicesBody about-us__text"
                                dangerouslySetInnerHTML={{ __html: services.body }}
                            />
                    <div className="container_services">
                        {items.map(item => (
                            <div className='container_services_list container_services_list_first' onClick={() => handleList(item)}>
                                <img className='service_icon' style={{ height: "110px", width: "110px" }} src={`${process.env.REACT_APP_API_URL}assets/${item?.icon}`} alt="" />
                                <p>{item?.name_service}</p>
                            </div>
                        ))}
                    </div>
                    </>:
                    <div className='container_second_page'>
                        <div className="container_services_list second">
                            <h2 className='services_ref'>Nuestros Servicios</h2>
                            {items.map(item => (
                                <p className={`${item.name_service === nameService ? 'service_name service_name_active' : 'service_name'}`} onClick={() => handleListItems(item)}>
                                    {item.name_service}
                                </p>
                            ))}
                        </div>

                        <>
                            {filteredItems.map(item => (
                                <div key={item.id} className='container_services_info'>
                                    <h3 className='services_info_title'>{item.name_service}</h3>
                                    <p>{item.text}</p>

                                    <a className='button_service' href="https://api.whatsapp.com/send?phone=62996744&text=Hola%20desde%20la%20web%20quisiera%20más%20información" target="_blank">Pedir Presupuesto</a>
                                </div>
                            ))}
                        </>
                    </div>}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
    items: state.pagesContent.services,
});

export default connect(mapStateToProps)(SitePageServices);

// react
import React from 'react';

// third-party
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';

// application
import PageHeader from '../shared/PageHeader';
import BlockCategory from '../blocks/BlockCategory';

// data stubs


function ShopPageCategory(props) {
    const {
        categories,
        match: { params: category },
    } = props;
    const categoria = categories.find((x) => x.id == category.id);
    const breadcrumb = [
        { title: 'Home', url: '/' },
        { title: category.category.replace(/_/g, ' '), url: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>Categorias de Productos - ICAutomatizados S.A</title>
            </Helmet>

            <PageHeader header={category.category.replace(/_/g, ' ')} breadcrumb={breadcrumb} />

            <BlockCategory layout="compact" subcategories={categoria.subcategories} />
        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    categories: state.pagesContent.categories,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCategory);


// react
import React,{useEffect} from 'react';

// third-party
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch } from 'react-router-dom';
import Cookie from '../components/site/Cookies';
import { ToastContainer } from 'react-toastify';

// application
import Footer from './footer';
import Header from './header';
import MobileHeader from './mobile/MobileHeader';
import MobileMenu from './mobile/MobileMenu';
import Quickview from './shared/Quickview';

// pages
import AccountLayout from './account/AccountLayout';
import AccountPageLogin from './account/AccountPageLogin';
import PageCart from './shop/ShopPageCart';
import PageCheckout from './shop/ShopPageCheckout';
import PageCompare from './shop/ShopPageCompare';
import PageWishlist from './shop/ShopPageWishlist';
import ShopPageCategory from './shop/ShopPageCategory';
import ShopPage from './shop/ShopPage';
import ShopPageSubCategory from './shop/ShopPageSubCategory';
import ShopSearchProducts from './shop/ShopSearchProducts';
import ShopPageOrderSuccess from './shop/ShopPageOrderSuccess';
import ShopPageProduct from './shop/ShopPageProduct';
import ShopPagePayment from './shop/ShopPagePayment';
import ShopPagePaymentConfirm from './shop/ShopPagePaymentConfirm';
import ShopPageTrackOrder from './shop/ShopPageTrackOrder';
import SitePageAboutUs from './site/SitePageAboutUs';
import SitePageServices from './site/SitePageServices';
import SitePageContactUs from './site/SitePageContactUs';
import SitePageNotFound from './site/SitePageNotFound';
import SitePageTerms from './site/SitePageTerms';
import SitePagePrivacyPolicies from './site/SitePagePrivacyPolicies';
import PageProjectsList from './projects/PageProjectsList';
import PageSolutionstList from './solutions/PageSolutionstList';
import PageProject from './projects/PageProject';
import PageSolutions from './solutions/PageSolutions';
import PageSolutionsInfo from './solutions/PageSolutionsInfo';
import ForgetPassword from './account/ForgetPassword';
import ResetPassword from './account/ResetPassword';
import ResponsePassword from './account/ResponsePassword';
import PageContent from './site/PageContent';

import { login, registerUser, refresh} from '../store/directusSdk';
import { currentUserChange } from '../store/currentUser';
import HomePageOne from './home/HomePageOne';

function Layout(props) {
    const { match, headerLayout, pagesContent, currentUser, history, currentUserChange } = props;
    let token=localStorage.getItem('auth_token');
    if(!token && currentUser){
        localStorage.clear()
        currentUserChange(null);
        history.push('/')
        window.location.reload()
        }
    return (
        <React.Fragment>
            <Helmet>
                <title>ICAutomatizados S.A</title>
                <meta name="description" content="ICAutomatizados S.A" />
            </Helmet>
            
            <Cookie/>
            <ToastContainer autoClose={5000} hideProgressBar />

            <Quickview />

            <MobileMenu />

            <div className="site">
                <header className="site__header d-lg-none">
                    <MobileHeader pagesContent={pagesContent} currentUser={currentUser} />
                </header>

                <header className="site__header d-lg-block d-none">
                    <Header layout={headerLayout} currentUser={currentUser} history={history} pagesContent={pagesContent} />
                </header>

                <div className="site__body">
                    <Switch>
                        {/*
                        // Home
                        */}
                        <Route exact path={`${match.path}`} component={(props) => <HomePageOne {...props} categories={pagesContent.categories} brands={pagesContent.brands} />} />

                        {/*
                        // Shop
                        */}

                        <Route exact path="/shop/checkout/success" component={(props) => <ShopPageOrderSuccess {...props} currentUser={currentUser} />} />
                        <Route exact path="/shop/checkout/payment" component={() => (<ShopPagePayment currentUser={currentUser} />)} />

                        <Route
                            exact
                            path="/shop/checkout/payment/confirm"
                            component={(props) => (
                                <ShopPagePaymentConfirm {...props} currentUser={currentUser} />
                            )}
                        />

                        <Route
                            exact
                            path="/shop"
                            render={(props) => (
                                <ShopPage {...props} columns={3} viewMode="grid" sidebarPosition="start" />
                            )}
                        />
                        <Route exact path="/shop/product/:code/:id" component={ShopPageProduct} />
                        <Route
                            exact
                            path="/shop/search/:string"
                            render={(props) => (
                                <ShopSearchProducts {...props} columns={4} viewMode="list" />
                            )}
                        />
                        <Route
                            exact
                            path="/shop/categoria/:category/:id"
                            render={(props) => (
                                <ShopPageCategory {...props} />
                            )}
                        />
                        <Route
                            exact
                            path="/shop/categoria/:category/subcategoria/:subcategory/:cat_id/:subcat_id"
                            render={(props) => (
                                <ShopPageSubCategory {...props} columns={4} viewMode="list" sidebarPosition="start" company={pagesContent.company} />
                            )}
                        />

                        <Route exact path="/shop/cart" component={PageCart} />
                        <Route exact path="/shop/checkout" render={() => <PageCheckout currentUser={currentUser} history={history} />} />
                        <Route exact path="/shop/wishlist" component={PageWishlist} />
                        <Route exact path="/shop/compare" component={PageCompare} />
                        <Route exact path="/shop/track-order" component={ShopPageTrackOrder} />

                        {/*
                        // Account
                        */}
                        <Route
                            exact
                            path="/account/login"
                            render={(props) => (
                                <AccountPageLogin
                                    {...props}
                                    login={login}
                                    registerUser={registerUser}
                                    currentUser={currentUser}
                                    currentUserChange={currentUserChange}
                                    company={pagesContent.company}
                                />
                            )}
                        />
                        <Route
                            path="/account"
                            component={AccountLayout}
                        />

                        {/*
                        // Site
                        */}
                        <Redirect exact from="/site" to="/site/about-us" />
                        <Route exact path="/site/about-us" component={() => <SitePageAboutUs company={pagesContent.company} aboutUs={pagesContent.contentPages[2]} />} />
                        <Route exact path="/site/services" component={() => <SitePageServices company={pagesContent.company} services={pagesContent.contentPages[3]} />} />
                        <Route exact path="/site/contact-us" component={() => <SitePageContactUs company={pagesContent.company} />} />
                        <Route exact path="/site/not-found" component={SitePageNotFound} />
                        <Route exact path="/site/terms" component={() => <SitePageTerms company={pagesContent.company} terms={pagesContent.contentPages[1]} />} />
                        <Route exact path="/site/privacy-policies" component={() => <SitePagePrivacyPolicies company={pagesContent.company} privacyPolicies={pagesContent.contentPages[0]} />} />
                        {/* <Route exact path="/proyectos" component={() => <PageProjectsList company={pagesContent.company} projects={pagesContent.projects} />} /> */}
                        <Route exact path="/soluciones" component={() => <PageSolutionstList company={pagesContent.company} solutions={pagesContent.solutions} content={pagesContent.contentPages[9]} />} />
                        {/* <Route exact path="/proyectos/:title/:id" component={(props) => <PageProject {...props} projects={pagesContent.projects} />} /> */}
                        <Route exact path="/soluciones/:title/" component={(props) => <PageSolutions {...props} solutions={pagesContent.solutions} sidebar={pagesContent.contentPages[9]}/>} />
                        <Route exact path="/soluciones/:title/:title" component={(props) => <PageSolutionsInfo {...props} solutions={pagesContent.solutions} sidebar={pagesContent.contentPages[9]}/>} />
                        <Route exact path="/cuenta/recuperar-contrasena" component={(props) => <ForgetPassword {...props} />} />
                        <Route exact path="/cuenta/reestablecer-contrasena" component={(props) => <ResetPassword {...props} />} />
                        <Route exact path="/cuenta/reestablecer-contrasena/:result" component={(props) => <ResponsePassword {...props} />} />
                        <Route exact path="/site/ayuda" component={() => <PageContent company={pagesContent.company} content={pagesContent.contentPages[5]} />} />
                        <Route exact path="/site/garantia" component={() => <PageContent company={pagesContent.company} content={pagesContent.contentPages[7]} />} />
                        <Route exact path="/site/devoluciones" component={() => <PageContent company={pagesContent.company} content={pagesContent.contentPages[6]} />} />
                        <Route exact path="/site/condiciones-de-venta" component={() => <PageContent company={pagesContent.company} content={pagesContent.contentPages[4]} />} />
                        <Route exact path="/site/preguntas-frecuentes" component={() => <PageContent company={pagesContent.company} content={pagesContent.contentPages[8]} />} />

                        {/*
                        // Page Not Found
                        */}
                        <Route component={SitePageNotFound} />
                    </Switch>
                </div>

                <footer className="site__footer">
                    <Footer company={pagesContent.company} />
                </footer>
            </div>
        </React.Fragment>
    );
}

Layout.propTypes = {
    /**
     * header layout (default: 'classic')
     * one of ['classic', 'compact']
     */
    headerLayout: PropTypes.oneOf(['default', 'compact']),
};
const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
    pagesContent: state.pagesContent,
});

const mapDispatchToProps = {
    currentUserChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
// react
import React, { Component } from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import moment from "moment";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { CreateQuotation, getQuotationById } from "../../store/directusSdk";
import LoginRegister from "./LoginRegister";

// application
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import InputNumber from "../shared/InputNumber";
import PageHeader from "../shared/PageHeader";
import { cartRemoveItem, recalculateShipping, ChangeQuantity } from "../../store/cart";
import { Cross12Svg } from "../../svg";
import SelectAddressShipping from "./SelectAddressShipping";
import { toast } from "react-toastify";
import { data } from "jquery";
import { func } from "prop-types";
import { CURRENT_USER } from "../../store/currentUser/currentUserActionTypes";

class ShopPageCart extends Component {
    constructor(props) {
        super(props);
        const { cart, currentUser } = props;
        this.state = {
            quantities: [],
            sending: false,
            checked: cart.incEnvio,
            open: false,
            shippingAddress: cart.shippingAddress && currentUser ? cart.shippingAddress : null,
            func: null,
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.currentUser !== null) {
            return {
                open: false,
            };
        }
        if (nextProps.currentUser === null) {
            return {
                checked: false,
            };
        }
        return null;
    }

    handleAddressChange = (shippingAddress) => {
        const { recalculateShipping } = this.props;
        const { checked } = this.state;
        if (!shippingAddress) {
            return recalculateShipping(null, false);
        }
        return recalculateShipping(shippingAddress, checked);
    };

    handleCheked = () => {
        const { checked } = this.state;
        const { currentUser, recalculateShipping } = this.props;
        if (!currentUser) {
            return this.setState({ open: true, func: "check" });
        }
        if (checked) {
            recalculateShipping(null, false);
            return this.setState({ checked: !checked, func: null });
        }
        if (currentUser.addresses.length > 0) {
            const addrs = currentUser.addresses.find((addr) => addr.defaultaddress);
            recalculateShipping(addrs ? addrs : currentUser.addresses[0], !checked);
            return this.setState({ checked: !checked, func: null, shippingAddress: addrs ? addrs : currentUser.addresses[0] });
        }
        return this.setState({ checked: !checked, func: null, shippingAddress: null });
    };

    Buy = (event) => {
        event.preventDefault();
        const { currentUser, history } = this.props;
        if (!currentUser) {
            return this.setState({ open: true, func: "buy" });
        }
        this.setState({ open: false, func: null });
        return history.push("/shop/checkout");
    };

    Ingresar = (event) => {
        event.preventDefault();
        const { currentUser } = this.props;
        if (!currentUser) {
            return this.setState({ open: true });
        }
    };

   async GeneratePDF() {
  
        const { currentUser, cart } = this.props;

        if (!currentUser) {
            return this.setState({ open: true, func: "quote" });
        }
        this.setState({ sending: true });
        let lines = "";
        cart.items.forEach((e, i) => {
            lines += `<tr>
            <td style="padding:5px 5px; text-align: center">${i + 1}</td>
            <td style="padding:5px 5px; text-align: center">${e.product.code}</td>
            <td style="padding:5px 5px; text-align: center">${e.product.name}</td>
            <td style="padding:5px 5px; text-align: center">${e.quantity}</td>
            <td style="padding:5px 5px; text-align: right">$ ${(Number(e.price)).toFixed(2)}</td>
            <td style="padding:5px 5px; text-align: right">$ ${e.total.toFixed(2)}</td>
            </tr>`;
        });
        const items = `
        <h2 align="center">Lista de Productos</h2>
        <table id="tabla" style="width: 90%; margin-bottom: 20px; margin-left: auto; margin-right: auto;" border="1" cellspacing="1" cellpadding="1" align="center">
            <thead>
                <tr style="background: #eee; text-align: center;">
                    <th style="padding: 5px 10px;" width="6%">#</th>
                    <th style="padding: 5px 10px;" width="18%">C&oacute;digo</th>
                    <th style="padding: 5px 10px;" width="42%">Producto</th>
                    <th style="padding: 5px 10px;" width="10%">Cant</th>
                    <th style="padding: 5px 10px;" width="12%">Precio</th>
                    <th style="padding: 5px 10px;" width="12%">Total</th>
                </tr>
            </thead>
            <tbody>
                ${lines}
            </tbody>
        </table>`;
        const quotation = {
            subtotal: cart.subtotal,
            shipping: cart.shipping,
            itbms: cart.itbms,
            total: cart.total,
            validity: moment().add(1, "month").format("YYYY-MM-DD"),
            items: items.replace(/,/g, ""),
        };
        const htmlmsg = `<h1 align="center">Nueva Cotizaciòn</h1>
        <table style="width: 90%;" cellspacing="1" cellpadding="1" align="center">
        <tbody>
            <tr>
                <td style="margin: 0px 10px; padding:5px 10px; font-weight: bold;">Cotizaciòn # </td>
                <td style="margin: 0px 10px; padding:5px 10px; text-align: right;">{{id}}</td>
                <td style="margin: 0px 10px; padding:5px 10px; font-weight: bold;">Fecha: </td>
                <td style="margin: 0px 10px; text-align: right;padding:5px 10px;">${moment().format("DD/MM/YYYY")}</td>
            </tr>
            <tr>
                <td style="margin: 0px 10px; padding:5px 10px; font-weight: bold;">Cliente: </td>
                <td style="margin: 0px 10px; padding:5px 10px; text-align: right;">${currentUser.first_name} ${currentUser.last_name}</td>
                <td style="margin: 0px 10px; padding:5px 10px; font-weight: bold;">Validez: </td>
                <td style="margin: 0px 10px; padding:5px 10px; text-align: right;">${moment().add(1, "month").format("DD/MM/YYYY")}</td>
            </tr>
            <tr>
                <td style="margin: 0px 10px; padding:5px 10px; font-weight: bold;">Correo Electrònico: </td>
                <td style="margin: 0px 10px; padding:5px 10px; text-align: right;">${currentUser.email}</td>
                <td style="margin: 0px 10px; padding:5px 10px; font-weight: bold;">Telefono: </td>
                <td style="margin: 0px 10px; padding:5px 10px; text-align: right;">${currentUser.phone}</td>
            </tr>

            ${
                !cart.incEnvio || !cart.delivery
                    ? `
                <tr>
                <td style="margin: 0px 10px; padding:5px 10px; font-weight: bold;">Envio: </td>
                <td style="margin: 0px 10px; padding:5px 10px; text-align: right;">No Incluido</td>
                <td style="margin: 0px 10px; padding:5px 10px; font-weight: bold;"></td>
                <td style="margin: 0px 10px; padding:5px 10px; text-align: right;"></td>
                </tr>
            `
                    : ""
            }
            ${
                cart.incEnvio && cart.delivery && cart.shippingAddress === null
                    ? `
            <tr>
            <td style="margin: 0px 10px; padding:5px 10px; font-weight: bold;">Envio: </td>
            <td style="margin: 0px 10px; padding:5px 10px; text-align: right;">Sera contactado para cotizar envios internacionales.</td>
            <td style="margin: 0px 10px; padding:5px 10px; font-weight: bold;"></td>
            <td style="margin: 0px 10px; padding:5px 10px; text-align: right;"></td>
            </tr>
            `
                    : ""
            }
            ${
                cart.incEnvio && cart.delivery && cart.shippingAddress && cart.shippingAddress?.countryid?.countrycode === "PA"
                    ? `
            <tr>
            <td style="margin: 0px 10px; padding:5px 10px; font-weight: bold;">Dirección de Envio:</td>
            <td style="margin: 0px 10px; padding:5px 10px; text-align: right;">${cart.shippingAddress && cart.shippingAddress.address}</td>
            <td style="margin: 0px 10px; padding:5px 10px; font-weight: bold;">Responsable:</td>
            <td style="margin: 0px 10px; padding:5px 10px; text-align: right;">${cart.shippingAddress && cart.shippingAddress.responsable}</td>
            </tr>
            <tr>
            <td style="margin: 0px 10px; padding:5px 10px; font-weight: bold;">Costo:</td>
            <td style="margin: 0px 10px; padding:5px 10px; text-align: right;">${
                cart && cart.delivery && cart.msgEnvio ? cart.msgEnvio : `$ ${cart.shipping.toFixed(2)}`
            }</td>
            <td style="margin: 0px 10px; padding:5px 10px; font-weight: bold;">Telefono:</td>
            <td style="margin: 0px 10px; padding:5px 10px; text-align: right;">${cart.shippingAddress && cart.shippingAddress.phone1}</td>
            </tr>
            `
                    : ""
            }
        </tbody>
        </table>
        <hr style="background: #004FBD;" size="4" width="95%" />
        <p>&nbsp;</p>
        ${items}
        <hr style="background: #004FBD;" size="4" width="95%" />
        <p>&nbsp;</p>
        <table style="width: 45%; float: right;" border="1" cellspacing="1" cellpadding="1" align="left">
        <tbody>
        <tr>
        <th style="text-align: left; padding: 5px 10px; background: #eee;" scope="row">SUBTOTAL</th>
        <td style="text-align: right; padding: 5px 10px;">$ ${quotation.subtotal.toFixed(2)}</td>
        </tr>
        <tr>
        <th style="text-align: left; padding: 5px 10px; background: #eee;" scope="row">ENVIO</th>
        <td style="text-align: right; padding: 5px 10px;">$ ${quotation.shipping.toFixed(2)}</td>
        </tr>
        <tr>
        <th style="text-align: left; padding: 5px 10px; background: #eee;" scope="row">ITBMS</th>
        <td style="text-align: right; padding: 5px 10px;">$ ${quotation.itbms.toFixed(2)}</td>
        </tr>
        <tr>
        <th style="text-align: left; padding: 5px 10px; background: #eee;" scope="row">TOTAL</th>
        <td style="text-align: right; padding: 5px 10px;">$ ${quotation.total.toFixed(2)}</td>
        </tr>
        </tbody>
        </table>
        <p>&nbsp;</p>`;
        quotation.htmlmsg = htmlmsg;
        const lastQuote = sessionStorage.getItem("lastQuotation");
        const strQuotation = sessionStorage.getItem("strQuotation");
        const quotationStr = JSON.stringify(quotation); 
        quotation.cart = JSON.stringify(cart);
        
        const openPdf = async (quoteId) => {
            try {
              const data = await getQuotationById(quoteId);
              const pdfPath = data.data[0].pdf;
              const pdfUrl = `${process.env.REACT_APP_API_URL}assets/${pdfPath}.pdf`;
              window.open(pdfUrl, "_blank");
            } catch (error) {
              toast.error("Error!, " + error.message);
            } finally {
              this.setState({ sending: false, func: null });
            }
          };
          
          if (strQuotation !== quotationStr) {
            try {
              const quote = await CreateQuotation(quotation);
              sessionStorage.setItem("lastQuotation", JSON.stringify(quote));
              sessionStorage.setItem("strQuotation", quotationStr);
              await openPdf(quote.id);
            } catch (error) {
              toast.error("Error!, " + error.message);
            }
          } else {
            const quote = JSON.parse(lastQuote);
            await openPdf(quote.id);
          }
    }
    renderItems() {
        const { cart, cartRemoveItem, ChangeQuantity, currentUser } = this.props;
        const findingDiscounts = cart?.items.find(item => item.product.discount !== undefined && item.product.discount !== null);
const priceMultipliers = {
    2: 1.61,
    3: 1.5,
    4: 1.4,
    5: 1.35,
    6: 1.28,
    7: 1.0,
    default: 1.74
};
console.log(cart)
const priceUser = priceMultipliers[currentUser?.multi] || priceMultipliers.default;
        return cart.items.map((item, i) => {
            let image;
            let options;

            if (item.product.images.length > 0) {
                image = (
                    <Link to={`/shop/product/${item.product.code.replace(/\//g, "-")}/${item.product.id}`}>
                        <img src={item.product.images[0]} alt="" />
                    </Link>
                );
            }

            if (item.options.length > 0) {
                options = (
                    <ul className="cart-table__options">
                        {item.options.map((option, index) => (
                            <li key={index}>{`${option.optionTitle}: ${option.valueTitle}`}</li>
                        ))}
                    </ul>
                );
            }

            const removeButton = (
                <AsyncAction
                    action={() => cartRemoveItem(item.id)}
                    render={({ run, loading }) => {
                        const classes = classNames("btn btn-light btn-sm btn-svg-icon", {
                            "btn-loading": loading,
                        });

                        return (
                            <button type="button" onClick={run} className={classes}>
                                <Cross12Svg />
                            </button>
                        );
                    }}
                />
            );

            return (
                <tr key={item.id} className="cart-table__row">
                    <td className="cart-table__column cart-table__column--image">{image}</td>
                    <td className="cart-table__column cart-table__column--product">
                        <Link to={`/shop/product/${item.product.code.replace(/\//g, "-")}/${item.product.id}`} className="cart-table__product-name">
                            {item.product.name}
                            <br />
                            <span style={{ fontSize: "0.8em" }}>{`Código de Producto: ${item.product.code}`}</span>
                        </Link>
                        {options}
                        <br />
                        {item.product.bod01 < 1 && item.product.bod05 < 1 ? (
                            <>
                                <span className="text-success" style={{ fontSize: "0.8em", width: "100%" }}>{`Para entrega de ${
                                    item.product.days
                                } a ${item.product.days + 5} días hábiles.`}</span>
                                <br></br>
                                {item.product &&
                                    item.product.existence &&
                                    item.product.existence.length > 0 &&
                                    item.product.existence.map((ele) => (
                                        <>
                                            <span
                                                className="text-success"
                                                style={{ fontSize: "0.8em", width: "100%" }}
                                            >{` ${ele.inv.toUpperCase()} : disponible en ${ele.time} Días, ${ele.qty} un.`}</span>{" "}
                                            <br></br>
                                        </>
                                    ))}
                            </>
                        ) : (
                            <p>
                                {item.product.bod01 > 0 ? (
                                    <>
                                        <span
                                            className="text-success"
                                            style={{ fontSize: "0.8em" }}
                                        >{`Disponibilidad Inmediata: ${item.product.bod01} un.`}</span>
                                        <br></br>
                                    </>
                                ) : null}
                                {item.product.bod05 > 0 ? (
                                    <>
                                        <span className="text-success" style={{ fontSize: "0.8em" }}>
                                            {item.product.bod01 > 0 ? <br /> : null}
                                            {`Disponible en 5 días hábiles: ${item.product.bod05} un.`}
                                        </span>
                                        <br></br>
                                    </>
                                ) : null}
                                {item.product &&
                                    item.product.existence &&
                                    item.product.existence.length > 0 &&
                                    item.product.existence.map((ele) => (
                                        <>
                                            <span
                                                className="text-success"
                                                style={{ fontSize: "0.8em", width: "100%" }}
                                            >{`${ele.inv.toUpperCase()} : disponible en ${ele.time} Días, ${ele.qty} un.`}</span>
                                            <br></br>
                                        </>
                                    ))}
                            </p>
                        )}
                    </td>                    
                    {findingDiscounts &&
                        <td className="cart-table__column cart-table__column--quantity" data-title="descuento">
                    {item.product.discount ?`$${(item.product.price_without_sale_price/1.74*priceUser).toFixed(2)}` :  `$${item.price}`}
                    {/* {currentUser ?`$${item.product.price}` :  `$${item.price}`} */}
                   </td>}
                    <td className="cart-table__column cart-table__column--price" data-title="Precio">
                        <Currency value={Number(item.price)} />
                    </td>
                    {findingDiscounts &&
                    <td style={{color:`${item.product?.discount? 'red': 'black'}`}} className="cart-table__column cart-table__column--quantity" data-title="descuento">
                    {item.product.discount ?`-${item?.product?.discount}%`: 0 }
                    </td>}
                    <td className="cart-table__column cart-table__column--quantity" data-title="Cantidad">
                        <InputNumber onChange={(quantity) => ChangeQuantity(i, quantity)} value={item.quantity} min={1} />
                    </td>
                    <td className="cart-table__column cart-table__column--total" data-title="Total">
                        <Currency value={item.total} />
                    </td>
                    <td className="cart-table__column cart-table__column--remove">{removeButton}</td>
                </tr>
            );
        });
    }

    renderTotals() {
        const { cart, currentUser } = this.props;

        return (
            <React.Fragment>
                <thead className="cart__totals-header">
                    <tr>
                        <th>Subtotal</th>
                        <td>
                            <Currency value={cart.subtotal} />
                        </td>
                    </tr>
                    <tr>
                        <th>Envio</th>
                        <td>
                            {cart.msgEnvio && (
                                <span className={`text-${cart.delivery ? "success" : "danger"} mr-2`} style={{ fontSize: "0.8em" }}>
                                    {cart.msgEnvio}
                                </span>
                            )}
                            <Currency value={cart.shipping} />
                        </td>
                    </tr>
                </thead>
                <tbody className="cart__totals-body">
                    <tr>
                        <th>ITBMS</th>
                        <td>
                            {currentUser && currentUser?.countryid?.countrycode !== "PA" && (
                                <span className="text-success mr-2" style={{ fontSize: "0.8em" }}>
                                    No aplica para ventas fuera de Panamá
                                </span>
                            )}
                            <Currency value={cart.itbms} />
                        </td>
                    </tr>
                </tbody>
            </React.Fragment>
        );
    }

    renderCart() {
        const { cart, currentUser } = this.props;
        const { sending, checked, shippingAddress, open } = this.state;
        const findingDiscounts = cart?.items.find(item => item.product.discount !== undefined && item.product.discount !== null);

        return (
            <div className="cart block" id="cont">
                <div className="container">
                    {!currentUser && (
                        <div className="alert alert-primary text-center">
                            Ingrese con su{" "}
                            <Link to="/account/login" onClick={this.Ingresar}>
                                Cuenta
                            </Link>{" "}
                            para Obtener mejores precios.
                        </div>
                    )}
                    <table className="cart__table cart-table">
                        <thead className="cart-table__head">
                            <tr className="cart-table__row">
                                <th className="cart-table__column cart-table__column--image">Imagen</th>
                                <th className="cart-table__column cart-table__column--product">Producto</th>
                                {findingDiscounts &&<th className="cart-table__column cart-table__column--price">Precio sin descuento</th>}
                                <th className="cart-table__column cart-table__column--price">Precio</th>
                                {findingDiscounts &&<th className="cart-table__column cart-table__column--price">% Descuento</th>}
                                <th className="cart-table__column cart-table__column--quantity">Cantidad</th>
                                <th className="cart-table__column cart-table__column--total">Total</th>
                                <th className="cart-table__column cart-table__column--remove" aria-label="Remove" />
                            </tr>
                        </thead>
                        <tbody className="cart-table__body">{this.renderItems()}</tbody>
                    </table>
                    <div className="cart__actions">
                        <form className="cart__coupon-form">
                            <label>
                                <span>Incluir Costo de Envio: </span>
                                <Switch onChange={this.handleCheked} checked={checked} className="ml-2" />
                            </label>
                        </form>
                        <div className="cart__buttons">
                            <button
                                type="button"
                                className={`btn btn-info ${sending && "btn-loading"}`}
                                onClick={() => this.GeneratePDF()}
                                disabled={sending}
                            >
                                Cotizacion en PDF
                            </button>
                        </div>
                    </div>

                    <div className="row justify-content-end pt-md-5 pt-4">
                        <div className="col-12 col-md-4 col-lg-6 col-xl-7">
                            {checked && (
                                <SelectAddressShipping
                                    currentUser={currentUser}
                                    handleAddressChange={this.handleAddressChange}
                                    shippingAddress={shippingAddress}
                                />
                            )}
                        </div>
                        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="card-title">Total Carrito</h3>
                                    <table className="cart__totals">
                                        {this.renderTotals()}
                                        <tfoot className="cart__totals-footer">
                                            <tr>
                                                <th>Total</th>
                                                <td>
                                                    <Currency value={cart.total} />
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    <Link to="/shop/checkout" className="btn btn-primary btn-xl btn-block cart__checkout-button" onClick={this.Buy}>
                                        Comprar
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={open} toggle={() => this.setState({ open: false })} centered size="lg">
                    <ModalHeader toggle={() => this.setState({ open: false })}>INGRESE CON SU CUENTA.</ModalHeader>
                    <ModalBody style={{padding:"0px"}}>
                        <LoginRegister />
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    render() {
        const { cart } = this.props;
        const breadcrumb = [
            { title: "Home", url: "/" },
            { title: "Carrito de Compras", url: "" },
        ];

        let content;

        if (cart.quantity) {
            content = this.renderCart();
        } else {
            content = (
                <div className="block block-empty">
                    <div className="container">
                        <div className="block-empty__body">
                            <div className="block-empty__message">¡Su Carrito de compras está vacío!</div>
                            <div className="block-empty__actions">
                                <Link to="/" className="btn btn-primary btn-sm">
                                    Continuar
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>Carrito de Compras -- ICAutomatizados S.A</title>
                </Helmet>

                <PageHeader header="Carrito de Compras" breadcrumb={breadcrumb} />

                {content}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    currentUser: state.currentUser,
});

const mapDispatchToProps = {
    cartRemoveItem,
    ChangeQuantity,
    recalculateShipping,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCart);

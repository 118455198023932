import React, { useEffect, useState } from 'react';
import ProductsList from './ProductsList';
import { getAccessories } from '../../store/directusSdk';


function ProductTabAccessories(props) {
    const { accessories } = props;
    const [state, setState] = useState({ filters: [], products: [], loading: true });
 
    useEffect(() => {
        setState({ filters: [], products: [], loading: true });
        const productos = getAccessories(accessories);
        productos.then((res) => {
            if (res.data.length > 0) {
                const products = res.data.map((e, i) => {
                    const bodegas = e.OnHandByWhs.split(',');
                    const bod01 = bodegas[0].split(':');
                    const bod05 = bodegas[1].split(':');
                    const features = [];
                    if (e.especificaciones !== '') {
                        const specifications = e.especificaciones.split('<br>').splice(0,5);
                        specifications.forEach((e) => {
                            if (e !== '') {
                                const specif = e.split(':');
                                features.push({ name: specif[0], value: specif[1] });
                            }
                        });
                    }
                    const images = [];
                    if (e.images !== '') {
                        const imag = e.images.split(',');
                        imag.forEach((img) => {
                            if (img !== '') {
                                images.push(`images/products/${img}`);
                            }
                        });
                    } else {
                        images.push('images/not_found.png');
                    }

                    const product = Object.assign(e);
                    product.days = parseInt(e.days);
                    product.bod01 = parseInt(bod01[1]);
                    product.bod05 = parseInt(bod05[1]);
                    product.compareAtPrice = null;
                    product.images = images;
                    product.features = features;
                    return product;
                });
                setState({ category: products[0].category, products, loading: false });
            } else {
                setState({ products: [], loading: false });
            }
        });
    }, [accessories]);

    if (state.loading) {
        return (
            <div className="container">
                <br />
                <br />
                <div className="div-loading" />
                <br />
                <br />
            </div>
        );
    }

    return <ProductsList products={state.products} filters={[]} />
}

export default ProductTabAccessories;

// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


function SolutionsCard(props) {
    const { solutions, layout } = props;
    const cardClasses = classNames(
        'post-card',
        {
            'post-card--layout--grid': ['grid-nl', 'grid-lg'].includes(layout),
            'post-card--layout--list': ['list-nl', 'list-sm'].includes(layout),
            'post-card--size--nl': ['grid-nl', 'list-nl'].includes(layout),
            'post-card--size--lg': layout === 'grid-lg',
            'post-card--size--sm': layout === 'list-sm',
        },
    );
    const slug = solutions.solution_name.replace(/ /gi,'_');

    return (
        <>
        <div className='solutions_container'>
            <div className="post-card__image">
                <Link to={`/soluciones/${slug}`}>
                    <img src={`${process.env.REACT_APP_API_URL}assets/${solutions.solution_image}`} alt="" />
                </Link>
            </div>
            <div className="solution-card__info">
                <div className="post-card__name">
                    <Link to={`/soluciones/${slug}`}>{solutions.solution_name}</Link>
                    <p>{solutions.text}</p>
                </div>
                <div className="post-card__content">
                    <p align="justify">
                    {solutions.solution_text}
                    </p>
                </div>

                  <div className="button_read_mor_container">
                    <Link style={{zIndex:999}} to={`/soluciones/${slug}`} className="button_read_more">Leer más...</Link>
                </div>
</div>               
        </div>
                    
        </>
);
}

SolutionsCard.propTypes = {
    /**
     * post data object
     */
    solutions: PropTypes.object,
    /**
     * post card layout
     * one of ['grid-nl', 'grid-lg', 'list-nl', 'list-sm']
     */
    layout: PropTypes.oneOf(['grid-nl', 'grid-lg', 'list-nl', 'list-sm']),
};

export default SolutionsCard;

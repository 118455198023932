// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';
import { addTransactionLog } from '../../store/directusSdk';
import PageHeader from '../shared/PageHeader';
import Currency from '../shared/Currency';


class ShopPagePayment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order: JSON.parse(localStorage.getItem('ord')),
            transaction: null,
        };
    }

    componentDidMount() {
        const { order } = this.state;
        const trans = addTransactionLog(order.total, order.numberOrder);
        trans.then((res) => {
            const transaction = res.data;
            const script = document.createElement('script');
            script.src = `${process.env.REACT_APP_TC_URL + transaction.formid}`;
            script.async = false;
            document.body.appendChild(script);

            localStorage.setItem('trans', JSON.stringify(transaction));
            localStorage.setItem('try', '0');
            this.setState(() => ({ transaction }));
        });
    }

    render() {
        const { currentUser } = this.props;
        const { transaction, order } = this.state;

        if (!currentUser) {
            return <Redirect to="/" />;
        }
        const breadcrumb = [
            { title: 'Mis Cuenta', url: '/account/dashboard' },
            { title: 'Mis Ordenes', url: '/account/orders' },
            { title: 'Trarjeta de Credito', url: '' },
        ];

        return (
            <React.Fragment>
                <Helmet>
                    <title>Procesar pago - ICAutomatizados S.A</title>
                </Helmet>

                <PageHeader breadcrumb={breadcrumb} />
                <div className="checkout block">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-7 col-xl-7">
                                <div className="card" id="cr">
                                    <div className="card-body text-center">
                                        <h4 className="card-title">Ingrese los Datos de su Tarjeta de Credito</h4>
                                        <div className="card-table" >
                                            <strong>Total a Cobrar:</strong>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <strong><Currency value={order.total} /></strong>
                                        </div>
                                        <div className="card-divider" />
                                        <br />
                                        {!transaction && (
                                            <React.Fragment>
                                                <br />
                                                <br />
                                                <br />
                                                <div className="div-loading" />
                                                <br />
                                                <br />
                                                <br />
                                            </React.Fragment>
                                        )}
                                        <form
                                            action={process.env.REACT_APP_ShopperReturnURL}
                                            className="paymentWidgets wpwl-form wpwl-clearfix"
                                            data-brands="VISA MASTER"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-5 col-xl-5 mt-4 mt-lg-0">
                                <div className="card mb-0">
                                    <div className="card-body">
                                        <div align="center">
                                            <h4 className="card-title">¡Su Pago de Manera Segura!</h4>
                                            <img src="images/secure-payment.png" alt="" />
                                        </div>
                                        <p align="justify">Contamos con el mejor proveedor de servicios para compras con tarjetas de credito, garantizando la seguridad de sus datos asi como la rapidez en todas sus transacciones.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };
}

export default ShopPagePayment;
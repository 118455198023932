import {
    PAGESCONTENT_CHANGE,
    FILTERS_CHANGE,
    RESET_FILTERS,
    DROP_MULTIPLE_FILTER,
    DROP_FILTER,
} from './pagesContentActionTypes';

const initialState = {
    filters: [], // Initialize filters as an array
};

export default function pagesContentReducer(state = initialState, action) {
    function arraysAreEqual(arr1, arr2) {
        // Implementa la lógica de comparación de arrays según tus necesidades
        // Puedes comparar elementos uno por uno o utilizar algún método de comparación
        // Aquí se asume que ambos arrays son de la misma longitud para simplificar
        return JSON.stringify(arr1) !== JSON.stringify(arr2);
      }


    switch (action.type) {

        case PAGESCONTENT_CHANGE:
            return action.pagesContent;
        case FILTERS_CHANGE:
            const existingFilterIndex = state.filters.findIndex((filter) => filter.name === action.filter.name);
            if (existingFilterIndex !== -1) {
                // If the filter already exists, update its value
                const updatedFilters = [...state.filters];
                updatedFilters[existingFilterIndex] = action.filter;
                return { ...state, filters: updatedFilters };
            } else {
                // If the filter doesn't exist, add it to the filters array
                return { ...state, filters: [...state.filters, action.filter] };
            }
            case DROP_FILTER:

            if (action.filter.value.length < 1) {
                // Si es el filtro que quieres eliminar y tiene solo un valor, exclúyelo
                const updatedValue = state.filters.filter((e) => e.name !== action.filter.name);
                return { ...state, filters: updatedValue };

            } 
                    const updatedFilters = state.filters.map((filter) => {
                        console.log(filter.value.length)
                         if (filter.name === action.filter.name) {
                          // Si es el filtro que quieres modificar, actualiza el array value
                          const updatedValue = filter.value.filter((val) => val !== action.value);
                          return { ...filter, value: updatedValue };
                        }
                        return filter;
                      });
                    
                      return { ...state, filters: updatedFilters }
            
        case DROP_MULTIPLE_FILTER:
            const existingFilterIndexs = state.filters.findIndex((filter) => filter.name === action.filter.name);

            if (existingFilterIndex !== -1) {
                // Clona el array de filtros y actualiza el filtro específico con los valores filtrados
                const updatedFilters = [...state.filters];
                updatedFilters[existingFilterIndexs] = {
                    ...state.filters[existingFilterIndex],
                    value: action.filter.value,
                };
                return { ...state, filters: [...state.filters, updatedFilters] };
            }
            return state;


        case RESET_FILTERS:
            return { ...state, filters: [] };

        default:
            return state;
    }
}
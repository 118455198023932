// react
import React from 'react';

// third-party
import AccountFormLogin from './AccountFormLogin';


function Login() {

    return <AccountFormLogin LoginCheckout />;
}

export default Login;

// react
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
} from 'reactstrap';
// third-party
import './slides.css';

const BlockCarousel = ({ items }) => {

    const [animating, setAnimating] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const media = window.matchMedia('(max-width: 540px)').matches;

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }
    const slides = items.map((item, index) => {
        const backgroundStyles = {
            backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/${item?.background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            width: '100%', // Ajusta el ancho según tus necesidades
            height: '400px', // Ajusta la altura según tus necesidades
        };
        const color_title = {
            color: `${item?.color_title}`
        }
        const color_body = {
            color: `${item?.color_body}`
        }

        return (

            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item?.id}
            >
                {item.manual_slider === false ?
                    <Link to={item?.link_button || null} >
                        <div className="slidess" >
                            <img className="slidess-background"src={media ? `${process.env.REACT_APP_API_URL}assets/${item?.image_mobile}` : `${process.env.REACT_APP_API_URL}assets/${item?.image} `} alt={item.title} />

                            {/* <img className='slidess-background' src={`${process.env.REACT_APP_API_URL}assets/${item?.image} `} alt={item.title} /> */}
                        </div>
                    </Link>

                    :
                    <div >
                        {/* <img src={media ? `${process.env.REACT_APP_API_URL}assets/${item.background_mobile}` : `${process.env.REACT_APP_API_URL}assets/${item.background} `} alt='' /> */}
                        <div className="slidess" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}assets/${item.background})` }}>
                            <div className="container">
                                <div className={`carousel-container ${index === activeIndex ? 'active' : ''}`}>
                                    <div className='text-container'>
                                        <img className='imagen-title-carousel' src={`${process.env.REACT_APP_API_URL}assets/${item?.imagen_title} `} alt={item?.imagen_title} />
                                        <h1 style={color_title} className='title-carousel' >{item?.title}</h1>
                                        <h4 style={color_title} className='code-carousel' >{item?.code}</h4>
                                        <h4 style={color_body} className='imagen-body-carousel'>{item?.body}</h4>

                                            <Link to={item?.link_button || null}> 
                                            <button className={`button_carousel ${index === activeIndex ? 'active' : ''}`}> 
                                                Pídelo Ya!
                                            </button>                                           
                                             </Link>

                                    </div>
                                    <div className={`imagen-container fade-in-out ${index === activeIndex ? 'active' : ''}`}>
                                        <Link to={item?.link_button}>
                                            <img
                                                src={`${process.env.REACT_APP_API_URL}assets/${item?.product_image}`}
                                                alt={item?.title}
                                            />
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                       
                    </div>
                }
            </CarouselItem>
        );
    }
    );

    return (
        <div className="mb-5">
            <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
            >
                <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
                {slides}
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
            </Carousel>
        </div>
    );
}


const mapStateToProps = (state) => ({
    locale: state.locale,
    items: state.pagesContent.carousel,
});

export default connect(mapStateToProps)(BlockCarousel);

/* eslint-disable linebreak-style */
// react
import React, { useState, useEffect } from 'react';
// third-party
import { Helmet } from 'react-helmet-async';
import Slider from 'react-slick';
// blocks
import BlockBrands from '../blocks/BlockBrands';
import BlockCategories from '../blocks/BlockCategories';
import BlockFeatures from '../blocks/BlockFeatures';
import BlockSlideShow from '../blocks/BlockSlideShow';
import Whastapp from '../../svg/whatsapp.png';
import BlockProducts from '../blocks/BlockProducts';
import { getSaleProducts } from '../../store/directusSdk';
import BlockCarousel from '../blocks/BlockCarousel';
import BlockHeader from '../shared/BlockHeader';

export default function HomePageOne(props) {
    const keyWords = 'SALE';
    const [featuredProducts, setFeaturedProducts] = useState([]);
    const { categories, brands } = props;
    
    useEffect(() => {
        const abortController = new AbortController();
        const fetchData = async () => {
            try {
                const res = await getSaleProducts(keyWords, { signal: abortController.signal });
                setFeaturedProducts(res.data);
            } catch (error) {
                console.error('Error fetching carousel data:', error);
            }
        };

        fetchData();
        return () => {
            abortController.abort();
        };
    }, []);

    const chunkedProducts = [];
    for (let i = 0; i < featuredProducts.length; i += 9) {
        chunkedProducts.push(featuredProducts.slice(i, i + 9));
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        pauseOnHover: false,
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>Ingeniería y Controles Automatizados</title>
            </Helmet>

            <BlockCarousel />
            <BlockFeatures />
            <BlockCategories title="Categorias" layout="classic" categories={categories} />
            <div className="container">
                <BlockHeader title="Productos en Oferta" />
            </div>

            <Slider {...settings}>
                {chunkedProducts.map((productsChunk, index) => (
                    <div key={index}>
                        <BlockProducts
                            layout="large-first"
                            products={productsChunk.slice(1, 9)}
                            featuredProduct={productsChunk[0]}

                        />
                    </div>
                ))}
            </Slider>

            <BlockBrands brands={brands} />

            <div className="whatsapp-float">
                <a href="https://api.whatsapp.com/send?phone=62996744&text=Hola%20desde%20la%20web%20quisiera%20más%20información" target="_blank">
                    <img src={Whastapp} alt="WhatsApp" />
                </a>
            </div>
        </React.Fragment>
    );
}

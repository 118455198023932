// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';

// application
import PageHeader from '../shared/PageHeader';
import AccountFormLogin from './AccountFormLogin';
import AccountFormRegister from './AccountFormRegister';


function AccountPageLogin(props) {
    const { currentUser, history, company, location } = props;
    if (currentUser) {
        if (location.state && location.state.lastUrl) {
            return <Redirect to={{pathname: '/shop/cart', state: {GoQuotation: true}}} />;
        }
        return <Redirect to={{pathname: '/account/dashboard'}} />;
    }

    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Mi Cuenta', url: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Login — ${company[0].name}`}</title>
            </Helmet>

            <PageHeader header="Mi Cuenta" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex">
                            <div className="card flex-grow-1 mb-md-0">
                                <div className="card-body">
                                    <AccountFormLogin history={history} location={location} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex mt-4 mt-md-0">
                            <div className="card flex-grow-1 mb-0">
                                <div className="card-body">
                                    <AccountFormRegister type="Crear" history={history} location={location} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AccountPageLogin;

// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es-us';
import Currency from '../shared/Currency';
import { getDefaultAddress } from '../../store/directusSdk';

class AccountPageDashboard extends Component {
    constructor(props) {
        super(props);
        const { currentUser } = props;
        const address = currentUser ?  getDefaultAddress( currentUser.id).then(res => res.data[0]): null;
        this.state = {
             address,
            listOrders: [],
        };
    }
   

    componentDidMount() {
        const { currentUser, getOrders } = this.props;
        getOrders(currentUser.id)
            .then((res) => this.setState(() => ({ listOrders: res.data })));
    }

    render() {
        const { currentUser, history, company } = this.props;
        if (!currentUser) {
            history.replace('/account/login');
            return null;
        }
      
        
        const { listOrders, address } = this.state;
        const orders = listOrders.slice(0, 5).map((order) => {
            const date = moment(order.created_on, 'YYYY/MM/DD HH:mm:ss', 'es-us');
            const dateFormated = date.add(date.utcOffset(), 'minutes').format('LLL');
           
            return (
                <tr key={order.id}>
                    <td>
                        <Link to={`/account/order/${order.id}`}>
                            #
                            {order.id}
                        </Link>
                    </td>
                    <td>{dateFormated}</td>
                    <td>
                        <div className={`badge badge-${order.status === 'pendingforpayment' ? 'info' : 'success'}`}>{order.status === 'pendingforpayment' ? 'Pendiente' : 'Procesada'}</div>
                    </td>
                    <td><Currency value={order.total} /></td>
                </tr>
            );
        });

        return (
            <div className="dashboard">
                <Helmet>
                    <title>{`Dashboard — ${company[0].name}`}</title>
                </Helmet>

                <div className="dashboard__profile card profile-card">
                    <div className="card-body profile-card__body">
                        <div className="profile-card__avatar">
                            <img src="images/avatars/avatar-3.jpg" alt="" />
                        </div>
                        <div className="profile-card__name">{`${currentUser.first_name} ${currentUser.last_name}`}</div>
                        <div className="profile-card__email">{currentUser.email}</div>
                        <div className="profile-card__edit">
                            <Link to="profile" className="btn btn-secondary ">Editar Perfil</Link>
                        </div>
                    </div>
                </div>
                {/* {address && (
                    // <div className="dashboard__address card address-card address-card--featured">
                    //     {address.defaultaddress && <div className="address-card__badge">Dirección Predeterminada</div>}
                    //     <div className="address-card__body">
                    //         <div className="address-card__name">{address.responsable}</div>
                    //         <div className="address-card__row">
                    //             {address.address}
                    //             ,
                    //             <br />
                    //              {address.countryid.countryname === 'Panamá' && address.deliverycityid ? `${address.deliverycityid?.cityname}, ${address?.countryid?.countryname}.` : `${address.countryid?.countryname}.`}
                    //         </div>
                    //         <div className="address-card__row">
                    //             <div className="address-card__row-title">Telefono Principal</div>
                    //             <div className="address-card__row-content">{address.phone1}</div>
                    //         </div>
                    //         <div className="address-card__row">
                    //             <div className="address-card__row-title">Telefono Opcional</div>
                    //             <div className="address-card__row-content">{address.phone2}</div>
                    //         </div>
                    //         <div className="address-card__row">
                    //             <div className="address-card__row-title">Correo Electrnico</div>
                    //             <div className="address-card__row-content">{address.email}</div>
                    //         </div>
                    //         <div className="address-card__footer">
                    //             <Link to="/account/addresses">Editar Direcciones</Link>
                    //         </div>
                    //     </div>
                    // </div>
                )} */}
                
                {orders.length > 0 && (
                    <div className="dashboard__orders card">
                        <div className="card-header">
                            <h5>Ordenes Recientes</h5>
                        </div>
                        <div className="card-divider" />
                        <div className="card-table">
                            <div className="table-responsive-sm">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Orden</th>
                                            <th>Fecha</th>
                                            <th>Estado</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orders}
                                        <tr>
                                            <td colSpan={4}>
                                                <div className="center">
                                                    <Link to="/account/orders" className="btn btn-secondary btn-sm center">
                                                        <p align="center">Ver Historial de Ordenes</p>
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
export default AccountPageDashboard;

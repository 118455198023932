import { CURRENT_USER, ADDRESSES_USER } from './currentUserActionTypes';


// eslint-disable-next-line import/prefer-default-export
export const currentUserChange = (currentUser) => ({
    type: CURRENT_USER,
    currentUser,
});
export const AddressesChange = (addresses) => ({
    type: ADDRESSES_USER,
    addresses,
});

import { combineReducers } from 'redux';

// reducers
import cartReducer from './cart';
import compareReducer from './compare';
import currencyReducer from './currency';
import currentUserReducer from './currentUser';
import localeReducer from './locale';
import mobileMenuReducer from './mobile-menu';
import quickviewReducer from './quickview';
import sidebarReducer from './sidebar';
import wishlistReducer from './wishlist';
import pagesContentReducer from './pagesContent';


export default combineReducers({
    cart: cartReducer,
    compare: compareReducer,
    currency: currencyReducer,
    currentUser: currentUserReducer,
    locale: localeReducer,
    mobileMenu: mobileMenuReducer,
    quickview: quickviewReducer,
    sidebar: sidebarReducer,
    wishlist: wishlistReducer,
    pagesContent: pagesContentReducer,
});

// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import 'moment/locale/es';
// application
import PageHeader from '../shared/PageHeader';


function SitePageTerms(props) {
    const { company, terms } = props;
    const modified = moment(terms.modified_on, 'YYYY/MM/DD HH:mm:ss').locale('es').format('LL');

    const breadcrumb = [
        { title: 'Home', url: '/' },
        { title: 'Términos y Condiciones', url: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Términos y Condiciones — ${company.name}`}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="document">
                        <div className="document__header">
                            <h1 className="document__title">Términos y Condiciones</h1>
                            <div className="document__subtitle">{`Este Acuerdo fue modificado por última vez el ${modified}`}</div>
                        </div>
                        <div
                            className="document__content typography"
                            dangerouslySetInnerHTML={{ __html: terms.body }}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePageTerms;

// react
import React from 'react';
// third-party
import { connect } from 'react-redux';
import PageHeader from '../shared/PageHeader';


function ResponsePassword(props) {
    const { match: { params: { result } } } = props;
    const breadcrumb = [
        { title: 'Cuenta', url: 'account/login' },
        { title: 'Reestablecer Contraseña', url: '' },
    ];

    return (
        <React.Fragment>
            <PageHeader breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="document">
                        {result === 'true' ? (
                            <div className="document__header">
                                <div align="center">
                                    <img src="images/pass_success.png" alt="" />
                                    <br />
                                    <br />
                                    <h3 className="document__title">¡Contraseña Actualizada!</h3>
                                    <span>Se actualizo su contraseña correctamente.</span>
                                </div>
                            </div>
                        ) : (
                            <div className="document__header">
                                <div align="center">
                                    <img src="images/token_expired.png" alt="" />
                                    <br />
                                    <br />
                                    <h3 className="document__title">¡Token ha Expirado!</h3>
                                    <span>Por favor, vuelva a solicitar un nuevo token de recuperación.</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
    company: state.pagesContent.company,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ResponsePassword);

// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';

// application
import ProductsView from './ProductsView';

function ProductsList(props) {
    const {
        products,
        columns,
        viewMode,
        filters,
    } = props;

 
    let content;

    const offcanvas = columns === 3 ? 'mobile' : 'always';

    if (columns > 3) {
        content = (
            <div className="container">
                <div className="block">
                    <ProductsView
                        products={products}
                        layout={viewMode}
                        grid={`grid-${columns}-full`}
                        limit={15}
                        offcanvas={offcanvas}
                        filters={filters}
                    />
                </div>
            </div>
        );
    } else {
        content = (
            <div className="container">
                <div className={`shop-layout`}>
                    <div className="shop-layout__content">
                        <div className="block">
                            <ProductsView
                                products={products}
                                layout={viewMode}
                                grid="grid-3-sidebar"
                                limit={15}
                                offcanvas={offcanvas}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            {content}
        </React.Fragment>
    );
}

ProductsList.propTypes = {
    /**
     * number of product columns (default: 3)
     */
    columns: PropTypes.number,
    /**
     * mode of viewing the list of products (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    viewMode: PropTypes.oneOf(['grid', 'grid-with-features', 'list']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
};

ProductsList.defaultProps = {
    columns: 4,
    viewMode: 'list',
};

export default ProductsList;
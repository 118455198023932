// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

// application
import ProductTabSpecification from './ProductTabSpecification';
import ProductTabAccessories from './ProductTabAccessories';
import ProductTabVideo from './ProductTabVideo';
import ProductTabPdf from './ProductTabPdf';
import ProductTabAlternatives from './ProductTabAlternatives';


class ProductTabs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            product: props.product,
            currentTab: '',
            tabs: [],
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.product.id !== state.product.id) {
            const { product } = props;
            const tabs = [];
            if (product.especificaciones) {
                tabs.push({ key: 'specification', title: 'Especificaciones', content: <ProductTabSpecification specification={product.especificaciones} /> });
            }
            if (product.accesories) {
                tabs.push({ key: 'accesories', title: 'Accesorios', content: <ProductTabAccessories accessories={product.accesories} /> });
            }
            if (product.video) {
                tabs.push({ key: 'video', title: 'Video', content: <ProductTabVideo video={product.video} /> });
            }
            if (product.pdfs.length > 0) {
                tabs.push({ key: 'pdfs', title: 'PDFs', content: <ProductTabPdf pdfs={product.pdfs} /> });
            }
            if (product.alternatives) {
                tabs.push({ key: 'alternatives', title: 'Alternativas', content: <ProductTabAlternatives alternatives={product.alternatives} /> });
            }

            return {
                product,
                currentTab: tabs[0].key,
                tabs,
            };
        }
        return null;
    }

    componentDidMount() {
        const { product } = this.props;
        const tabs = [];
        if (product.especificaciones) {
            tabs.push({ key: 'specification', title: 'Especificaciones', content: <ProductTabSpecification specification={product.especificaciones} /> });
        }
        if (product.accesories) {
            tabs.push({ key: 'accesories', title: 'Accesorios', content: <ProductTabAccessories accessories={product.accesories} /> });
        }
        if (product.video) {
            tabs.push({ key: 'video', title: 'Video', content: <ProductTabVideo video={product.video} /> });
        }
        if (product.pdfs.length > 0) {
            tabs.push({ key: 'pdfs', title: 'PDFs', content: <ProductTabPdf pdfs={product.pdfs} /> });
        }
        if (product.alternatives) {
            tabs.push({ key: 'alternatives', title: 'Alternativas', content: <ProductTabAlternatives alternatives={product.alternatives} /> });
        }
        if (tabs.length > 0) {
            this.setState(() => ({ currentTab: tabs[0].key, tabs }));
        }
    }

    setTab = (newTab) => {
        this.setState(() => ({ currentTab: newTab }));
    };

    render() {
        const { currentTab, tabs } = this.state;
        const { withSidebar } = this.props;
        const classes = classNames('product-tabs', {
            'product-tabs--layout--sidebar': withSidebar,
        });
        if (tabs.length < 1) {
            return null;
        }
        const tabsButtons = tabs.map((tab) => {
            const classes = classNames('product-tabs__item', {
                'product-tabs__item--active': currentTab === tab.key,
            });

            return <button key={tab.key} type="button" onClick={() => this.setTab(tab.key)} className={classes}>{tab.title}</button>;
        });

        const tabsContent = tabs.map((tab) => {
            const classes = classNames('product-tabs__pane', {
                'product-tabs__pane--active': currentTab === tab.key,
            });

            return <div key={tab.key} className={classes}>{tab.content}</div>;
        });

        return (
            <div className={classes}>
                <div className="product-tabs__list">
                    {tabsButtons}
                </div>
                <div className="product-tabs__content">
                    {tabsContent}
                </div>
            </div>
        );
    }
}

ProductTabs.propTypes = {
    withSidebar: PropTypes.bool,
};

ProductTabs.defaultProps = {
    withSidebar: false,
};

export default ProductTabs;

/* eslint-disable */
import React, { useState } from 'react';
import {registerEmail} from '../../store/directusSdk';
import { isEmptyArray } from 'formik';
export default function FooterNewsletter() {
    
    function isValidEmail(email) {
        // A basic regular expression for email format validation
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailRegex.test(email);
    }
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [modal, setModal] = useState(false)
    const socialLinks = [
        {
            key: 'linkedln',
            url: 'https://linkedin.com/company/icautomatizados',
            iconClass: 'fab fa-linkedin',
        },
        {
            key: 'facebook',
            url: 'https://www.facebook.com/icautomatizados',
            iconClass: 'fab fa-facebook-f',
        },
        {
            key: 'x-twitter',
            url: 'https://twitter.com/icautomatizados',
            iconClass: 'fa-brands fa-x-twitter',
        },
        {
            key: 'youtube',
            url: 'https://www.youtube.com/user/icautomatizados',
            iconClass: 'fab fa-youtube',
        },
        {
            key: 'instagram',
            url: 'https://www.instagram.com/icautomatizados/',
            iconClass: 'fab fa-instagram',
        }
    ];

    const socialLinksList = socialLinks.map((item) => (
        <li key={item.key}>
            <a href={item.url} target="_blank" >
                <i className={`${item.iconClass} fa-lg`} />            </a>
        </li>
    ));

    const handleFormSubmit = async (e) => {
        e.preventDefault();
    
        if (!isValidEmail(email)) {
            setModal(true); // Show an error message, for example
        } else {
            setIsModalOpen(true);
            await registerEmail({ email: email });
        }
    };
    const handleCloseModal = () => {
        modal &&
        setModal(false)
        setIsModalOpen(false);
        setEmail('');
    };  
    return (
        <>
          <div className={`modal ${isModalOpen ? 'open' : ''}`}>
      <div className="modal-content-news modal-news">
        <h2>Gracias por suscribirte a nuestro newsletter</h2>
        <p>Recibirás pronto nuestras últimas novedades y promociones.</p>
        <button className='btn btn-primary btn-news' onClick={handleCloseModal}>Aceptar</button>
      </div>
    </div>
          <div className={`modal ${modal ? 'open' : ''}`}>
          <div className="modal-content-news modal-news">
        <h2>No has ingresado un correo valido</h2>
        <p>Ingresa un correo valido para obtener todas las promociones que tenemos para ti.</p>
        <button className='btn btn-primary btn-news' onClick={handleCloseModal}>Aceptar</button>
      </div>
    </div>
        <div className="site-footer__widget footer-newsletter">
            <div className="footer-newsletter__text">
                Suscribase para recibir nuestros Boletines de Tecnología.
            </div>

            <form action="" className="footer-newsletter__form">
                <label className="sr-only" htmlFor="email">Correo Electronico...</label>
                <input
                    type="email"
                    id="email"
                    required
                    name='email'
                    value={email}
                    className="footer-newsletter__form-input form-control"
                    placeholder="Correo Electronico..."
                    onChange={(e) => setEmail(e.target.value)}
                />
                <button type="submit" onClick={handleFormSubmit} className="footer-newsletter__form-button btn btn-primary">Suscribirse</button>
            </form>

            <div className="footer-newsletter__text footer-newsletter__text--social">
                Síguenos en redes sociales.
            </div>

            <ul className="footer-newsletter__social-links">
                {socialLinksList}
            </ul>
        </div>
                </>

    );
}

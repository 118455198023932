// react
import React, { Component } from 'react';

// application
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Collapse from '../shared/Collapse';
import AccountFormAddress from '../account/AccountFormAddress';


class SelectAddresses extends Component {
    constructor(props) {
        super(props);
        const { cart, currentUser } = props;
        const addr = currentUser && currentUser.addresses.length > 0 ? currentUser.addresses[0].id : null;
        this.state = {
            address: currentUser ? currentUser.addresses : [],
            defaultaddress: cart && cart.shippingAddress ? cart.shippingAddress.id : addr,
            defaultaddressShipping: cart && cart.shippingAddress ? cart.shippingAddress.id : '0',
            loading: false,
            open: false,
        };
    }

    componentDidMount() {
        const { handleAddressChange, cart } = this.props;
        handleAddressChange('billing', cart.shippingAddress);
        handleAddressChange('shipping', cart.shippingAddress);
    }

    handleAddressChange = (event) => {
        const { handleAddressChange } = this.props;
        if (event.target.checked) {
            const address = JSON.parse(event.target.value);
            this.setState({ defaultaddress: address.id });
            handleAddressChange('billing', address);
        }
    };

    handleAddressShippingChange = (event) => {
        const { handleAddressChange } = this.props;
        if (event.target.checked) {
            const address = JSON.parse(event.target.value);
            this.setState({ defaultaddressShipping: address.id });
            handleAddressChange('shipping', address);
        }
    };

    refresh = () => {
        const { getCustomerAddress, currentUser, handleAddressChange } = this.props;
        const addres = getCustomerAddress(currentUser.id);
        addres.then((res) => {
            const address = res.data;
            if (address.length > 0) {
                const defaultaddress = address.find((e) => e.defaultaddress === true);
                const def = !defaultaddress ? address[0] : defaultaddress;
                this.setState({ address, defaultaddress: def.id, defaultaddressShipping: def.id, loading: false });
                handleAddressChange('billing', def);
                handleAddressChange('shipping', def);

            }
        });
    }

    renderAddressList() {
        const { address, defaultaddress } = this.state;
        const { errors, sending } = this.props;
        const addresses = address.map((address) => {
            const renderAddress = ({ setItemRef, setContentRef }) => (
                <li className="payment-methods__item" ref={setItemRef}>
                    <label className="payment-methods__item-header">
                        <span className="payment-methods__item-radio input-radio">
                            <span className="input-radio__body">
                                <input
                                    type="radio"
                                    className="input-radio__input"
                                    name="billing_address"
                                    value={JSON.stringify(address)}
                                    checked={defaultaddress == address.id}
                                    onChange={this.handleAddressChange}
                                    disabled={sending}
                                />
                                <span className="input-radio__circle" />
                            </span>
                        </span>
                        <span className="payment-methods__item-title">{address.responsable}</span>
                    </label>
                    <div className="payment-methods__item-container" ref={setContentRef}>
                        <div className="payment-methods__item-description text-muted">
                            <div>{`${address.address}. ${address.countryid.countryname}`}</div>
                            <br />
                            <div className="address-card__row">
                                <div className="address-card__row-title">Telefono Principal</div>
                                <div className="address-card__row-content">{address.phone1}</div>
                            </div>
                            {address.email && (
                                <div className="address-card__row">
                                    <div className="address-card__row-title">Correo Electrónico</div>
                                    <div className="address-card__row-content">{address.email}</div>
                                </div>
                            )}
                        </div>
                    </div>
                </li>
            );
            return (
                <Collapse
                    key={address.id}
                    open={defaultaddress == address.id}
                    toggleClass="payment-methods__item--active"
                    render={renderAddress}
                />
            );
        });

        return (
            <div className="payment-methods">
                <ul className="payment-methods__list">
                    {errors.billingaddress && (
                        <span style={{ fontSize: '90%', color: 'red' }}>
                            {errors.billingaddress}
                        </span>
                    )}
                    {addresses}
                </ul>
            </div>
        );
    }

    renderAddressShippingList() {
        const { address, defaultaddressShipping } = this.state;
        const { errors, sending } = this.props;

        const addresses = address.map((address) => {
            const renderAddressShipping = ({ setItemRef, setContentRef }) => (
                <li className="payment-methods__item" ref={setItemRef}>
                    <label className="payment-methods__item-header">
                        <span className="payment-methods__item-radio input-radio">
                            <span className="input-radio__body">
                                <input
                                    type="radio"
                                    className="input-radio__input"
                                    name="shipping_address"
                                    value={JSON.stringify(address)}
                                    checked={defaultaddressShipping == address.id}
                                    onChange={this.handleAddressShippingChange}
                                    disabled={sending}
                                />
                                <span className="input-radio__circle" />
                            </span>
                        </span>
                        <span className="payment-methods__item-title">{address.responsable}</span>
                    </label>
                    <div className="payment-methods__item-container" ref={setContentRef}>
                        <div className="payment-methods__item-description text-muted">
                            <div>{`${address.address}. ${address.countryid.countryname}`}</div>
                            <br />
                            <div className="address-card__row">
                                <div className="address-card__row-title">Telefono Principal</div>
                                <div className="address-card__row-content">{address.phone1}</div>
                            </div>
                            {address.email && (
                                <div className="address-card__row">
                                    <div className="address-card__row-title">Correo Electrónico</div>
                                    <div className="address-card__row-content">{address.email}</div>
                                </div>
                            )}
                        </div>
                    </div>
                </li>
            );
            return (
                <Collapse
                    key={address.id}
                    open={defaultaddressShipping == address.id}
                    toggleClass="payment-methods__item--active"
                    render={renderAddressShipping}
                />
            );
        });
        const onSite = () => {
            const { sending } = this.props;
            const renderAddressShipping = ({ setItemRef, setContentRef }) => (
                <li className="payment-methods__item" ref={setItemRef}>
                    <label className="payment-methods__item-header">
                        <span className="payment-methods__item-radio input-radio">
                            <span className="input-radio__body">
                                <input
                                    type="radio"
                                    className="input-radio__input"
                                    name="shipping_address"
                                    value={JSON.stringify({ id: '0' })}
                                    checked={defaultaddressShipping === '0'}
                                    onChange={this.handleAddressShippingChange}
                                    disabled={sending}
                                />
                                <span className="input-radio__circle" />
                            </span>
                        </span>
                        <span className="payment-methods__item-title">Retirar en Sitio</span>
                    </label>
                    <div className="payment-methods__item-container" ref={setContentRef}>
                        <div className="payment-methods__item-description text-muted">Retirar en nuestras instalaciones.</div>
                    </div>
                </li>
            );
            return (
                <Collapse
                    key="0"
                    open={defaultaddressShipping === '0'}
                    toggleClass="payment-methods__item--active"
                    render={renderAddressShipping}
                />
            );
        };

        return (
            <div className="payment-methods">
                <ul className="payment-methods__list">
                    {errors.shippingaddress && (
                        <span style={{ fontSize: '90%', color: 'red' }}>
                            {errors.shippingaddress}
                        </span>
                    )}
                    {addresses}
                    {onSite()}
                </ul>
            </div>
        );
    }

    render() {
        const { open, address } = this.state;
        const { cart, currentUser } = this.props;
        return (
            <React.Fragment>
                <div className="card mb-lg-0">
                    <div className="card-body" style={{backgroundColor: '#f8f9fa' }}>
                        <h3 className="card-title">Dirección de Envío</h3>
                        {cart && cart.incEnvio && cart.shippingAddress ? (
                            <div>
                                <strong>{cart.shippingAddress.responsable}</strong>
                                <p>
                                    {cart.shippingAddress.address}
                                    {', '}
                                    {cart.shippingAddress.deliverycityid ? `${cart.shippingAddress.deliverycityid.cityname}, ${cart.shippingAddress.countryid.countryname}.` : cart.shippingAddress.countryid.countryname}
                                </p>
                                {cart.msgEnvio && (
                                    <span className={`text-${cart.delivery ? 'success' : 'danger'}`} style={{ fontSize: '0.8em' }}>
                                        {cart.msgEnvio}
                                        <br />
                                    </span>
                                )}
                                {currentUser && currentUser.countryid.countrycode !== 'PA' && <span className="text-success mr-2" style={{ fontSize: '0.8em' }}>ITBMS No aplica para ventas fuera de Panamá.</span>}
                            </div>
                        )
                            :
                            (
                                <div>
                                    Retirar en nuestras instalaciones.
                                    {currentUser && currentUser.countryid.countrycode !== 'PA' && (
                                        <span className="text-success mr-2" style={{ fontSize: '0.9em' }}>
                                            <br />
                                            ITBMS No aplica para ventas fuera de Panamá
                                        </span>
                                    )}
                                </div>
                            )
                        }
                    </div>
                    <div className="card-divider" />
                    <div className="card-body">
                        <h3 className="card-title">Dirección de Facturación</h3>
                        {this.renderAddressList()}
                        {address.length < 1 && (<span> Debe Registar una direccción de Facturación.</span>)}
                    </div>
                </div>
                <Modal isOpen={open} toggle={() => this.setState({ open: false })} centered size="lg">
                    <ModalHeader toggle={() => this.setState({ open: false })} />
                    <ModalBody>
                        <AccountFormAddress type="Agregar" cart />
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export default SelectAddresses;

/* eslint-disable  */

// react
import React from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// application
import AsyncAction from "./AsyncAction";
import Currency from "./Currency";
import { cartAddItem } from "../../store/cart";
import { Compare16Svg, Quickview16Svg, Wishlist16Svg } from "../../svg";
import { compareAddItem } from "../../store/compare";
import { quickviewOpen } from "../../store/quickview";
import { wishlistAddItem } from "../../store/wishlist";
import ProductTabSpecification from "../shop/ProductTabSpecification";
function ProductCard(props) {
    // eslint-disable-next-line object-curly-newline
    const { product, layout, quickviewOpen, cartAddItem, wishlistAddItem, compareAddItem, brands } = props;
    const containerClasses = classNames("product-card", {
        "product-card--layout--grid product-card--size--sm": layout === "grid-sm",
        "product-card--layout--grid product-card--size--nl": layout === "grid-nl",
        "product-card--layout--grid product-card--size--lg": layout === "grid-lg",
        "product-card--layout--list": layout === "list",
        "product-card--layout--horizontal": layout === "horizontal",
    });
    const discountStyle = {
        fontSize: "16px"
    }
    const [showSpecifications, setShowSpefifications] = React.useState(false)
    let image;
    let price;
    let features;
    let percentageDifference = Math.ceil(((parseFloat(product?.price_without_sale_price) - parseFloat(product?.price)) / parseFloat(product?.price_without_sale_price)) * 100);
    if (percentageDifference <= 11) {
        percentageDifference = 10
    }
    else if (percentageDifference <= 18) {
        percentageDifference = 15
    }
    else if (percentageDifference >= 29) {
        percentageDifference = 30
    }
    if (product?.images && product?.images.length > 0) {
        image = (
            <div className="product-card__image">
                <Link to={`/shop/product/${product.code.replace(/\//g, "-")}/${product?.id}`}>
                    <img src={product?.images[0]} alt="" />
                </Link>
            </div>
        );
    } else {
        image = (
            <div className="product-card__image">
                <Link to={`/shop/product/${product.code.replace(/\//g, "-")}/${product.id}`}>
                    <img src={`${process.env.REACT_APP_API_URL}assets/2750d158-ce6b-4c78-9c90-3e5dd0cf9549`} alt={product.name} />
                </Link>
            </div>
        );
    }

    if (product.compareAtPrice) {
        price = (
            <span className="product-card__prices">
                <span className="product-card__new-price">
                    <Currency style={{ fontSize: "12px" }} value={product?.price} />
                </span>{" "}
                <span className="product-card__old-price">
                    <Currency value={product?.compareAtPrice} />
                </span>
            </span>
        );
    } else {
        price = (
            <span className="product-card__prices">
                <Currency value={parseFloat(product?.price)} />
            </span>
        );
    }
   
    if (product.features && product.features.length) {

        // eslint-disable-next-line react/jsx-wrap-multilines
        features = (
            <div >
                {brands.map((brand) => (
                    <React.Fragment key={brand.id}>
                        {brand.name.toLowerCase() === product?.brand.toLowerCase() &&

                            <img className="imagen-brand"
                                src={`${process.env.REACT_APP_API_URL}assets/${brand.logo}`}
                                alt=""
                                style={{ height: "50px" }}
                                onError={(e) => {
                                    e.target.style.height = "50px"; // Establecer la altura predeterminada si la imagen no se carga correctamente
                                    e.target.style.width = "auto"; // Opcional: Mantener la proporción del ancho si es necesario
                                }}
                            /> 
                        }
                    </React.Fragment>
                ))}

                <ul className="product-card__features-list">
                    {product.features.slice(0, 3).map((feature, index) => (
                        <li key={index}>
                            {`${feature.name === "Número del cátalogo del fabricante" ? "ACE:" : feature.name}: `}
                            {feature.name === "Código del Producto" ? (
                                <b>{feature.value}</b>
                            ) : (
                                feature.value
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        );

    }
    function getFirstWord(paragraph) {
        const words = paragraph.split(' ');
        if (words.length > 0) {
            return words[0];
        } else {
            return ''; // Devuelve una cadena vacía si el párrafo está vacío
        }
    }
    const handleShowSpecifications = () => {
        setShowSpefifications(prev => !prev)
    }
    return (
        <div>
            <div className={containerClasses}>
                <AsyncAction
                    action={() => quickviewOpen(product.id)}
                    render={({ run, loading }) => (
                        <button
                            type="button"
                            onClick={run}
                            className={classNames("product-card__quickview", {
                                "product-card__quickview--preload": loading,
                            })}
                        >
                            <Quickview16Svg />
                        </button>
                    )}
                />
                <AsyncAction
                    action={() => wishlistAddItem(product)}
                    render={({ run, loading }) => (
                        <button
                            type="button"
                            onClick={run}
                            className={classNames("btn btn-light btn-svg-icon btn-svg-icon--fake-svgcard__wishlist", {
                                "btn-loading": loading,
                            })}
                        >
                            {/* <i className="fa fa-heart" aria-hidden="true"></i> */}
                            <Wishlist16Svg />
                        </button>
                    )}
                />
                {image}
                <div className="product-card__info">
                    <div className="product-card__name">
                        <Link to={`/shop/product/${product.code.replace(/\//g, "-")}/${product?.id}`}> <b>{getFirstWord(product?.name)} {product?.brand} </b>  <span className="code_product">{product.code}</span></Link>
                    </div>
                    {features}
                    <div className="specifications">
                        <span onClick={handleShowSpecifications}>Especificaciones
                            {showSpecifications ?
                                <i className="fa fa-arrow-circle-up" aria-hidden="true"></i> : <i className="fa fa-arrow-circle-down" aria-hidden="true"></i>
                            }</span>
                        <div className="footer_spec">
                            <a href={`${process.env.REACT_APP_HOST}/${product.pdfs}`} target="_blank" rel="noopener noreferrer">
                                <img className="footer_spec_img" src="images/download_pdf.png" height="80px" alt="PDF" />
                                <span className="footer_spec_span"> Ficha Técnica</span>
                            </a>
                        </div>
                    </div>
                    {showSpecifications &&
                        < ProductTabSpecification specification={product.especificaciones} list={true} />}
                </div>
                <div className="product-card__actions">
                    {product?.available > 0 &&
                        <div className="product-card__availability">
                            <i className="fa fa-check-circle" aria-hidden="true"></i>
                            <span className="text-stock">EN STOCK</span>
                        </div>}
                    <div className="product-card__availability">
                        Disponible: <span className="text-success">{product?.available > 0 ? product?.available : "0"}</span>
                    </div>
                    <div className="product-card__availability">
                        En Transito: <span className="text-info">{product?.OnOrder > 0 ? product?.OnOrder : "0"}</span>
                    </div>{" "}
                    <div className="product-card__availability">
                        Bodega externa: <span className="text-info">{product?.externalQty}</span>
                    </div>

                    {product.discount ?
                        <div> <span style={{ color: "#b30000", fontWeight: "100", fontSize: "14px" }}> -{product.discount}%</span> {price} <span style={discountStyle}><del>${product.sale_price}</del> </span> </div>
                        :
                        price
                    }

                    <div className="product-card__buttons">
                        <AsyncAction
                            action={() => cartAddItem(product)}
                            render={({ run, loading }) => (
                                <React.Fragment>
                                    <button
                                        type="button"
                                        onClick={run}
                                        className={classNames("btn btn-primary product-card__addtocart", {
                                            "btn-loading": loading,
                                        })}
                                    >
                                        Enviar al Carrito
                                    </button>
                                    <button
                                        type="button"
                                        onClick={run}
                                        className={classNames("btn btn-secondary product-card__addtocart product-card__addtocart--list", {
                                            "btn-loading": loading,
                                        })}
                                    >
                                        Enviar al Carrito
                                    </button>
                                </React.Fragment>
                            )}
                        />

                        {/* <AsyncAction
                            action={() => compareAddItem(product)}
                            render={({ run, loading }) => (
                                <button
                                    type="button"
                                    onClick={run}
                                    className={classNames("btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__compare", {
                                        "btn-loading": loading,
                                    })}
                                >
                                    <Compare16Svg />
                                </button>
                            )}
                        /> */}
                    </div>
                </div>
            </div>
        </div>

    );
}

ProductCard.propTypes = {
    /**
     * product object
     */
    product: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(["grid-sm", "grid-nl", "grid-lg", "list", "horizontal"]),
};

const mapStateToProps = (state) => ({
    brands: state.pagesContent.brands,

});

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
    quickviewOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);

// react
import React from 'react';

function unidMed(key) {
    switch (key) {
    case 'Largo':
        return 'mm';
    case 'Ancho':
        return 'mm';
    case 'Altura':
        return 'mm';
    case 'Peso':
        return 'kg';
    case 'Amperio':
        return 'Amp';
    case 'Voltaje':
        return 'V';
    case 'Volumen':
        return 'cm3';
    case 'Hp':
        return 'hp';
    case 'Kw':
        return 'kw';
    case 'RPM':
        return 'rpm';
    case 'Cantidad de unidades por empaque':
        return ' unid';
    default:
        return '';
    }
}
function ProductTabSpecification(props) {
    const { specification, list } = props;
    const specifications = specification.split('<br>');
    const features = specifications.map((feature, index) => {
        const e = feature.split(':');
        if (e.length > 1) {
            return (
                <div key={index} className={list ?"spec_row" :"spec__row"}>
                    <div className={list ?"spec_name" :"spec__name"}>{e[0]}</div>
                    <div className={list ?"spec_value" :"spec__value"}>{`${e[1]}  ${unidMed(e[0])}`}</div>
                </div>
            );
        }
    });

    return (
        <div className="spec__section">
           {list ? "": <h4 className="spec__section-title">General</h4>}
            {features}
        </div>
    );
}

export default ProductTabSpecification;

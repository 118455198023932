import { toast } from 'react-toastify';
import {
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_UPDATE_QUANTITIES,
    CART_REMOVE_ALL_ITEMS,
    CART_UPDATE_PRICE,
    CART_LOAD_ALL_ITEMS,
    RECALCULATE_SHIPPING,
    CHANGE_QTY
} from './cartActionTypes';

export function recalculateShipping(shippingAddress, incEnvio) {
    return {
        type: RECALCULATE_SHIPPING,
        shippingAddress,
        incEnvio,
    };
}

export function cartLoadItemsSuccess(cart) {
    toast.success('Productos agregados al Carrito!');
    return {
        type: CART_LOAD_ALL_ITEMS,
        cart,
    };
}

export function cartAddItemSuccess(product, options = [], quantity = 1) {
    toast.success(`Producto "${product.name}" agregado al Carrito!`);

    return {
        type: CART_ADD_ITEM,
        product,
        options,
        quantity,
    };
}

export function cartRemoveItemSuccess(itemId) {
    return {
        type: CART_REMOVE_ITEM,
        itemId,
    };
}

export function cartUpdateQuantitiesSuccess(quantities) {
    return {
        type: CART_UPDATE_QUANTITIES,
        quantities,
    };
}

export function cartUpdatePrice(items, action) {
    return {
        type: CART_UPDATE_PRICE,
        items,
        action
    };
}

export function cartAddItem(product, options = [], quantity = 1) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartAddItemSuccess(product, options, quantity));
                resolve();
            }, 500);
        })
    );
}

export function cartRemoveItem(itemId) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartRemoveItemSuccess(itemId));
                resolve();
            }, 500);
        })
    );
}

export function cartUpdateQuantities(quantities) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartUpdateQuantitiesSuccess(quantities));
                resolve();
            }, 500);
        })
    );
}

export function cartRemoveAllItems() {
    return {
        type: CART_REMOVE_ALL_ITEMS,
    };
}

export const ChangeQuantity = (index, qty = 1) => ({
    type: CHANGE_QTY,
    index,
    qty,
});
// react
import React from 'react';

// third-party
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';

// application
import PageHeader from '../shared/PageHeader';
import BlockCategoriesCompact from '../blocks/BlockCategoriesCompact';

// data stubs


function ShopPage(props) {
    const {
        categories,
    } = props;
    const breadcrumb = [
        { title: 'Home', url: '/' },
        { title: 'Categorias', url: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>Categorias de Productos - ICAutomatizados S.A</title>
            </Helmet>

            <PageHeader header="Todas las Categorias" breadcrumb={breadcrumb} />

            <BlockCategoriesCompact layout="compact" categories={categories} />
        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    categories: state.pagesContent.categories,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPage);

// react
import React from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import PageHeader from '../shared/PageHeader';
import Rating from '../shared/Rating';
import { cartAddItem } from '../../store/cart';
import { Cross12Svg } from '../../svg';
import { wishlistRemoveItem } from '../../store/wishlist';



function ShopPageWishlist(props) {
    const { wishlist, cartAddItem, wishlistRemoveItem } = props;
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'favoritos', url: '' },
    ];

    let content;

    if (wishlist.length) {
        const itemsList = wishlist.map((item) => {
            console.log(item)
            let image;

            if (item.images.length > 0) {
                image = (
                    <Link to={`/shop/product/${item.code.replace(/\//g, '-')}/${item.id}`}>
                        <img src={item.images[0]} alt="" />
                    </Link>
                );
            }

            const renderAddToCarButton = ({ run, loading }) => {
                const classes = classNames('btn btn-primary btn-sm', {
                    'btn-loading': loading,
                });

                return <button type="button" onClick={run} className={classes}>Enviar al carrito</button>;
            };

            const renderRemoveButton = ({ run, loading }) => {
                const classes = classNames('btn btn-light btn-sm btn-svg-icon', {
                    'btn-loading': loading,
                });

                return <button type="button" onClick={run} className={classes} aria-label="Remove"><Cross12Svg /></button>;
            };

            return (
                <tr key={item.id} className="wishlist__row">
                    <td className="wishlist__column wishlist__column--image">
                        {image}
                    </td>
                    <td className="wishlist__column wishlist__column--product">
                        <Link to={`/shop/product/${item.code.replace(/\//g, '-')}/${item.id}`} className="wishlist__product-name">{item.name}</Link>
                    </td>
                    <td className="wishlist__column wishlist__column--stock">
         
                        {item.available >=1?
                        <div className="badge badge-success">In Stock</div>
                        :
                        <div className="badge badge-failure">Sin Stock</div>

                    }
                    </td>
                    <td className="wishlist__column wishlist__column--price"><Currency value={item.price} /></td>
                    <td className="wishlist__column wishlist__column--tocart">
                        <AsyncAction
                            action={() => cartAddItem(item)}
                            render={renderAddToCarButton}
                        />
                    </td>
                    <td className="wishlist__column wishlist__column--remove">
                        <AsyncAction
                            action={() => wishlistRemoveItem(item.id)}
                            render={renderRemoveButton}
                        />
                    </td>
                </tr>
            );
        });

        content = (
            <div className="block">
                <div className="container">
                    <table className="wishlist">
                        <thead className="wishlist__head">
                            <tr className="wishlist__row">
                                <th className="wishlist__column wishlist__column--image">Imagen</th>
                                <th className="wishlist__column wishlist__column--product">Producto</th>
                                <th className="wishlist__column wishlist__column--stock">Stock</th>
                                <th className="wishlist__column wishlist__column--price">Precio</th>
                                <th className="wishlist__column wishlist__column--tocart" aria-label="Emviar al carrito" />
                                <th className="wishlist__column wishlist__column--remove" aria-label="Remove" />
                            </tr>
                        </thead>
                        <tbody className="wishlist__body">
                            {itemsList}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    } else {
        content = (
            <div className="block block-empty">
                <div className="container">
                    <div className="block-empty__body">
                        <div className="block-empty__message">¡Tu lista de favoritos esta vacía!</div>
                        <div className="block-empty__actions">
                            <Link to="/" className="btn btn-primary btn-sm">Continuar</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Lista de Favoritos — Icautomatizados S.A</title>
            </Helmet>

            <PageHeader header="Favoritos" breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    wishlist: state.wishlist,
});

const mapDispatchToProps = {
    cartAddItem,
    wishlistRemoveItem,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ShopPageWishlist);

/* eslint linebreak-style: ["error", "windows"] */
// react
import React, { Component } from 'react';

// third-party
import PropTypes from 'prop-types';
import {
    BrowserRouter,
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';// \r\n
import { connect } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import { ScrollContext } from 'react-router-scroll-4';

// application
import languages from '../i18n';
import { localeChange } from '../store/locale';
import {
    pagesContentChange,
} from '../store/pagesContent';

// pages
import Layout from './Layout';
import {
    getContent,
} from '../store/directusSdk';

class Root extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        const { pagesContentChange } = this.props;
        const preloader = document.querySelector('.site-preloader');

        getContent().then((contentPage) => {
            pagesContentChange(contentPage)
                this.setState({ loading: false });
                preloader.addEventListener('transitionend', (event) => {
                    if (event.propertyName === 'opacity') {
                        preloader.parentNode.removeChild(preloader);
                    }
                });
                preloader.classList.add('site-preloader__fade');
            }
            );
        
    }

    render() {
        const { loading } = this.state;
        const { locale } = this.props;
        const { messages, direction } = languages[locale];

        if (loading) {
            return null;
        }

        return (
            <IntlProvider locale={locale} messages={messages}>
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <HelmetProvider>
                        <Helmet htmlAttributes={{ lang: locale, dir: direction }} />
                        <ScrollContext>
                            <Switch>
                                <Route
                                    path="/"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" />
                                    )}
                                />
                                <Redirect to="/" />
                            </Switch>
                        </ScrollContext>
                    </HelmetProvider>
                </BrowserRouter>
            </IntlProvider>
        );
    }
}

Root.propTypes = {
    /** current locale */
    locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
    currentUser: state.currentUser,
});

const mapDispatchToProps = {
    localeChange,
    pagesContentChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);

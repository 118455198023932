// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import moment from 'moment';
import 'moment/locale/es';
import Currency from '../shared/Currency';
import { Check100Svg } from '../../svg';

// data stubs
import order from '../../data/accountOrderDetails';

export default function ShopPageOrderSuccess(props) {
    const { location, currentUser } = props;
    const checkout = location.state;
    const procesada = moment(checkout.createdDate, 'YYYY/MM/DD HH:mm:ss').locale('es').format('LL');

    const items = checkout.lines.map((item) => {
        const options = (item.options || []).map((option) => (
            <li className="order-list__options-item">
                <span className="order-list__options-label">
                    {option.label}
                    {': '}
                </span>
                <span className="order-list__options-value">{option.value}</span>
            </li>
        ));

        return (
            <tr key={item.product.id}>
                <td className="order-list__column-image">
                    <Link to={`/shop/product/${item.product.id}`}>
                        <img src={item.product.images[0]} alt="" />
                    </Link>
                </td>
                <td className="order-list__column-product">
                    <Link to={`/shop/product/${item.product.id}`}>{item.product.name}</Link>
                    {options.length > 0 && (
                        <div className="order-list__options">
                            <ul className="order-list__options-list">
                                {options}
                            </ul>
                        </div>
                    )}
                </td>
                <td className="order-list__column-quantity" data-title="Qty:">{item.quantity}</td>
                <td className="order-list__column-total"><Currency value={item.total} /></td>
            </tr>
        );
    });

    const additionalLines = order.additionalLines.map((line) => (
        <tr>
            <th className="order-list__column-label" colSpan="3">{line.label}</th>
            <td className="order-list__column-total"><Currency value={line.total} /></td>
        </tr>
    ));

    return (
        <div className="block order-success">
            <Helmet>
                <title>Orden Procesada - ICAutomatizados S.A</title>
            </Helmet>

            <div className="container">
                <div className="order-success__body">
                    <div className="order-success__header">
                        <Check100Svg className="order-success__icon" />
                        <h1 className="order-success__title">Gracias por su Compra!</h1>
                        <div className="order-success__subtitle">Su orden ha sido procesada con exito.</div>
                        <div className="order-success__actions">
                            <Link to="/" className="btn btn-xs btn-secondary">Ir a la página de inicio</Link>
                        </div>
                    </div>

                    <div className="order-success__meta">
                        <ul className="order-success__meta-list">
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Numero de Orden:</span>
                                <span className="order-success__meta-value">{`#${checkout.numberOrder}`}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Procesada el:</span>
                                <span className="order-success__meta-value">{procesada}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Total:</span>
                                <span className="order-success__meta-value"><Currency value={checkout.total} /></span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Metodo de Pago:</span>
                                <span className="order-success__meta-value">{checkout.paymenttypeid.title}</span>
                            </li>
                        </ul>
                    </div>

                    <div className="card">
                        <div className="order-list">
                            <table>
                                <thead className="order-list__header">
                                    <tr>
                                        <th className="order-list__column-label" colSpan="2">Producto</th>
                                        <th className="order-list__column-quantity">Cantidad</th>
                                        <th className="order-list__column-total">Total</th>
                                    </tr>
                                </thead>
                                <tbody className="order-list__products">
                                    {items}
                                </tbody>
                                {additionalLines.length > 0 && (
                                    <tbody className="order-list__subtotals">
                                        <tr>
                                            <th className="order-list__column-label" colSpan="3">Subtotal</th>
                                            <td className="order-list__column-total"><Currency value={checkout.subtotal} /></td>
                                        </tr>
                                        <tr>
                                            <th className="order-list__column-label" colSpan="3">
                                                Envío
                                                {checkout.msg_envi && (
                                                    <span className={`ml-3 text-${checkout.delivery ? 'success' : 'danger'}`} style={{ fontSize: '0.8em'}}>
                                                        {checkout.msg_envi}
                                                    </span>
                                                )}
                                            </th>
                                            <td className="order-list__column-total"><Currency value={checkout.envio} /></td>
                                        </tr>
                                        <tr>
                                            <th className="order-list__column-label" colSpan="3">
                                                ITBMS
                                                {currentUser && currentUser.countryid.countrycode !== 'PA' && <span className="ml-3 text-success mr-2" style={{ fontSize: '0.8em' }}>No aplica para ventas fuera de Panamá.</span>}
                                            </th>
                                            <td className="order-list__column-total">
                                                <Currency value={checkout.itbms} />
                                            </td>
                                        </tr>
                                    </tbody>
                                )}
                                <tfoot className="order-list__footer">
                                    <tr>
                                        <th className="order-list__column-label" colSpan="3">Total</th>
                                        <td className="order-list__column-total"><Currency value={checkout.total} /></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                    <div className="row mt-3 no-gutters mx-n2">
                        <div className="col-sm-6 col-12 px-2">
                            <div className="card address-card">
                                <div className="address-card__body">
                                    <div className="address-card__badge address-card__badge--muted">
                                        Dirección Envío
                                    </div>
                                    {checkout.shippingaddress === 'Rerirar en Sitio' ? (
                                        <div className="address-card__name">
                                            {`${checkout.shippingaddress}`}
                                        </div>
                                    )
                                        :
                                        (
                                            <React.Fragment>
                                                <div className="address-card__name">
                                                    {`${checkout.shippingaddress.responsable}`}
                                                </div>
                                                <div className="address-card__row">
                                                    {checkout.shippingaddress.address}
                                            ,
                                                {' '}
                                                    {checkout.shippingaddress.countryid.countryname}
                                                </div>
                                                <div className="address-card__row">
                                                    <div className="address-card__row-title">Telefono Principal</div>
                                                    <div className="address-card__row-content">{checkout.shippingaddress.phone1}</div>
                                                </div>
                                                {checkout.shippingaddress.phone2 && (
                                                    <div className="address-card__row">
                                                        <div className="address-card__row-title">Telefono Opcional</div>
                                                        <div className="address-card__row-content">{checkout.shippingaddress.phone2}</div>
                                                    </div>
                                                )}
                                                {checkout.shippingaddress.email && (
                                                    <div className="address-card__row">
                                                        <div className="address-card__row-title">Correo Electrónico</div>
                                                        <div className="address-card__row-content">{checkout.shippingaddress.email}</div>
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12 px-2 mt-sm-0 mt-3">
                            <div className="card address-card">
                                <div className="address-card__body">
                                    <div className="address-card__badge address-card__badge--muted">
                                        Dirección Facturación
                                    </div>
                                    <div className="address-card__name">
                                        {`${checkout.billingaddress.responsable}`}
                                    </div>
                                    <div className="address-card__row">
                                        {checkout.billingaddress.address}
                                        ,
                                        {' '}
                                        {checkout.billingaddress.countryid.countryname}
                                    </div>
                                    <div className="address-card__row">
                                        <div className="address-card__row-title">Telefono Principal</div>
                                        <div className="address-card__row-content">{checkout.billingaddress.phone1}</div>
                                    </div>
                                    {checkout.billingaddress.phone2 && (
                                        <div className="address-card__row">
                                            <div className="address-card__row-title">Telefono Opcional</div>
                                            <div className="address-card__row-content">{checkout.billingaddress.phone2}</div>
                                        </div>
                                    )}
                                    {checkout.billingaddress.email && (
                                        <div className="address-card__row">
                                            <div className="address-card__row-title">Correo Electrónico</div>
                                            <div className="address-card__row-content">{checkout.billingaddress.email}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

// react
import React from 'react';

function ProductTabPdf(props) {
    const { pdfs } = props;
    const PdfList = pdfs.map((e, i) => <li key={i}><a href={e}><img src="images/download_pdf.png" height="80px" alt="PDF"/>{e.split('/').splice(-1)}</a></li>);
    return (
        <React.Fragment>
            <div>
                <ul>
                    {PdfList}
                </ul>
            </div>
        </React.Fragment>
    );
}

export default ProductTabPdf;
